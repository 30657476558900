import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateTimePicker } from '@mantine/dates';
import { Group, Button, Container, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../client-redux/reducers';
import { Language } from '../../../../languages/languageHandler';
import { LoginState } from '../../../../client-redux/login/reducer';
import {
  EquipmentRegistrationRequest,
  IHelpdeskEquipment,
  IHelpdeskEquipmentResponse,
  fetchHelpdeskEquipInfo,
  updateHelpdeskEquipment
} from '../../../../../libraries/helpdesk';
import ChooseEntityModal from '../../../components/choose-entity-modal';
import CustomModal from '../../../../components/custom-modal';
import Background from '../../../../components/util/background';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

enum SELECTED_FIELD {
  ENTITY,
  MAINT_1,
  MAINT_2
}

const RegisterEquipmentHelpdesk: React.FC = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const { eid } = useParams<string>();
  const [equipment, setEquipment] = useState<IHelpdeskEquipment>();
  const [chooseEntityModal, setChooseEntityModal] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedField, setSelectedField] = useState<SELECTED_FIELD>(SELECTED_FIELD.ENTITY);

  useEffect(() => {
    getEquipmentList();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEquipmentList = () => {
    if (!loginInfo.token) {
      return;
    }
    fetchHelpdeskEquipInfo(Number(eid), controller)
      .then((response: IHelpdeskEquipmentResponse) => {
        if (response.statusOper.code === 0) {
          setEquipment(response?.list[0]);
        } else {
          //TODO: show error message
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          return;
        }
        //TODO: show error message
      });
  };

  const defaultExpDate = () => {
    if (equipment?.licenceExp) {
      return equipment?.licenceExp;
    } else {
      const newDate = new Date();
      newDate.setFullYear(newDate.getFullYear() + 10);
      return newDate.toISOString();
    }
  };

  const form = useForm({
    initialValues: {
      eid: 0,
      entid: 0,
      entidName: '',
      maintid: 0,
      maintidName: '',
      maintid2: 0,
      maintid2Name: '',
      shop: {
        sid: 0,
        name: ''
      },
      dateTimeExp: defaultExpDate(),
      posid: 0
    }
  });

  useEffect(() => {
    if (equipment) {
      form.setValues(prevValues => ({
        ...prevValues,
        entid: prevValues.entid || equipment?.entity?.entid,
        entidName: prevValues.entidName || equipment?.entity?.name || '',
        maintid: prevValues.maintid || equipment?.maintEntity?.entid,
        maintidName: prevValues.maintidName || equipment?.maintEntity?.name || '',
        maintid2: prevValues.maintid2 || equipment?.maintEntity2?.entid,
        maintid2Name: prevValues.maintid2Name || equipment?.maintEntity2?.name || '',
        shop: {
          sid: prevValues.shop?.sid || equipment?.sid || 0,
          name: prevValues.shop?.name || equipment?.sidDescription || ''
        },
        dateTimeExp: defaultExpDate(),
        posid: prevValues.posid || equipment?.tb520?.posid
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment]);

  const submitForm = async (values: any) => {
    const request: EquipmentRegistrationRequest = {
      eid: Number(eid),
      entid: values.entid,
      maintid: values.maintid,
      maintid2: values.maintid2,
      shop: {
        sid: values.shop.sid,
        name: values.shop.name
      },
      dateTimeExp: values.dateTimeExp,
      posid: values.posid
    };

    const formController = new AbortController();
    try {
      const response = await updateHelpdeskEquipment(request, formController);

      if (response.statusOper && response.statusOper.code !== 0) {
        const errorMessage = response.statusOper.message || 'An unknown error occurred.';
        setErrorMessage(
          `${lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR} (Code: ${response.statusOper.code}) ${errorMessage}`
        );
        setShowErrorDialog(true);
      } else {
        setSuccessMessage(lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_SUCCESS || 'Equipment updated successfully');
        setShowSuccessDialog(true);
        setTimeout(() => {
          setShowSuccessDialog(false);
        }, 1000);
      }
    } catch (error) {
      setErrorMessage(`${lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR} (Status: ${errorMessage}`);
      setShowErrorDialog(true);
    }
  };

  const chooseEntid = (entid: number, name?: string) => {
    switch (selectedField) {
      case SELECTED_FIELD.ENTITY:
        form.setValues({ entid: entid, entidName: name });
        return;
      case SELECTED_FIELD.MAINT_1:
        form.setValues({ maintid: entid, maintidName: name });
        return;
      case SELECTED_FIELD.MAINT_2:
        form.setValues({ maintid2: entid, maintid2Name: name });
        return;
    }
  };

  const toggleChooseEntityModal = () => {
    setChooseEntityModal(!chooseEntityModal);
    setSelectedField(SELECTED_FIELD.ENTITY);
  };

  const toggleChooseMaintModal = () => {
    setChooseEntityModal(!chooseEntityModal);
    setSelectedField(SELECTED_FIELD.MAINT_1);
  };

  const toggleChooseAgentModal = () => {
    setChooseEntityModal(!chooseEntityModal);
    setSelectedField(SELECTED_FIELD.MAINT_2);
  };

  const clearEntid = () => {
    form.setValues({ ...form.values, entid: 0, entidName: '' });
  };

  const clearMaintid = () => {
    form.setValues({ ...form.values, maintid: 0, maintidName: '' });
  };

  const clearMaintid2 = () => {
    form.setValues({ ...form.values, maintid2: 0, maintid2Name: '' });
  };

  const handleDateChange = (date: Date | null) => {
    const dateString = date ? date.toISOString() : '';
    form.setFieldValue('dateTimeExp', dateString);
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'register-equip-helpdesk' }}>
        <ChooseEntityModal
          lang={lang}
          isOpen={chooseEntityModal}
          hideBtn={() => toggleChooseEntityModal()}
          chooseEntid={chooseEntid}
        />
        <Group>
          <form
            onSubmit={form.onSubmit(values => {
              submitForm(values);
            })}
          >
            <Group>
              {equipment && (
                <h3>
                  {lang?.EQUIPMENT_ID}: {equipment.eid} SN: {equipment.sn} {lang?.GLOBAL_TYPE}: {equipment.type} ENTID:{' '}
                  {equipment.entity?.entid}
                </h3>
              )}
            </Group>

            <Group classNames={{ root: 'entities' }}>
              {/* Entity */}
              <Group>
                <TextInput
                  label={lang?.USER_ENTITY}
                  type="text"
                  placeholder={`${lang?.GLOBAL_ENTITY}`}
                  {...form.getInputProps('entid')}
                  value={form.values.entid ? `${form.values.entid} - ${form.values.entidName}` : ''}
                ></TextInput>
                <Icon.IconSearch stroke={3} onClick={toggleChooseEntityModal} />
                <Icon.IconX stroke={3} onClick={clearEntid} />
              </Group>

              {/* Maintenance company */}
              <Group>
                <TextInput
                  label={lang?.HELPDESK_MAINTENANCE_COMPANY}
                  type="text"
                  placeholder={`${lang?.HELPDESK_MAINTENANCE_COMPANY}`}
                  {...form.getInputProps('maintid')}
                  value={form.values.maintid ? `${form.values.maintid} - ${form.values.maintidName}` : ''}
                ></TextInput>
                <Icon.IconSearch stroke={3} onClick={toggleChooseMaintModal} />
                <Icon.IconX stroke={3} onClick={clearMaintid} />
              </Group>

              {/* Agent */}
              <Group>
                <TextInput
                  label={lang?.HELPDESK_MAINTENANCE_AGENCY}
                  type="text"
                  placeholder={`${lang?.HELPDESK_MAINTENANCE_AGENCY}`}
                  {...form.getInputProps('maintid2')}
                  value={form.values.maintid2 ? `${form.values.maintid2} - ${form.values.maintid2Name}` : ''}
                ></TextInput>
                <Icon.IconSearch stroke={3} onClick={toggleChooseAgentModal} />
                <Icon.IconX stroke={3} onClick={clearMaintid2} />
              </Group>
            </Group>

            {/* Delegation */}
            <Group classNames={{ root: 'shop' }}>
              {/* ID */}
              <TextInput label={'ID'} placeholder="ID" {...form.getInputProps('shop.sid')} />

              {/* Name */}
              <TextInput
                label={lang?.GLOBAL_NAME}
                placeholder={`${lang?.GLOBAL_NAME}`}
                {...form.getInputProps('shop.name')}
              />
            </Group>

            {/* Licence */}
            <Group classNames={{ root: 'licence' }}>
              <Group>
                <DateTimePicker
                  label={lang?.GLOBAL_LICENSE}
                  value={form.values.dateTimeExp ? new Date(form.values.dateTimeExp) : null}
                  onChange={handleDateChange}
                  valueFormat="DD/MM/YYYY HH:mm"
                />

                {/* Posid */}
                <TextInput label={'POSID'} placeholder={'Posid'} {...form.getInputProps('posid')}></TextInput>
              </Group>
            </Group>

            <Group>
              <Button type="submit" color="#538539">
                {lang?.GLOBAL_SAVE_BUTTON}
              </Button>
            </Group>
          </form>
        </Group>
        <CustomModal
          opened={showSuccessDialog}
          onClose={() => setShowSuccessDialog(false)}
          message={successMessage}
          backgroundColor="#3BDF70"
          textColor="black"
        />

        <CustomModal
          opened={showErrorDialog}
          onClose={() => setShowErrorDialog(false)}
          message={errorMessage}
          backgroundColor="#E3353F"
          textColor="white"
        />
      </Container>
    </Background>
  );
};

export default RegisterEquipmentHelpdesk;
