import React from 'react';
import createRoot from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './app/client-redux/store';
import axiosConfig from './libraries/axiosConfig';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { ColorSchemeScript, MantineProvider, MantineThemeOverride } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ContextMenuProvider } from 'mantine-contextmenu';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import 'mantine-contextmenu/styles.layer.css';
import './app.scss';

const customThemes: Record<string, [string, string, string, string, string, string, string, string, string, string]> = {
  zarph: [
    '#003f52', // $zarph-dark-color
    '#004960', // $zarph-color
    '#016273', // $zarph-secondary
    '#176d88',
    '#1f7d9b',
    '#37b0d6',
    '#47d4ff', // $zarph-light-color
    '#03abac', // $zarph-green
    '#303030',
    'h' // $zarph-white
  ]
};

const themeOverride: MantineThemeOverride = {
  colors: customThemes
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  release: process.env.REACT_APP_VERSION,
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE)
});

axiosConfig();

createRoot.render(
  <React.StrictMode>
    <ColorSchemeScript />
    <MantineProvider theme={themeOverride}>
      <ContextMenuProvider>
        <Notifications />
        <Provider store={store}>
          <App />
        </Provider>
      </ContextMenuProvider>
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
