import { useSelector } from 'react-redux';
import { AppState } from '../../../client-redux/reducers';
import { Language } from '../../../languages/languageHandler';
import { useEffect, useState } from 'react';
import { fetchTicketClassifications } from '../../../../libraries/helpdesk';
import { LoginState } from '../../../client-redux/login/reducer';
import { Container, Select } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import './styles.scss';

interface TicketClassificationProps {
  onSelectionChange: (field: string, value: string, index: number) => void;
  form: any;
}

const TicketClassification: React.FC<TicketClassificationProps> = ({ onSelectionChange, form }) => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const login = useSelector<AppState, LoginState>(state => state.login);
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [classifications, setClassifications] = useState({
    problems: [] as { value: string; label: string }[],
    faults: [] as { value: string; label: string }[],
    causes: [] as { value: string; label: string }[],
    actions: [] as { value: string; label: string }[]
  });

  useEffect(() => {
    if (login.token) {
      const controller = new AbortController();
      getTicketClassification(controller);
      return () => controller.abort();
    }
  }, [login.token]);

  const getTicketClassification = async (controller: AbortController) => {
    try {
      setLoading(true);
      const response = await fetchTicketClassifications(controller);
      if (response.statusOper.code === 0) {
        setClassifications({
          problems: response.problems.map((item: any) => ({
            value: item.id.toString(),
            label: item.name
          })),
          faults: response.faults.map((item: any) => ({
            value: item.id.toString(),
            label: item.name
          })),
          causes: response.causes.map((item: any) => ({
            value: item.id.toString(),
            label: item.name
          })),
          actions: response.actions.map((item: any) => ({
            value: item.id.toString(),
            label: item.name
          }))
        });
      } else {
        console.error('Error fetching ticket classifications:', response.statusOper.message);
      }
    } catch (error) {
      console.error('Error fetching ticket classifications:', error);
    } finally {
      setLoading(false);
    }
  };

  const determineDisable = (path: string) => {
    if (path === '/helpdesk/ticket/new' || /\/helpdesk\/ticket\/edit\/\d+/.test(path)) {
      return false;
    }
    if (/\/helpdesk\/ticket\/read\/\d+/.test(path)) {
      return true;
    }
    return false;
  };

  const handleSelectionChange = (field: string, value: string | null) => {
    const numericValue = value || '0';
    form.setFieldValue(field, numericValue, 0);
    onSelectionChange(field, numericValue, 0);
  };

  const fields = [
    { name: 'problem', label: lang?.HELPDESK_TICKET_UPDATE_CLASSIFICATION_PROBLEM, data: classifications.problems },
    { name: 'fault', label: lang?.HELPDESK_TICKET_UPDATE_CLASSIFICATION_FAULT, data: classifications.faults },
    { name: 'cause', label: lang?.HELPDESK_TICKET_UPDATE_CLASSIFICATION_CAUSE, data: classifications.causes },
    { name: 'action', label: lang?.HELPDESK_TICKET_UPDATE_CLASSIFICATION_ACTION, data: classifications.actions }
  ];

  return (
    <Container fluid className="helpdesk-ticket-classification">
      {fields.map(field => {
        const selectedValue = String(form.values.pc?.[0]?.[field.name] || '0');

        return (
          <Select
            key={field.name}
            label={field.label}
            data={field.data}
            value={selectedValue}
            onChange={value => handleSelectionChange(field.name, value)}
            disabled={loading || determineDisable(location.pathname)}
          />
        );
      })}
    </Container>
  );
};

export default TicketClassification;
