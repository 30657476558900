import {
  AppShell,
  AppShellHeader,
  Burger,
  Container,
  Group,
  Image,
  UnstyledButton,
  useMantineColorScheme
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../client-redux/login';
import { clearSidebar, updateActiveTab } from '../../client-redux/sidebar';
import { useEffect, useMemo } from 'react';
import userLogo from '../../../assets/img/user-profile.svg';
import exitLogo from '../../../assets/img/exit.svg';
import { ViewMapProps } from '../../utils/view-map-props';
import zarphInitial from '../../../assets/img/zarph_Z_logo_white.svg';
import zarphLogo from '../../../assets/img/Versão_horizontal_branco_sem_assinatura.svg';
import './styles.scss';

export const Sidebar = () => {
  const views = useSelector<AppState, ViewMapProps[]>(state => state.sidebar.views);
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure();

  const isDesktop = useMediaQuery('(min-width: 576px)', false);
  const { colorScheme } = useMantineColorScheme({
    keepTransitions: true
  });

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', colorScheme);
  }, [colorScheme]);

  const logoutOper = () => {
    dispatch(logout());
    dispatch(clearSidebar());
    navigate('/login');
  };

  const renderProfileSection = () => (
    <Group classNames={{ root: 'profile-options' }}>
      <UnstyledButton
        component={Link}
        to="/profile"
        id="profile-tab"
        onClick={() => {
          dispatch(updateActiveTab(lang.USER_PROFILE));
        }}
      >
        <Group>
          <Image src={userLogo} />
          <span>{lang.USER_PROFILE}</span>
        </Group>
      </UnstyledButton>

      <UnstyledButton onClick={logoutOper} component={Link} to="/login">
        <Group>
          <Image src={exitLogo} />
          <span>{lang.GLOBAL_LOGOUT}</span>
        </Group>
      </UnstyledButton>
      <span className="react-version">{process.env.REACT_APP_VERSION}</span>
    </Group>
  );

  const renderViews = useMemo(() => {
    return (
      <>
        {isDesktop && (
          <Group className="navbar-header">
            <Image className={opened ? 'logo' : 'initial'} src={opened ? zarphLogo : zarphInitial} />
            <Burger opened={opened} onClick={() => toggle()} color="#fcfcfc" />
          </Group>
        )}
        <Group className="menu">
          {views.map((element, index) => (
            <UnstyledButton
              component={Link}
              to={element.link}
              onClick={() => {
                dispatch(updateActiveTab(element.label));
                if (opened) {
                  toggle();
                }
              }}
              id="sidebar-tab"
              key={index}
            >
              <Group>
                <Image src={element.icon} />
                {opened && element.label && <span>{lang[element.label]}</span>}
              </Group>
            </UnstyledButton>
          ))}
        </Group>
      </>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views, lang, opened, isDesktop]);

  const collapsedNavbar = (
    <Container className="collapsed-navbar">
      <Group classNames={{ root: 'options' }}>{renderViews}</Group>
      {renderProfileSection()}
    </Container>
  );

  return (
    <AppShell
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: !opened, desktop: !opened }
      }}
      padding="md"
      transitionDuration={0}
    >
      {/* Mobile Header */}
      {!isDesktop && (
        <AppShellHeader>
          <Burger opened={opened} onClick={toggle} color="#fcfcfc" />
          <Group>
            <Image src={zarphLogo} />
          </Group>
        </AppShellHeader>
      )}

      {/* Sidebar for Desktop */}
      <AppShell.Navbar>
        <Group classNames={{ root: 'options' }}>
          {renderViews}
          {renderProfileSection()}
        </Group>
      </AppShell.Navbar>

      {isDesktop && !opened && collapsedNavbar}
    </AppShell>
  );
};
