import { Group } from '@mantine/core';
import './styles.scss';

export interface HeaderProps {
  pageTitle?: string;
}

const Header = ({ pageTitle }: HeaderProps) => {
  return (
    <Group className="header">
      <h1>{pageTitle}</h1>
    </Group>
  );
};

export default Header;
