import { useState, useEffect, useMemo } from 'react';
import { Group, Loader, Container, Table, TextInput, Button, JsonInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import Header from '../../components/util/header';
import Background from '../../components/util/background';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import FilterBar from '../../components/filterbar/index';
import { addShop, deleteShop, fetchShops, IShopResponse, CurrencyRequest, updateShop } from '../../../libraries/shop';
import { LoginState } from '../../client-redux/login/reducer';
import CurrencyTable from '../equipment-config/currencies-table';
import { ICurrency, ICurrencyValuesForm } from '../../../libraries/currencies';
import { formatCurrAmount } from '../../utils/script';
import { IUser } from '../../../libraries/users';
import CustomModal from '../../components/custom-modal';
import './styles.scss';

const Locals = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, IUser>(state => state.login.user);
  const user = useSelector<AppState, LoginState>(state => state.login);
  /*   const shops = useSelector<AppState, ShopState> (state => state.shops.shops);
   */

  const controller = new AbortController();
  const [loading, setLoading] = useState<boolean>(false);
  const [entid, setEntid] = useState<number>(user.user ? user.user.entid : 0);
  const [currencies] = useState<boolean>(true);
  const currencyList = useSelector<AppState, ICurrency[]>(state => state.currencies.currencies);

  const [declarationAmount, setDeclarationAmount] = useState<number>();
  const [shop, setShop] = useState<IShopResponse>();
  const [noResults, setNoResults] = useState<boolean>(false);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [, setStatusOperCode] = useState<number>();
  const [selectedShop, setSelectedShop] = useState<number | null>(null);

  const [editingCell, setEditingCell] = useState<{ rowIndex: number; column: string } | null>(null);
  const [cellValue, setCellValue] = useState<number | string>('');
  const [isNewShop, setNewShop] = useState<boolean>(true);
  const [editable] = useState<boolean>(false);

  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [selectedCurrencies, setSelectedCurrencies] = useState<
    { gid: number; cid: number; entid: number; minAmount: number; maxAmount: number; decAmount: number }[]
  >([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const existingCids = new Set(shop && shop.shops[0] ? shop.shops[0].currencies?.map(currency => currency.cid) : []);

  /* Select Currencies handler */
  const filteredCid = useMemo(() => {
    return (
      currencyList?.filter(
        currency =>
          !existingCids.has(currency.cid) && !selectedCurrencies.some(selected => selected.cid === currency.cid)
      ) || []
    );
  }, [currencyList, existingCids, selectedCurrencies]);

  useEffect(() => {
    getShopsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    setSearchTrigger(true);
  };

  const getShopsList = () => {
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);

    fetchShops(currencies, entid)
      .then(response => {
        if (response.statusOper.code === 0) {
          setShop(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
          let foundDecAmount = false;
          for (let shop of response.shops) {
            if (shop?.currencies?.length > 0) {
              const currency = shop.currencies[0];
              if (currency?.decAmount !== undefined) {
                setDeclarationAmount(currency.decAmount);
                foundDecAmount = true;
                break;
              }
            }
          }
          if (!foundDecAmount) {
            setDeclarationAmount(undefined);
          }
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const form = useForm({
    initialValues: {
      entid: 0,
      currencies: [] as ICurrencyValuesForm[],
      sid: 0,
      name: '',
      description: '',
      external: '',
      cbendpoint: ''
    }
  });

  useEffect(() => {
    if (selectedShop !== null && shop?.shops[selectedShop]) {
      const selectedShopData = shop.shops[selectedShop];
      if (selectedShopData) {
        const updatedCurrencies = (selectedShopData.currencies || []).map(currency => ({
          gid: currency.gid ?? 0,
          entid: currency.entid ?? 0,
          cid: currency.cid ?? 0,
          minAmount: currency.minAmount ?? 0,
          maxAmount: currency.maxAmount ?? 0,
          decAmount: currency.decAmount ?? 0
        }));

        setSelectedCurrencies(updatedCurrencies);
        form.setValues({
          entid: loginInfo.entid,
          currencies: updatedCurrencies,
          sid: selectedShopData.sid || 0,
          name: selectedShopData.name || '',
          description: selectedShopData.description || '',
          external: selectedShopData.external || '',
          cbendpoint: selectedShopData.cbendpoint || ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShop, shop]);

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  /* Excell behaving table cell */
  const handleCellClick = (rowIndex: number, column: string, value: number | string, currency: ICurrency) => {
    const formattedValue = formatCurrAmount(value as number, currency, true, false);
    setEditingCell({ rowIndex, column });
    setCellValue(formattedValue);
  };

  const handleCellChange = (e: React.ChangeEvent<HTMLInputElement>, rowIndex: number, column: string) => {
    const inputValue = e.target.value;
    const numericValue = parseFloat(inputValue.replace(',', '.')) * 100;

    setCellValue(inputValue);

    if (!isNaN(numericValue)) {
      const updatedCurrencies = [...selectedCurrencies];
      if (column === 'minAmount') {
        updatedCurrencies[rowIndex].minAmount = numericValue;
      } else if (column === 'maxAmount') {
        updatedCurrencies[rowIndex].maxAmount = numericValue;
      } else if (column === 'decAmount') {
        updatedCurrencies[rowIndex].decAmount = numericValue;
      }
      setSelectedCurrencies(updatedCurrencies);
    }
  };

  const handleCellBlur = () => {
    setEditingCell(null);
    setCellValue('');
  };
  /* ------------------------------------------------ */

  const handleAddCurrency = (currency: ICurrencyValuesForm) => {
    const updatedCurrencies = [
      ...selectedCurrencies,
      {
        gid: currency.gid,
        cid: currency.cid,
        entid: currency.entid ?? loginInfo.entid ?? 0,
        minAmount: currency.minAmount || 0,
        maxAmount: currency.maxAmount || 0,
        decAmount: currency.decAmount || 0
      }
    ];
    setSelectedCurrencies(updatedCurrencies);
    form.setFieldValue('currencies', updatedCurrencies);
  };

  const handleRemoveCurrency = (cid: number) => {
    setSelectedCurrencies(prevSelectedCurrencies => {
      const updatedCurrencies = prevSelectedCurrencies.filter(currency => currency.cid !== cid);
      form.setFieldValue('currencies', updatedCurrencies);
      return updatedCurrencies;
    });
  };

  const submitForm = async (form: any) => {
    const request: CurrencyRequest = {
      entid: loginInfo.entid,
      currencies: form.currencies,
      sid: form.sid,
      name: form.name,
      description: form.description,
      ...(form.external && { external: form.external }),
      ...(form.cbendpoint && { cbendpoint: form.cbendpoint })
    };

    const formController = new AbortController();

    try {
      let response;
      let successMessage: string;
      let errorMessage: string;

      if (isNewShop) {
        response = await addShop(request, formController);
        successMessage = lang?.SHOP_ADD_ALERT_SUCCESS || 'Shop added successfully';
        errorMessage = lang?.SHOP_ADD_ALERT_ERROR || 'An error occurred while adding the shop';
      } else {
        response = await updateShop(request, formController);
        successMessage = lang?.SHOP_UPDATE_ALERT_SUCCESS || 'Shop updated successfully';
        errorMessage = lang?.SHOP_UPDATE_ALERT_ERROR || 'An error occurred while updating the shop';
      }

      if (response.statusOper && response.statusOper.code !== 0) {
        const responseErrorMessage = response.statusOper.message || 'An unknown error occurred.';
        setErrorMessage(`${errorMessage} (Code: ${response.statusOper.code}) ${responseErrorMessage}`);
        setShowErrorDialog(true);
      } else {
        setSuccessMessage(successMessage);
        setShowSuccessDialog(true);
        form.reset();
        getShopsList();

        setTimeout(() => {
          setShowSuccessDialog(false);
        }, 1000);
      }
    } catch (error) {
      setErrorMessage(`${error || 'Unknown error'}`);
      setShowErrorDialog(true);
      setTimeout(() => {
        setShowErrorDialog(false);
      }, 1000);
    }
  };

  const handleDeleteShop = async (entid: number, sid: number) => {
    try {
      const response = await deleteShop(entid, sid);

      let successMessage: string = lang?.SHOP_DELETE_ALERT_SUCCESS || 'Shop deleted successfully';
      let errorMessage: string = lang?.SHOP_DELETE_ALERT_ERROR || 'An error occurred while deleting the shop';

      if (response.statusOper && response.statusOper.code !== 0) {
        const responseErrorMessage = response.statusOper.message || 'An unknown error occurred.';
        setErrorMessage(`${errorMessage} (Code: ${response.statusOper.code}) ${responseErrorMessage}`);
        setShowErrorDialog(true);
        setTimeout(() => {
          setShowErrorDialog(false);
        }, 1000);
      } else {
        setSuccessMessage(successMessage);
        setShowSuccessDialog(true);
        setTimeout(() => {
          setShowSuccessDialog(false);
        }, 1500);
        setTimeout(() => {
          form.reset();
          setSelectedCurrencies([]);
          setNewShop(true);
          getShopsList();
        }, 1000);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred.';
      setErrorMessage(errorMessage);
      setShowErrorDialog(true);

      setTimeout(() => {
        setShowErrorDialog(false);
      }, 2000);
    }
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'locals' }}>
        <Group className="locals-table">
          <Header pageTitle={lang?.GLOBAL_LOCALS} />
          <FilterBar onSearch={onSearch} tableLoaded={getShopsList} filterByEntid={updateTableEntid} />

          <Table striped highlightOnHover verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{lang?.GLOBAL_LOCALS}</Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {loading && (!shop || searchTrigger) ? (
                <Table.Tr>
                  <Table.Td colSpan={16}>
                    <Loader color="#016273" />
                  </Table.Td>
                </Table.Tr>
              ) : (
                <>
                  {noResults ? (
                    <Table.Tr>
                      <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                        {lang?.GLOBAL_NO_RESULTS}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    shop?.shops.map((shop, index) => {
                      return (
                        <Table.Tr
                          key={index}
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setNewShop(false);
                            setSelectedShop(index);
                          }}
                        >
                          <Table.Td>{`${shop.sid} - ${shop.name}`}</Table.Td>
                        </Table.Tr>
                      );
                    })
                  )}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Group>

        <Group className="locals-edit">
          <form
            onSubmit={form.onSubmit(values => {
              submitForm(values);
            })}
          >
            {/* ID */}
            <TextInput
              label={'ID'}
              placeholder={'ID'}
              {...form.getInputProps('sid')}
              value={form.values.sid}
            ></TextInput>

            {/* Name */}
            <TextInput
              label={lang?.GLOBAL_NAME}
              placeholder={`${lang?.GLOBAL_NAME}`}
              {...form.getInputProps('name')}
              value={form.values.name}
              required
            ></TextInput>

            {/* Description */}
            <TextInput
              label={lang?.EQUIPMENT_DESCRIPTION}
              placeholder={`${lang?.EQUIPMENT_DESCRIPTION}`}
              {...form.getInputProps('description')}
              value={form.values.description}
              required
            ></TextInput>

            {/* External data */}
            <JsonInput
              label={lang?.ENTITY_EXTERNAL_DATA}
              placeholder={`${lang?.ENTITY_EXTERNAL_DATA} (JSON)`}
              value={form.values.external}
              onChange={value => form.setFieldValue('external', value)}
              minRows={1}
            />

            {/* C.B. Endpoint */}
            <TextInput
              label={'C.B. Endpoint'}
              placeholder={'Core Bank Endpoint'}
              {...form.getInputProps('cbendpoint')}
              value={form.values.cbendpoint}
            ></TextInput>

            <Group className="locals-currency-table">
              <Header pageTitle={lang?.GLOBAL_COINS} />
              <CurrencyTable
                selectedCurrencies={selectedCurrencies}
                currencyList={currencyList}
                loading={loading}
                editable={editable}
                filteredCid={filteredCid}
                onAddCurrency={handleAddCurrency}
                onRemoveCurrency={handleRemoveCurrency}
                form={form}
                onCellClick={handleCellClick}
                onCellChange={handleCellChange}
                onCellBlur={handleCellBlur}
                loginInfo={{ gid: loginInfo.gid ?? null }}
                editingCell={editingCell}
                cellValue={String(cellValue)}
                declarationAmount={declarationAmount}
                isNewShop={isNewShop}
              />
            </Group>

            {/* Buttons */}
            <Group>
              <Button
                color="#e4ae00"
                onClick={() => {
                  setNewShop(true);
                  form.reset();
                  setSelectedCurrencies([]);
                }}
              >
                {lang?.GLOBAL_CLEAR}
              </Button>

              <Button type="submit" color="#538539">
                {lang?.GLOBAL_SAVE_BUTTON}
              </Button>
              <Button
                color="#861724"
                onClick={() => {
                  handleDeleteShop(entid, form.values.sid);
                  setTimeout(() => {
                    getShopsList();
                  }, 2000);
                }}
              >
                {lang?.GLOBAL_DELETE}
              </Button>
            </Group>
          </form>
        </Group>

        <CustomModal
          opened={showSuccessDialog}
          onClose={() => setShowSuccessDialog(false)}
          message={successMessage}
          backgroundColor="#3BDF70"
          textColor="black"
        />

        <CustomModal
          opened={showErrorDialog}
          onClose={() => setShowErrorDialog(false)}
          message={errorMessage}
          backgroundColor="#E3353F"
          textColor="white"
        />
      </Container>
    </Background>
  );
};

export default Locals;
