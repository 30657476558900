import { ICurrency, ICurrencyValuesForm } from '../currencies';
import { ApiResponse, zps_ea_war } from '../zps';
import axios from 'axios';

export type IShop = {
  currencies?: ICurrency[];
  description: string;
  entid: number;
  gid: number;
  name: string;
  sid: number;
  external?: string;
  cbendpoint?: string;
};

export interface CurrencyRequest {
  entid: number;
  currencies: ICurrencyValuesForm[];
  sid: number;
  name: string;
  description: string;
  external?: string;
  cbendpoint?: string;
}

export interface IShopResponse extends ApiResponse {
  shops: IShop[];
  type: string;
  totalRows: number;
}

export const fetchShops = async (currencies?: boolean, entid?: number) => {
  const response = await axios.get(
    `${zps_ea_war}/shop` + 
    `${currencies ? '/?currencies=' + currencies : ''}` + 
    `${entid ? '&entid=' + entid : ''}`
  );
  return response.data;
};

export const addShop = async (payload: CurrencyRequest, controller: AbortController) => {
  try {
    const response = await axios.post(`${zps_ea_war}/shop`, payload, {
      signal: controller.signal
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateShop = async (payload: CurrencyRequest, controller: AbortController) => {
  try {
    const response = await axios.put(`${zps_ea_war}/shop`, payload, {
      signal: controller.signal
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteShop = async (entid: number, sid: number) => {
  try {
    const response = await axios.delete(`${zps_ea_war}/shop/?entid=${entid}&sid=${sid}`);
    return response.data; 
  } catch (error) {
    throw error; 
  }
};

