import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';

export type IPeriod = {
  amountPayAP: number;
  amountPayCash: number;
  period: number;
};

export type ISafe = {
  amountBills: number;
  amountCoins: number;
  bills: number;
  coins: number;
};

export type IRecycler = {
  bills: number[];
  coins: number[];
};

export type IDashboardEquipment = {
  cid: number;
  description: string;
  eid: number;
  entid: number;
  period?: IPeriod;
  recyclers?: IRecycler;
  safe?: ISafe;
  sid: number;
  state: number;
  totalAmount: number;
};

export interface IDashboardResponse extends ApiResponse {
  equipments: IDashboardEquipment[];
}

export const fetchDashboardInfo = async (entid: number, sid: number) => {
  const { data } = await axios.get(`${zps_ea_war}/equipment/dashboard/?entid=${entid}&sid=${sid}`);

  return data;
};
