import moment from 'moment';
import { useEffect, useState } from 'react';
import { Loader, Container, Table, Group } from '@mantine/core';
import Header from '../../components/util/header';
import Background from '../../components/util/background';
import { useSelector } from 'react-redux';
import {
  fetchLog,
  fetchTransactionList,
  fetchTransactionXLS,
  Transaction,
  TransactionResponse
} from '../../../libraries/transactions';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { formatCurrAmount, handleShortTransactionType, paymentType } from '../../utils/script';
import ShowTransaction from '../../components/show-transactions';
import PaginationComponent from '../../components/pagination';
import { LoginState } from '../../client-redux/login/reducer';
import { ICurrency } from '../../../libraries/currencies';
import FilterBar from '../../components/filterbar/index';
import { IShop } from '../../../libraries/shop';
import './styles.scss';

const Transactions = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const user = useSelector<AppState, LoginState>(state => state.login);
  const shops = useSelector<AppState, IShop[]>(state => state.shops.shops);
  const currencies = useSelector<AppState, ICurrency[]>(state => state.currencies.currencies);
  const controller = new AbortController();
  const [openDetails, setOpenDetails] = useState<boolean>(true);
  const [, setActiveRow] = useState<number>();
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction>();
  const [selectedTransactionCurrency, setSelectedTransactionCurrency] = useState<ICurrency>();
  const [loading, setLoading] = useState(false);
  const [beginDate, setBeginDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DDT00:00:00.000'));
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000'));
  const [entid, setEntid] = useState<number>(user.user?.entid);
  const [sid, setSid] = useState<number>(0);
  const [eid, setEid] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  /* const itemsPerPage = window.innerHeight < 600 ? 5 : Math.floor(window.innerHeight / 55 - 2); */
  const [limit, setLimit] = useState<number>(10);
  const [, setCurrentPage] = useState<number>(0);
  const [message, setMessage] = useState<TransactionResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number>();

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      getTransactionList();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const onSearch = () => {
    setSearchTrigger(true);
  };

  const getTransactionList = () => {
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);
    fetchTransactionList(`${beginDate}`, `${endDate}`, entid, sid, eid, offset, limit, totalRows, controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          // ('fetchTransactionList aborted');
        } else {
          // console.log('fetchTransactionList error: ', error);
        }
      })
      .finally(() => setLoading(false));
  };

  // ----------------- Modal information with logs -------------------//
  const showTransactionDetails = (n: number) => {
    if (message) {
      setActiveRow(n);
      setSelectedTransaction(message?.transactions[n]);
      if (currencies) {
        let matchingCurrency = currencies.find(currency => currency.cid === message?.transactions[n].cid);
        setSelectedTransactionCurrency(matchingCurrency);
      }
      setOpenDetails(true);
    }
    // console.log('activeRow: ', activeRow);
  };

  const getLog = () => {
    if (!selectedTransaction) return;
    return fetchLog(selectedTransaction.eid, selectedTransaction.period, selectedTransaction.code, controller);
  };
  //----------------------------------------------------------------//

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateEid = (newEid: number, callback?: () => void) => {
    setEid(newEid);
    if (callback) {
      callback();
    }
  };

  const updateTableEid = (newEid: number, callback?: () => void) => {
    updateEid(newEid);
    if (callback) {
      callback();
    }
  };

  const updateSid = (newSid: number, callback?: () => void) => {
    setSid(newSid);
    if (callback) {
      callback();
    }
  };

  const updateTableSid = (newSid: number, callback?: () => void) => {
    updateSid(newSid);
    if (callback) {
      callback();
    }
  };

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  useEffect(() => {
    getTransactionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, eid, sid, beginDate, endDate]);

  const getXLSReport = () => {
    return fetchTransactionXLS(beginDate, endDate, entid, sid, eid, controller);
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'transactions' }}>
        {openDetails && selectedTransaction && selectedTransactionCurrency ? (
          <ShowTransaction
            transaction={selectedTransaction}
            openDetails={openDetails}
            hideBtn={() => setOpenDetails(false)}
            lang={lang}
            currency={selectedTransactionCurrency}
            getLog={async () => getLog()}
          />
        ) : null}

        <Header pageTitle={lang?.TRANSACTIONS} />

        <FilterBar
          onSearch={onSearch}
          tableLoaded={getTransactionList}
          filterByEntid={updateTableEntid}
          filterBySid={updateTableSid}
          filterByEid={updateTableEid}
          updateBeginDate={setBeginDate}
          updateEndDate={setEndDate}
          XLSReport={getXLSReport}
        />

        <Table.ScrollContainer minWidth={500}>
          <Table striped highlightOnHover verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{lang?.EQUIPMENT_ID}</Table.Th>
                <Table.Th>{lang?.USER_DELEGATION}</Table.Th>
                <Table.Th>{lang?.PROCESSED_GLASS_PERIOD}</Table.Th>
                <Table.Th>{lang?.GLOBAL_CODE}</Table.Th>
                <Table.Th>{lang?.TRANSACTION_USER_CASHIER}</Table.Th>
                <Table.Th>{lang?.GLOBAL_DATE_TIME}</Table.Th>
                <Table.Th>{lang?.GLOBAL_TYPE}</Table.Th>
                <Table.Th>{lang?.GLOBAL_METHOD}</Table.Th>
                <Table.Th>{lang?.GLOBAL_AMOUNT}</Table.Th>
                <Table.Th>{lang?.TRANSACTION_RECEIVED}</Table.Th>
                <Table.Th>{lang?.TRANSACTION_DISPENSED}</Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {loading && (!message || searchTrigger) ? (
                <Table.Tr>
                  <Table.Td colSpan={16}>
                    <Loader color="#016273" />
                  </Table.Td>
                </Table.Tr>
              ) : (
                <>
                  {noResults ? (
                    <Table.Tr>
                      <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                        {lang?.GLOBAL_NO_RESULTS}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    message?.transactions.map((transaction, index) => {
                      const matchingCurrency = currencies?.find(c => c.cid === transaction?.cid);
                      const matchingShop = shops.find(shop => shop.sid === transaction.sid);
                      return (
                        <Table.Tr key={index} onClick={() => showTransactionDetails(index)}>
                          <Table.Td>{transaction.eid}</Table.Td>
                          <Table.Td>{matchingShop ? matchingShop.name : ''}</Table.Td>
                          <Table.Td>{transaction.period}</Table.Td>
                          <Table.Td>{transaction.code}</Table.Td>
                          <Table.Td>{transaction.uid ? transaction.uid : ''}</Table.Td>
                          <Table.Td>{moment(transaction.dateTime).format('YYYY-MM-DD HH:mm')}</Table.Td>
                          <Table.Td>{handleShortTransactionType(lang, transaction.type)}</Table.Td>
                          <Table.Td>{paymentType(lang, transaction.type)}</Table.Td>
                          <Table.Td>
                            {matchingCurrency ? formatCurrAmount(transaction.value || 0, matchingCurrency) : '-'}
                          </Table.Td>
                          <Table.Td>
                            {matchingCurrency ? formatCurrAmount(transaction.valueRec || 0, matchingCurrency) : '-'}
                          </Table.Td>
                          <Table.Td>
                            {matchingCurrency ? formatCurrAmount(transaction.valueDis || 0, matchingCurrency) : '-'}
                          </Table.Td>
                        </Table.Tr>
                      );
                    })
                  )}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>

        <Group>
          <PaginationComponent
            total={message?.totalRows}
            statusOperCode={statusOperCode}
            itemsPerPage={limit}
            onPageChange={changePage}
            onResultsChange={resultsSelect}
          />
        </Group>
      </Container>
    </Background>
  );
};

export default Transactions;
