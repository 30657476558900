import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { AppState } from '../../../client-redux/reducers';
import { Language } from '../../../languages/languageHandler';
import { DateTimePicker } from '@mantine/dates';
import moment from 'moment';
import { Button, Container, Grid, GridCol, Group, Loader, Select, Table, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import Header from '../../../components/util/header';
import Background from '../../../components/util/background';
import ChooseEntityModal from '../../components/choose-entity-modal';
import TicketClassification from '../../components/classification';
import TicketEditModal from '../../components/ticket-modal';
import { useEffect, useState } from 'react';
import {
  fetchHelpdeskTicketInfo,
  HeldeskTicketInfoUpdateRequests,
  IHelpdeskTicketInfoResponse,
  ticketInfoLines,
  ticketInfoPc,
  updateTicket
} from '../../../../libraries/helpdesk';
import { LoginState } from '../../../client-redux/login/reducer';
import CustomModal from '../../../components/custom-modal';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

export enum MODE {
  READ = 'read',
  EDIT = 'edit',
  NEW = 'new'
}

const Ticket = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const login = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const location = useLocation();

  const { ticketID } = useParams<{ ticketID: string }>();

  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState<boolean>(true);
  const [, setStatusOperCode] = useState<number>();

  const [ticket, setTicket] = useState<IHelpdeskTicketInfoResponse>();
  const [chooseEntityModal, setChooseEntityModal] = useState<boolean>(false);

  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [selectedAction, setSelectedAction] = useState<number>(0);
  const [selectedFault, setSelectedFault] = useState<number>(0);
  const [selectedCause, setSelectedCause] = useState<number>(0);
  const [selectedProblem, setSelectedProblem] = useState<number>(0);

  const [isTicletMOdalOpen, setIsTicketMOdalOpen] = useState<boolean>(false);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);

  const [isEditable, setIsEditable] = useState<boolean>(false);

  const communicationTypes = [
    { value: '', label: lang?.ENTITY_TYPE_NULL },
    { value: '0', label: lang?.USER_MOBILE },
    { value: '1', label: lang?.USER_EMAIL },
    { value: '2', label: 'auto' },
    { value: '3', label: 'site' }
  ];

  const issueTypes = [
    { value: '', label: lang?.ENTITY_TYPE_NULL },
    { value: '0', label: lang?.ERROR },
    { value: '1', label: lang?.HELPDESK_TICKET_UPDATE_CHANGE_REQUEST },
    { value: '2', label: lang?.HELPDESK_TICKET_UPDATE_INFORMATION_REQUEST },
    { value: '3', label: lang?.GLOBAL_OTHER }
  ];

  const intTypes = [
    { value: '', label: 'n/d' },
    { value: '0', label: lang?.HELPDESK_TICKET_UPDATE_INT_TYPE_REMOTE },
    { value: '1', label: lang?.HELPDESK_TICKET_UPDATE_INT_TYPE_LOCAL }
  ];

  const determineStatus = (status: number) => {
    switch (status) {
      case 0:
        return lang?.HELPDESK_TICKET_STATUS_0;
      case 1:
        return lang?.HELPDESK_TICKET_STATUS_1;
      case 2:
        return lang?.HELPDESK_TICKET_STATUS_2;
      case 3:
        return lang?.HELPDESK_TICKET_STATUS_3;
      case 4:
        return lang?.HELPDESK_TICKET_STATUS_4;
      case 5:
        return lang?.HELPDESK_TICKET_STATUS_5;
      case 6:
        return lang?.HELPDESK_TICKET_STATUS_6;
      default:
        return lang?.HELPDESK_TICKET_STATUS_0;
    }
  };

  const form = useForm({
    initialValues: {
      agent: { entid: 0, name: '' },
      commChannel: '0',
      contact: '',
      eid: 0,
      serialNumber: '',
      entid: 0,
      entityName: '',
      intType: '0',
      issueType: '0',
      lines: [] as ticketInfoLines[],
      openDT: '',
      pc: [{ action: 0, cause: 0, fault: 0, problem: 0 }] as ticketInfoPc[],
      product: '',
      sn: '',
      status: determineStatus(0),
      statusDT: '0',
      ticketID: 0,
      time: 0,
      urgency: 0
    }
  });

  const getTicketInfo = () => {
    if (!login.token) return;
    if (loading) {
      controller.abort();
    }
    setLoading(true);
    fetchHelpdeskTicketInfo(Number(ticketID), controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setTicket(response);
          const ticketPCs = response?.tickets[0]?.pc?.[0];
          setSelectedAction(ticketPCs.action);
          setSelectedCause(ticketPCs.cause);
          setSelectedFault(ticketPCs.fault);
          setSelectedProblem(ticketPCs.problem);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
        } else {
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (ticket?.tickets[0]) {
      const ticketInfo = ticket.tickets[0];
      setSelectedAction(ticketInfo?.pc?.[0]?.action || 0);
      setSelectedCause(ticketInfo?.pc?.[0]?.cause || 0);
      setSelectedFault(ticketInfo?.pc?.[0]?.fault || 0);
      setSelectedProblem(ticketInfo?.pc?.[0]?.problem || 0);

      form.setValues({
        ...form.values,
        agent: { entid: ticketInfo?.agent?.entid || 0, name: ticketInfo?.agent?.name || '' },
        commChannel: ticketInfo?.commChannel?.toString() || '0',
        contact: ticketInfo?.contact || '',
        eid: ticketInfo?.eid || 0,
        entid: ticketInfo?.entid || 0,
        serialNumber: ticketInfo?.sn || '',
        entityName: ticketInfo?.entityName || '',
        intType: ticketInfo?.intType?.toString() || '0',
        issueType: ticketInfo?.issueType?.toString() || '0',
        lines: ticketInfo?.lines || [],
        openDT: ticketInfo?.openDT || '',
        pc: ticketInfo?.pc?.[0]
          ? [ticketInfo.pc[0]]
          : [
              {
                action: selectedAction,
                cause: selectedCause,
                fault: selectedFault,
                problem: selectedProblem
              }
            ],
        product: ticketInfo?.product || '',
        sn: ticketInfo?.sn || '',
        status: determineStatus(ticketInfo?.status || 0),
        statusDT: ticketInfo?.statusDT || '',
        ticketID: ticketInfo?.ticketID || 0,
        time: ticketInfo?.time || 0,
        urgency: ticketInfo?.urgency || 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  // Submit form logic
  const submitForm = async (form: any) => {
    let request: HeldeskTicketInfoUpdateRequests = {
      commChannel: form.commChannel,
      contact: form.contact,
      eid: form.eid,
      entid: form.entid,
      intType: form.intType,
      issueType: form.issueType,
      openDT: form.openDT,
      pc: form.pc || [],
      system: form.system,
      ticketID: form.ticketID
    };

    const formController = new AbortController();

    try {
      const response = await updateTicket(request, formController);

      if (response.statusOper && response.statusOper.code !== 0) {
        const errorMessage = response.statusOper.message || 'An unknown error occurred.';
        setErrorMessage(
          `${lang?.HELPDESK_EDIT_TICKET_ALERT_UPDATED_ERROR} (Code: ${response.statusOper.code}) ${errorMessage}`
        );
        setShowErrorDialog(true);
      } else {
        setSuccessMessage(lang?.HELPDESK_EDIT_TICKET_ALERT_UPDATED_SUCCESS || 'Ticket edited successfully');
        setShowSuccessDialog(true);

        setTimeout(() => {
          setShowSuccessDialog(false);
        }, 1000);
      }
    } catch (error) {
      console.error('Error during the API call:', error);
      setErrorMessage(`${lang?.HELPDESK_EDIT_TICKET_ALERT_UPDATED_ERROR} (Status: ${errorMessage}`);
      setShowErrorDialog(true);
    }
    setTimeout(() => {
      getTicketInfo();
    }, 1000);
  };

  const determineMode = (path: any) => {
    switch (path) {
      case '/helpdesk/ticket/new':
        return 'new';
      case (path.match(/\/helpdesk\/ticket\/read\/\d+/) || {}).input:
        return 'read';
      case (path.match(/\/helpdesk\/ticket\/edit\/\d+/) || {}).input:
        return 'edit';
      default:
        return 'read';
    }
  };

  const mode = determineMode(location.pathname);

  const chooseEntid = (entid: number, name?: string) => {
    if (!entid) return;
    form.setFieldValue('entid', entid);
    form.setFieldValue('entityName', name || '');
    toggleChooseEntityModal();
  };

  useEffect(() => {
    getTicketInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  useEffect(() => {
    const path = location.pathname;
    const editable = /\/helpdesk\/ticket\/(edit|new)/.test(path);
    setIsEditable(editable);
  }, [location]);

  const handleClassificationChange = (field: string, value: string, index: number) => {
    if (form.values.pc[index]) {
      const updatedPc = [...form.values.pc];
      updatedPc[index] = { ...updatedPc[index], [field]: value };
      form.setFieldValue('pc', updatedPc);
    }
    form.setFieldValue(`${field}_${index}`, value);
  };

  const toggleChooseEntityModal = () => {
    setChooseEntityModal(prev => !prev);
  };

  const toggleEditTicketModal = (ticketData?: any) => {
    setIsTicketMOdalOpen(!isTicletMOdalOpen);
    if (ticketData) {
      setSelectedTicket(ticketData);
    }
  };

  return (
    <Background>
      <Container fluid classNames={{ root: mode === MODE.EDIT ? 'helpdesk-ticket-edit' : 'helpdesk-ticket-read' }}>
        <ChooseEntityModal
          lang={lang}
          isOpen={chooseEntityModal}
          hideBtn={() => toggleChooseEntityModal()}
          chooseEntid={chooseEntid}
        />
        {ticket && selectedTicket && (
          <TicketEditModal
            isOpen={isTicletMOdalOpen}
            hideBtn={() => setIsTicketMOdalOpen(false)}
            lang={lang}
            ticketData={selectedTicket}
            onReload={getTicketInfo}
          />
        )}

        <Header pageTitle={`${lang?.GLOBAL_TICKET} ${ticketID}`} />

        {/* Form initialization */}
        <form
          onSubmit={form.onSubmit(values => {
            submitForm(values);
          })}
        >
          <Grid>
            {/* Client */}
            <GridCol span={12}>
              <Group>
                <TextInput
                  label={lang?.GLOBAL_CLIENT}
                  value={`${form.values.entid} - ${form.values.entityName}`}
                  onClick={toggleChooseEntityModal}
                  disabled={!isEditable}
                />
                {isEditable && <Icon.IconSearch stroke={3} onClick={toggleChooseEntityModal} />}
              </Group>
            </GridCol>

            {/* Contact */}
            <GridCol span={12}>
              <TextInput
                label={lang?.USER_CONTACT_INFO}
                value={form.values.contact}
                disabled={!isEditable}
                {...form.getInputProps('contact')}
              />
            </GridCol>
            {/* Communication channel */}
            <GridCol span={12}>
              <Select
                label={lang?.HELPDESK_TICKET_UPDATE_COMMUNICATION_CHANNEL}
                data={communicationTypes}
                value={form.values.commChannel.toString()}
                disabled={!isEditable}
                {...form.getInputProps('commChannel')}
              />
            </GridCol>
            {/* EID */}
            <GridCol span={12}>
              <TextInput label={'EID'} value={form.values.eid} {...form.getInputProps('eid')} />
            </GridCol>
            {/* Serial number */}
            <GridCol span={12}>
              <TextInput
                label={lang?.EQUIPMENT_SERIAL_NUMBER}
                value={form.values.serialNumber}
                disabled={!isEditable}
                {...form.getInputProps('serialNumber')}
              />
            </GridCol>

            {/* Issue Types*/}
            <GridCol span={12}>
              <Select
                label={lang?.HELPDESK_TICKET_UPDATE_ISSUE_TYPES}
                data={issueTypes}
                value={form.values.issueType.toString()}
                disabled={!isEditable}
                {...form.getInputProps('issueType')}
              />
            </GridCol>

            {/* Open date */}
            <GridCol span={12}>
              <DateTimePicker
                label={lang?.HELPDESK_TICKET_UPDATE_OPEN_DT}
                value={form.values.openDT ? new Date(form.values.openDT) : null}
                onChange={date => form.setFieldValue('openDT', date ? moment(date).format() : '')}
                disabled={!isEditable}
              />
            </GridCol>

            {/* Last update */}
            <GridCol span={12}>
              <DateTimePicker
                label={lang?.HELPDESK_TICKET_UPDATE_LAST_UPDATE}
                value={form.values.statusDT ? new Date(form.values.statusDT) : null}
                onChange={date => form.setFieldValue('statusDT', date ? moment(date).format() : '')}
                disabled={!isEditable}
              />
            </GridCol>

            {/* Last status */}
            <GridCol span={12}>
              <TextInput
                label={lang?.HELPDESK_TICKET_UPDATE_LAST_STATUS}
                value={form.values.status}
                disabled={!isEditable}
                {...form.getInputProps('status')}
              />
            </GridCol>

            {/* Int type */}
            <GridCol span={12}>
              <Select
                label={lang?.HELPDESK_TICKET_UPDATE_INT_TYPE}
                data={intTypes}
                value={form.values.intType.toString()}
                disabled={!isEditable}
                {...form.getInputProps('intType')}
              />
            </GridCol>
          </Grid>

          {/* Classifications */}
          <TicketClassification onSelectionChange={handleClassificationChange} form={form} />
        </form>

        {/* Submit button */}
        {mode !== MODE.READ && (
          <Button
            onClick={e => {
              e.preventDefault();
              submitForm(form.values);
            }}
          >
            {lang?.GLOBAL_SAVE_BUTTON}
          </Button>
        )}

        <CustomModal
          opened={showSuccessDialog}
          onClose={() => setShowSuccessDialog(false)}
          message={successMessage}
          backgroundColor="#3BDF70"
          textColor="black"
        />

        <CustomModal
          opened={showErrorDialog}
          onClose={() => setShowErrorDialog(false)}
          message={errorMessage}
          backgroundColor="#E3353F"
          textColor="white"
        />

        <Group>
          <Table.ScrollContainer minWidth={500}>
            <Table striped highlightOnHover verticalSpacing="sm">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>{lang?.LOGIN_USER}</Table.Th>
                  <Table.Th>{lang?.EQUIPMENT_DESCRIPTION}</Table.Th>
                  <Table.Th>{lang?.GLOBAL_COMMENTS}</Table.Th>
                  <Table.Th>{lang?.EQUIPMENT_COM_STATE}</Table.Th>
                  <Table.Th>{lang?.GLOBAL_DATE}</Table.Th>
                  <Table.Th>{lang?.GLOBAL_TIME}</Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>
                {loading && (!form.values.lines || !ticket) ? (
                  <Table.Tr>
                    <Table.Td colSpan={6}>
                      <Loader color="#016273" />
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  <>
                    {noResults ? (
                      <Table.Tr>
                        <Table.Td colSpan={6} style={{ textAlign: 'center' }}>
                          {lang?.GLOBAL_NO_RESULTS}
                        </Table.Td>
                      </Table.Tr>
                    ) : (
                      form.values.lines.map((ticketLine, index) => {
                        return (
                          <Table.Tr
                            key={index}
                            onClick={() => toggleEditTicketModal(ticketLine)}
                            style={{ cursor: 'pointer' }}
                          >
                            <Table.Td>{`${ticketLine.user.name} ${ticketLine.user.entid} / ${ticketLine.user.uid}`}</Table.Td>
                            <Table.Td>{ticketLine.description}</Table.Td>
                            <Table.Td>{ticketLine.comments}</Table.Td>
                            <Table.Td>{determineStatus(ticketLine.status || 0)}</Table.Td>
                            <Table.Td>{moment(ticketLine.statusDT).format('DD/MM/YYYY HH:mm:ss')}</Table.Td>
                            <Table.Td>{`${ticketLine.time} min`}</Table.Td>
                          </Table.Tr>
                        );
                      })
                    )}
                  </>
                )}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </Group>
      </Container>
    </Background>
  );
};

export default Ticket;
