import { Modal } from '@mantine/core';

interface ModalProps {
    opened: boolean;
    onClose: any;
    message: string;
    backgroundColor: string;
    textColor:string;
  }

const CustomModal = ({ opened, onClose, message, backgroundColor, textColor }: ModalProps) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      withCloseButton={false}
      closeOnClickOutside
      styles={{
        body: {
          backgroundColor: backgroundColor,
          color: textColor
        }
      }}
    >
      {message}
    </Modal>
  );
};

export default CustomModal;
