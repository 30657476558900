import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppState } from '../../../client-redux/reducers';
import { LoginState } from '../../../client-redux/login/reducer';
import { Language } from '../../../languages/languageHandler';
import { Container, Group, Loader, Table } from '@mantine/core';
import { fetchHelpdeskTickets, IHelpdeskTicketsResponse } from '../../../../libraries/helpdesk';
import Header from '../../../components/util/header';
import Background from '../../../components/util/background';
import PaginationComponent from '../../../components/pagination';
import HelpdeskFilterBar from '../../components/helpdesk-filterbar';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

const TicketList = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const login = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const navigate = useNavigate();

  const [beginDate, setBeginDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000'));
  const [closed, setClosed] = useState<string>('');
  const [ticketList, setTicketList] = useState<IHelpdeskTicketsResponse>();

  const [ticket, setTicket] = useState<number>();
  const [eid, setEid] = useState<number>();
  const [entid, setEntid] = useState<number>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalRows] = useState<boolean>(true);

  const [statusOperCode, setStatusOperCode] = useState<number>();
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [, setCurrentPage] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [, setClientName] = useState<string | undefined>();

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      getTicketsList();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const getTicketsList = () => {
    if (!login.token) return;
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);
    fetchHelpdeskTickets(endDate, offset, limit, totalRows, controller, ticket, eid, entid, closed, beginDate)
      .then(response => {
        if (response.statusOper.code === 0) {
          setTicketList(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
        }
      })
      .finally(() => setLoading(false));
  };

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  const updateTicket = (newTicket: number, callback?: () => void) => {
    setTicket(newTicket);
    if (callback) {
      callback();
    }
  };

  const updateTableTicket = (newTicket: number, callback?: () => void) => {
    updateTicket(newTicket);
    if (callback) {
      callback();
    }
  };

  const updateEid = (newEid: number, callback?: () => void) => {
    setEid(newEid);
    if (callback) {
      callback();
    }
  };

  const updateTableEid = (newEid: number, callback?: () => void) => {
    updateEid(newEid);
    if (callback) {
      callback();
    }
  };

  const updateClient = (newClient?: number, newClientName?: string, callback?: () => void) => {
    setEntid(newClient);
    setClientName(newClientName);
    if (callback) {
      callback();
    }
  };

  const updateTableClient = (newClient?: number, newClientName?: string, callback?: () => void) => {
    updateClient(newClient, newClientName);
    if (callback) {
      callback();
    }
  };

  const updateStatus = (newStatus: string, callback?: () => void) => {
    setClosed(newStatus);
    if (callback) {
      callback();
    }
  };

  const updateTableStatus = (newStatus: string, callback?: () => void) => {
    updateStatus(newStatus);
    if (callback) {
      callback();
    }
  };

  const getUrgencyType = (urgency: number) => {
    switch (urgency) {
      case 0:
        return lang?.HELPDESK_TICKETS_URGENCY_0;
      case 1:
        return lang?.HELPDESK_TICKETS_URGENCY_1;
      case 2:
        return lang?.HELPDESK_TICKETS_URGENCY_2;
      case 3:
        return lang?.HELPDESK_TICKETS_URGENCY_3;
      case 4:
        return lang?.HELPDESK_TICKETS_URGENCY_4;
      default:
        throw new Error(`Invalid urgency ID: ${urgency}`);
    }
  };

  const getTicketStatus = (status: number) => {
    switch (status) {
      case 0:
        return { status, name: lang?.HELPDESK_TICKET_STATUS_0 };
      case 1:
        return { status, name: lang?.HELPDESK_TICKET_STATUS_1 };
      case 2:
        return { status, name: lang?.HELPDESK_TICKET_STATUS_2 };
      case 3:
        return { status, name: lang?.HELPDESK_TICKET_STATUS_3 };
      case 4:
        return { status, name: lang?.HELPDESK_TICKET_STATUS_4 };
      case 5:
        return { status, name: lang?.HELPDESK_TICKET_STATUS_5 };
      case 6:
        return { status, name: lang?.HELPDESK_TICKET_STATUS_6, disabled: true };
      default:
        throw new Error(`Invalid ticket status status: ${status}`);
    }
  };

  const onSearch = () => {
    setSearchParams(searchParams);
  };

  useEffect(() => {
    getTicketsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, ticket, eid, entid, closed, beginDate]);

  return (
    <Background>
      <Container fluid classNames={{ root: 'helpdesk-tickets' }}>
        <Header pageTitle={lang?.GLOBAL_TICKETS} />

        <HelpdeskFilterBar
          onSearch={onSearch}
          tableLoaded={getTicketsList}
          filteredByTicket={updateTableTicket}
          filterByEid={updateTableEid}
          filteredByClient={updateTableClient}
          filteredByStatus={updateTableStatus}
          updateBeginDate={setBeginDate || null}
          updateEndDate={setEndDate}
        />

        <Table.ScrollContainer minWidth={500}>
          <Table striped highlightOnHover verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>ID</Table.Th>
                <Table.Th>{lang?.GLOBAL_AGENT}</Table.Th>
                <Table.Th>{lang?.GLOBAL_CLIENT}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_ID}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_SERIAL_NUMBER}</Table.Th>
                <Table.Th>{lang?.GLOBAL_DATE}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_COM_STATE}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_COM_STATE}</Table.Th>
                <Table.Th>{lang?.GLOBAL_TIME}</Table.Th>
                <Table.Th>{lang?.GLOBAL_URGENCY}</Table.Th>
                {/*  <Table.Th></Table.Th> */}
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {loading && (!ticket || searchTrigger) ? (
                <Table.Tr>
                  <Table.Td colSpan={16}>
                    <Loader color="#016273" />
                  </Table.Td>
                </Table.Tr>
              ) : (
                <>
                  {noResults ? (
                    <Table.Tr>
                      <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                        {lang?.GLOBAL_NO_RESULTS}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    ticketList?.tickets.map((ticket, index) => {
                      return (
                        <Table.Tr
                          key={index}
                          /* style={{ cursor: 'pointer' }}
                                                    onClick={() => navigate(`/helpdesk/ticket/read/${ticket.ticketID}`)}
                           */
                        >
                          <Table.Td>{ticket.ticketID}</Table.Td>
                          <Table.Td>{ticket.agent?.name}</Table.Td>
                          <Table.Td>{ticket.entityName}</Table.Td>
                          <Table.Td>{ticket.eid}</Table.Td>
                          <Table.Td>{ticket.sn}</Table.Td>
                          <Table.Td>{moment(ticket.openDT).format('YYYY-MM-DD HH:mm')}</Table.Td>
                          <Table.Td>{getTicketStatus(ticket.status || 0).name}</Table.Td>
                          <Table.Td>{moment(ticket.statusDT).format('YYYY-MM-DD HH:mm')}</Table.Td>
                          <Table.Td>{ticket.time} min</Table.Td>
                          <Table.Td>{getUrgencyType(ticket.urgency || 0)}</Table.Td>
                          {/*      <Table.Td>
                            <Icon.IconEdit
                              stroke={2}
                              style={{ cursor: 'pointer' }}
                              onClick={event => {
                                event.stopPropagation(); // Prevent the row click event
                                navigate(`/helpdesk/ticket/edit/${ticket.ticketID}`);
                              }} 
                            />
                          </Table.Td> */}
                        </Table.Tr>
                      );
                    })
                  )}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>

        <Group>
          <PaginationComponent
            total={ticketList?.totalRows}
            statusOperCode={statusOperCode}
            itemsPerPage={limit}
            onPageChange={changePage}
            onResultsChange={resultsSelect}
          />
        </Group>
      </Container>
    </Background>
  );
};

export default TicketList;
