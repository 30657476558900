import moment from 'moment';
import { useEffect, useState } from 'react';
import { Loader, Container, Table, Group } from '@mantine/core';
import Header from '../../components/util/header';
import Background from '../../components/util/background';
import { useSelector } from 'react-redux';
import {
  fetchEquipmentPeriodReport,
  fetchEquipmentPeriodsSummaryResult,
  IPeriodResponse
} from '../../../libraries/enterprise-equip-periods';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { LoginState } from '../../client-redux/login/reducer';
import { formatCurrAmount, getShopName } from '../../utils/script';
import { ICurrency } from '../../../libraries/currencies';
import FilterBar from '../../components/filterbar/index';
import PaginationComponent from '../../components/pagination';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

const EnterpriseEquipPeriods = () => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);
  const user = useSelector<AppState, LoginState>(state => state.login);
  const currencies = useSelector<AppState, ICurrency[]>(state => state.currencies.currencies);
  const controller = new AbortController();
  const [loading, setLoading] = useState<boolean>(false);
  const [beginDate, setBeginDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DDT00:00:00.000'));
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000'));
  const [entid, setEntid] = useState<number>(user.user ? user.user.entid : 0);
  const [sid, setSid] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  /* const itemsPerPage = window.innerHeight < 600 ? 5 : Math.floor(window.innerHeight / 55 - 2); */
  const [limit, setLimit] = useState<number>(10);

  const [totalRows] = useState<boolean>(true);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [message, setMessage] = useState<IPeriodResponse>();
  const [, setTotalResults] = useState<number>(0);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      getPeriodsList();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const onSearch = () => {
    setCurrentPage(1);
    setOffset(0);
    setSearchTrigger(true);
  };

  const getPeriodsList = () => {
    if (loading) {
      setNoResults(false);
      controller.abort();
      // console.log('getEquipmentList cancelled');
    }
    setLoading(true);
    fetchEquipmentPeriodsSummaryResult(`${beginDate}`, `${endDate}`, entid, sid, offset, limit, totalRows, controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          // console.log('fetchEquipmentPeriodsSummaryResult aborted');
        } else {
          // handle other errors´
          // console.log('fetchEquipmentPeriodsSummaryResult: ', error);
        }
      })
      .finally(() => setLoading(false));
  };

  const getTotalResults = (periods: any[]) => {
    let totalCount = 0;
    periods.forEach(array => {
      if (Array.isArray(array)) {
        totalCount += array.length;
      }
    });
    return totalCount;
  };

  useEffect(() => {
    if (message) {
      const totalCount = getTotalResults(Object.values(message));
      setTotalResults(totalCount);
    }
  }, [message]);

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateSid = (newSid: number, callback?: () => void) => {
    setSid(newSid);
    if (callback) {
      callback();
    }
  };

  const updateTableSid = (newSid: number, callback?: () => void) => {
    updateSid(newSid);
    if (callback) {
      callback();
    }
  };

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
    setSearchTrigger(true);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(1);
    setOffset(0);
    setSearchTrigger(true);
  };

  useEffect(() => {
    getPeriodsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, offset, limit, entid, sid, beginDate, endDate]);

  const getPeriodReport = (format: string, eid: number, period: number) => {
    if (!format || !eid || !period) return;
    return fetchEquipmentPeriodReport(format, eid, period, controller);
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'enterpriseTransactions' }}>
        <Header pageTitle={lang?.ENTERPRISE_EQUIP_PERIODS} />

        <FilterBar
          onSearch={onSearch}
          tableLoaded={getPeriodsList}
          filterByEntid={updateTableEntid}
          filterBySid={updateTableSid}
          updateBeginDate={setBeginDate}
          updateEndDate={setEndDate}
        />

        <Table.ScrollContainer minWidth={500}>
          <Table striped verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{lang?.USER_DELEGATION}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_ID}</Table.Th>
                <Table.Th>{lang?.PROCESSED_GLASS_PERIOD}</Table.Th>
                <Table.Th>{lang?.ENTERPRISE_EQUIP_PERIODS_DT_OPEN}</Table.Th>
                <Table.Th>{lang?.ENTERPRISE_EQUIP_PERIODS_DT_CLOSE}</Table.Th>
                <Table.Th>{lang?.GLOBAL_AMOUNT}</Table.Th>
                <Table.Th>{lang?.ENTERPRISE_EQUIP_PERIODS_PAYMENT_AMOUNTS_MIN}</Table.Th>
                <Table.Th></Table.Th>
                <Table.Th></Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {loading && (!message || searchTrigger) ? (
                <Table.Tr>
                  <Table.Td colSpan={16}>
                    <Loader color="#016273" />
                  </Table.Td>
                </Table.Tr>
              ) : (
                <>
                  {noResults ? (
                    <Table.Tr>
                      <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                        {lang?.GLOBAL_NO_RESULTS}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    message?.periods.slice(offset, offset + limit).map((period, index) => {
                      const matchingCurrency = currencies?.find(c => c.cid === period?.periodAmounts?.[0]?.cid);
                      return (
                        <Table.Tr key={index}>
                          <Table.Td>{getShopName(period.sid)}</Table.Td>
                          <Table.Td>{period.eid}</Table.Td>
                          <Table.Td>{period.period}</Table.Td>
                          <Table.Td>{moment(period.dtOpen).format('YYYY-MM-DD HH:mm')}</Table.Td>
                          <Table.Td>{moment(period.dtClose).format('YYYY-MM-DD HH:mm')}</Table.Td>
                          <Table.Td>
                            {' '}
                            {matchingCurrency
                              ? formatCurrAmount(period?.periodAmounts?.[0]?.amount || 0, matchingCurrency)
                              : '-'}
                          </Table.Td>
                          <Table.Td>
                            {' '}
                            {matchingCurrency
                              ? formatCurrAmount(period?.periodAmounts?.[0]?.amountBilled || 0, matchingCurrency)
                              : '-'}
                          </Table.Td>
                          {period.useReport ? (
                            <>
                              <Table.Td onClick={() => getPeriodReport('pdf', period.eid, period.period)}>
                                <Icon.IconFileTypePdf stroke={2} />
                              </Table.Td>
                              <Table.Td onClick={() => getPeriodReport('xls', period.eid, period.period)}>
                                <Icon.IconFileTypeXls stroke={2} />
                              </Table.Td>
                              <Table.Td onClick={() => getPeriodReport('txt', period.eid, period.period)}>
                                <Icon.IconFileTypeTxt stroke={2} />{' '}
                              </Table.Td>
                            </>
                          ) : (
                            <>
                              <Table.Td></Table.Td>
                              <Table.Td></Table.Td>
                              <Table.Td></Table.Td>
                            </>
                          )}
                        </Table.Tr>
                      );
                    })
                  )}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>

        <Group>
          <PaginationComponent
            total={message?.totalRows}
            statusOperCode={statusOperCode}
            itemsPerPage={limit}
            onPageChange={changePage}
            onResultsChange={resultsSelect}
            initialPage={currentPage}
          />
        </Group>
      </Container>
    </Background>
  );
};

export default EnterpriseEquipPeriods;
