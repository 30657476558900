import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';

export type IEntity = {
  address?: string;
  description?: string;
  entid: number;
  gid: number;
  lang: number;
  name: string;
  nif?: number;
  ownerid: number;
  postalCode: string;
  printType: number;
  region: string;
  reportType: number;
  type: number;
  usePos: number;
  useShifts: number;
};

export type IEntityActivity = {
  description: string;
  id: number;
};

export type IEntityCurrency = {
  cid: number;
  entid?: number;
  eid: number;
  gid: number;
  maxAmount: number;
  minAmount: number;
  decAmount: number
};

export interface EntityRequest {
  entid: number;
  currencies: IEntityCurrency;
  sid: number;
  name: string;
  description: string;
  externalData: string;
  CBEndpoint: string
}

export interface IEntityResponse extends ApiResponse {
  entities: IEntity[];
  type: string;
}

export interface IEntityActivityResponse extends ApiResponse {
  list: IEntityActivity[];
  type: string;
}

export const fetchEntities = async () => {
  const response = await axios.get(`${zps_ea_war}/entity`);
  return response.data;
};
