import { useEffect, useMemo, useState } from 'react';
import store from '../../client-redux/store';
import { Button, Container, Grid, GridCol, Group, Select, TextInput } from '@mantine/core';
import Header from '../../components/util/header';
import Background from '../../components/util/background';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import {
  EquipmentUpdateRequest,
  IEquipmentCurrency,
  IEquipmentDetails,
  IEquipmentLanguage,
  fetchEquipmentDetails,
  updateEquipment
} from '../../../libraries/equipment';
import CurrencyTable from './currencies-table';
import LanguageTable from './languages-table';
import { IShop } from '../../../libraries/shop';
import { IUser } from '../../../libraries/users';
import { IEntity } from '../../../libraries/entity';
import { formatCurrAmount } from '../../utils/script';
import { ICurrency, ICurrencyValuesForm } from '../../../libraries/currencies';
import CustomModal from '../../components/custom-modal';
import './styles.scss';

const EquipmentConfigForm = () => {
  const loginInfo = useSelector<AppState, IUser>(state => state.login.user);
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const regions = useSelector<AppState, string[]>(state => state.regions.regions);
  const entities = useSelector<AppState, IEntity[]>(state => state.entities.entities);
  const currencyList = useSelector<AppState, ICurrency[]>(state => state.currencies.currencies);
  const languageList = store.getState().lang?.langSettings;
  const shops = useSelector<AppState, IShop[]>(state => state.shops.shops);
  const controller = new AbortController();

  const [loading, setLoading] = useState(false);
  const [equipment, setEquipment] = useState<IEquipmentDetails | undefined>(undefined);
  const [, setNoResults] = useState<boolean>(true);
  const { eid } = useParams<string>();
  const [, setStatusOperCode] = useState<number>();

  const [editingCell, setEditingCell] = useState<{ rowIndex: number; column: string } | null>(null);
  const [cellValue, setCellValue] = useState<number | string>('');

  const [selectedLanguages, setSelectedLanguages] = useState<
    { gid: number; eid: number; langid: number; langidx: number }[]
  >([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const existingLangIds = new Set(equipment?.equipment?.languages.map(language => language.langid) || []);

  const [selectedCurrencies, setSelectedCurrencies] = useState<
    { gid: number; eid: number; cid: number; minAmount: number; maxAmount: number }[]
  >([]);

  /* Select Languages handler */
  const filteredLang = useMemo(() => {
    return (
      languageList?.filter(
        language =>
          !existingLangIds.has(language.langid) &&
          !selectedLanguages.some(selected => selected.langid === language.langid)
      ) || []
    );
  }, [languageList, existingLangIds, selectedLanguages]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const existingCids = new Set(equipment?.equipment?.currencies.map(currency => currency.cid) || []);

  /* Select Currencies handler */
  const filteredCid = useMemo(() => {
    return (
      currencyList?.filter(
        currency =>
          !existingCids.has(currency.cid) && !selectedCurrencies.some(selected => selected.cid === currency.cid)
      ) || []
    );
  }, [currencyList, existingCids, selectedCurrencies]);

  const [editable, setEditable] = useState<boolean>(false);

  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    getEquipmentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm({
    initialValues: {
      entid: 0,
      sid: 0,
      bankid: 0,
      languages: [] as IEquipmentLanguage[],
      currencies: [] as IEquipmentCurrency[],
      region: '',
      description: '',
      maxCoins: 0,
      maxBills: 0,
      almostFullPerc: 0,
      fullPerc: 0,
      cardInsertionTimeout: 0,
      cardRemovalTimeout: 0,
      dataInsertionTimeout: 0,
      depositTimeout: 0,
      declarationTimeout: 0,
      fullValueCid: 0,
      almostFullValue: 0,
      fullValue: 0
    }
  });

  const getEquipmentDetails = () => {
    setLoading(true);
    fetchEquipmentDetails(Number(eid), controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setEquipment(response.equipments?.[0]);
          setStatusOperCode(response.statusOper.code);
          let equi = response.equipments[0]?.equipment;

          setSelectedLanguages(equi.languages);
          setSelectedCurrencies(equi.currencies);
          setEditable(equi.status !== 3 && equi.status !== 4);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const equipmentData = equipment?.equipment;

    if (equipmentData?.currencies) {
      const updatedCurrencies = (equipmentData.currencies || []).map(currency => ({
        gid: currency.gid ?? 0,
        eid: currency.eid ?? 0,
        cid: currency.cid ?? 0,
        minAmount: currency.minAmount ?? 0,
        maxAmount: currency.maxAmount ?? 0
      }));

      setSelectedCurrencies(updatedCurrencies);
      form.setValues(prevValues => {
        const newValues = {
          ...prevValues,
          entid: prevValues.entid || equipmentData.entid || 0,
          sid: prevValues.sid || equipmentData.sid || 0,
          bankid: prevValues.bankid || equipmentData.bankid || 0,
          languages: selectedLanguages && equipmentData.languages,
          currencies: updatedCurrencies,
          region: prevValues.region || equipmentData.region,
          description: prevValues.description || equipmentData.description || '',
          maxCoins: prevValues.maxCoins || equipmentData.maxCoins || 0,
          maxBills: prevValues.maxBills || equipmentData.maxBills || 0,
          almostFullPerc: prevValues.almostFullPerc || equipmentData.almostFullPerc || 0,
          fullPerc: prevValues.fullPerc || equipmentData.fullPerc || 0,
          cardInsertionTimeout: prevValues.cardInsertionTimeout || equipmentData.cardInsertionTimeout || 0,
          cardRemovalTimeout: prevValues.cardRemovalTimeout || equipmentData.cardRemovalTimeout || 0,
          dataInsertionTimeout: prevValues.dataInsertionTimeout || equipmentData.dataInsertionTimeout || 0,
          depositTimeout: prevValues.depositTimeout || equipmentData.depositTimeout || 0,
          declarationTimeout: prevValues.declarationTimeout || equipmentData.declarationTimeout || 0,
          fullValueCid: prevValues.fullValueCid || equipmentData.fullValueCid || 0,
          almostFullValue: prevValues.almostFullValue || equipmentData.almostFullValue || 0,
          fullValue: prevValues.fullValue || equipmentData.fullValue || 0
        };
        return newValues;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment]);

  const amountCurrency: ICurrency[] = currencyList.filter(currency => [1, 2].includes(currency.cid));
  const selectedCurrency = amountCurrency.find(currency => currency.cid === Number(form.values.fullValueCid));

  const [almostFullInput, setAlmostFullInput] = useState(
    selectedCurrency ? formatCurrAmount(form.values.almostFullValue, selectedCurrency, true, false) : ''
  );
  const [fullInput, setFullInput] = useState(
    selectedCurrency ? formatCurrAmount(form.values.fullValue, selectedCurrency, true, false) : ''
  );

  // Effect to sync form value changes with local input state
  useEffect(() => {
    setAlmostFullInput(
      selectedCurrency ? formatCurrAmount(form.values.almostFullValue, selectedCurrency, true, false) : ''
    );
    setFullInput(selectedCurrency ? formatCurrAmount(form.values.fullValue, selectedCurrency, true, false) : '');
  }, [form.values.almostFullValue, form.values.fullValue, selectedCurrency]);

  /* Excell behaving table cell */
  const handleCellClick = (rowIndex: number, column: string, value: number | string, currency: ICurrency) => {
    const formattedValue = formatCurrAmount(value as number, currency, true, false);
    setEditingCell({ rowIndex, column });
    setCellValue(formattedValue);
  };

  const handleCellChange = (e: React.ChangeEvent<HTMLInputElement>, rowIndex: number, column: string) => {
    const inputValue = e.target.value;
    const numericValue = parseFloat(inputValue.replace(',', '.')) * 100;

    setCellValue(inputValue);

    if (!isNaN(numericValue)) {
      const updatedCurrencies = [...selectedCurrencies];
      if (column === 'minAmount') {
        updatedCurrencies[rowIndex].minAmount = numericValue;
      } else if (column === 'maxAmount') {
        updatedCurrencies[rowIndex].maxAmount = numericValue;
      }

      setSelectedCurrencies(updatedCurrencies);
      form.setFieldValue('currencies', updatedCurrencies);
    }
  };

  const handleCellBlur = () => {
    setEditingCell(null);
    setCellValue('');
  };

  /* ------------------------------------------------ */

  const handleRemoveLangid = (langid: number) => {
    const updatedLanguages = selectedLanguages.filter(lang => lang.langid !== langid);
    form.setFieldValue('languages', updatedLanguages);
    setSelectedLanguages(updatedLanguages);
    return updatedLanguages;
  };

  const handleAddCurrency = (entity: ICurrencyValuesForm) => {
    const updatedCurrencies = [
      ...selectedCurrencies,
      {
        gid: entity.gid,
        cid: entity.cid,
        entid: entity.entid ?? loginInfo.entid ?? 0,
        eid: entity.eid ?? equipment?.equipment.eid ?? 0,
        minAmount: entity.minAmount || 0,
        maxAmount: entity.maxAmount || 0,
        decAmount: entity.decAmount || 0
      }
    ];
    setSelectedCurrencies(updatedCurrencies);
    form.setFieldValue('currencies', updatedCurrencies);
  };

  const handleRemoveCurrency = (cid: number) => {
    setSelectedCurrencies(prevSelectedCurrencies => {
      const updatedCurrencies = prevSelectedCurrencies.filter(currency => currency.cid !== cid);
      form.setFieldValue('currencies', updatedCurrencies);
      return updatedCurrencies;
    });
  };

  // Submit form logic
  const submitForm = async (form: any) => {
    let request: EquipmentUpdateRequest = {
      almostFullPerc: form.almostFullPerc,
      almostFullValue: form.almostFullValue,
      almostFullValueToShow: form.almostFullValueToShow || 0,
      amounts: form.amounts || [],
      bankid: form.bankid,
      cardInsertionTimeout: form.cardInsertionTimeout,
      cardRemovalTimeout: form.cardRemovalTimeout,
      currencies: form.currencies || [],
      dataInsertionTimeout: form.dataInsertionTimeout,
      declarationTimeout: form.declarationTimeout,
      depositTimeout: form.depositTimeout,
      eid: Number(eid),
      entid: loginInfo.entid,
      sid: form.sid,
      fullPerc: form.fullPerc,
      fullValue: form.fullValue,
      fullValueCid: form.fullValueCid || 0,
      fullValueCidObj: form.fullValueCidObj || {},
      fullValueToShow: form.fullValueToShow || 0,
      lang: loginInfo.lang,
      languages: form.languages || [],
      maxBills: form.maxBills,
      maxCoins: form.maxCoins,
      region: form.region,
      description: form.description
    };

    const formController = new AbortController();

    try {
      const response = await updateEquipment(request, formController);

      if (response.statusOper && response.statusOper.code !== 0) {
        const errorMessage = response.statusOper.message || 'An unknown error occurred.';
        setErrorMessage(
          `${lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR} (Code: ${response.statusOper.code}) ${errorMessage}`
        );
        setShowErrorDialog(true);
      } else {
        setSuccessMessage(lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_SUCCESS || 'Equipment updated successfully');
        setShowSuccessDialog(true);

        setTimeout(() => {
          setShowSuccessDialog(false);
        }, 1000);
      }
    } catch (error) {
      console.error('Error during the API call:', error);
      setErrorMessage(`${lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR} (Status: ${errorMessage}`);
      setShowErrorDialog(true);
    }
    setTimeout(() => {
      getEquipmentDetails();
    }, 1000);
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'equipment-config-form' }}>
        <Header pageTitle={lang?.EQUIPMENT_DETAILS_SUBTITLE} />

        {/* Form initialization */}
        <form
          onSubmit={form.onSubmit(values => {
            submitForm(values);
          })}
        >
          <Group>
            {/* Entity Select */}
            <Select
              size="sm"
              label={lang?.GLOBAL_ENTITY}
              data={[
                { value: '0', label: lang?.GLOBAL_ALL },
                ...(entities ? entities.map(entity => ({ value: entity.entid.toString(), label: entity.name })) : [])
              ]}
              value={form.values.entid.toString()}
              onChange={value => {
                const newEntid = Number(value);
                form.setFieldValue('entid', newEntid);
                form.setFieldValue('sid', 0);
              }}
              disabled={!editable}
            />

            {/* Shop Select */}
            <Select
              withCheckIcon={true}
              label={lang?.USER_DELEGATION}
              data={[
                { value: '0', label: lang?.GLOBAL_ALL },
                ...(shops
                  ? shops
                      .filter((elem: IShop) => elem.entid === form.values.entid)
                      .map(shop => ({ value: shop.sid.toString(), label: shop.name }))
                  : [])
              ]}
              value={form.values.sid.toString()}
              onChange={value => form.setFieldValue('sid', Number(value))}
              disabled={!editable}
              placeholder={lang?.GLOBAL_ALL}
            />
          </Group>

          <LanguageTable
            selectedLanguages={selectedLanguages}
            languageList={languageList}
            loading={loading}
            editable={editable}
            filteredLangId={filteredLang}
            onRemoveLanguage={handleRemoveLangid}
            form={form}
          />

          <CurrencyTable
            selectedCurrencies={selectedCurrencies}
            currencyList={currencyList}
            loading={loading}
            editable={editable}
            filteredCid={filteredCid}
            onAddCurrency={handleAddCurrency}
            onRemoveCurrency={handleRemoveCurrency}
            form={form}
            onCellClick={handleCellClick}
            onCellChange={handleCellChange}
            onCellBlur={handleCellBlur}
            loginInfo={{ gid: loginInfo.gid ?? null }}
            eid={eid ?? ''}
            editingCell={editingCell}
            cellValue={String(cellValue)}
          />

          {/* Time zone */}
          <Select
            label={lang?.USER_REGION}
            data={[
              ...(regions?.map(region => ({
                value: region,
                label: region
              })) || [])
            ]}
            value={form.values.region}
            searchable={true}
            {...form.getInputProps('region')}
            disabled={!editable}
          />

          <Grid>
            {/* Description */}
            <GridCol span={{ base: 12, md: 12, lg: 12 }}>
              <TextInput
                label={lang?.EQUIPMENT_DESCRIPTION}
                value={form.values.description}
                {...form.getInputProps('description')}
              />
            </GridCol>

            {/* Max coins in safe */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <TextInput
                label={lang?.EQUIPMENT_MAX_COINS_SAFE}
                value={form.values.maxCoins}
                {...form.getInputProps('maxCoins')}
              />
            </GridCol>

            {/* Max bills in safe */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <TextInput
                label={lang?.EQUIPMENT_MAX_BILLS_SAFE}
                value={form.values.maxBills}
                {...form.getInputProps('maxBills')}
              />
            </GridCol>

            {/* Almost full percentage */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <TextInput
                label={lang?.EQUIPMENT_ALMOST_FULL_SAFE}
                value={form.values.almostFullPerc}
                {...form.getInputProps('almostFullPerc')}
              />
            </GridCol>

            {/* Full percentage */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <TextInput
                label={lang?.EQUIPMENT_FULL_SAFE}
                value={form.values.fullPerc}
                {...form.getInputProps('fullPerc')}
              />
            </GridCol>

            {/*  Card insertion timeout */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <TextInput
                label={lang?.EQUIPMENT_CARD_INSERTION_TIME}
                value={form.values.cardInsertionTimeout}
                {...form.getInputProps('cardInsertionTimeout')}
              />
            </GridCol>

            {/* Card removal timeout */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <TextInput
                label={lang?.EQUIPMENT_CARD_REMOVAL_TIME}
                value={form.values.cardRemovalTimeout}
                {...form.getInputProps('cardRemovalTimeout')}
              />
            </GridCol>

            {/* Data insertion timeout */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <TextInput
                label={lang?.EQUIPMENT_DATA_INSERTION_TIME}
                value={form.values.dataInsertionTimeout}
                {...form.getInputProps('dataInsertionTimeout')}
              />
            </GridCol>

            {/* Deposit timeout */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <TextInput
                label={lang?.EQUIPMENT_DEPOSIT_INSERTION_TIME}
                value={form.values.depositTimeout}
                {...form.getInputProps('depositTimeout')}
              />
            </GridCol>

            {/* Choose declaration timeout */}
            <GridCol span={{ base: 12, md: 12, lg: 12 }}>
              <TextInput
                label={lang?.EQUIPMENT_DECLARATION_TIME}
                value={form.values.declarationTimeout}
                {...form.getInputProps('declarationTimeout')}
              />
            </GridCol>

            <Group>
              {/* Almost/Full amount currency */}
              <GridCol span={{ base: 12, md: 12, lg: 12 }}>
                <Select
                  label={lang?.EQUIPMENT_AMOUNT_CURRENCY}
                  data={amountCurrency.map(currency => ({
                    value: currency.cid.toString(),
                    label: currency.currencyStr
                  }))}
                  value={form.values.fullValueCid.toString() ?? ''}
                  onChange={value => form.setFieldValue('fullValueCid', Number(value))}
                  disabled={!editable}
                />
              </GridCol>

              {/* Almost Full Amount */}
              <GridCol span={{ base: 6, md: 6, lg: 6 }}>
                <TextInput
                  label={lang?.EQUIPMENT_ALMOST_FULL_AMOUNT}
                  value={almostFullInput}
                  onChange={event => {
                    const inputValue = event.currentTarget.value;
                    setAlmostFullInput(inputValue);
                  }}
                  onBlur={() => {
                    const sanitizedInput = almostFullInput.replace(',', '.');
                    const numericValue = parseFloat(sanitizedInput);

                    if (!isNaN(numericValue)) {
                      form.setFieldValue('almostFullValue', numericValue * 100);
                    } else {
                      form.setFieldValue('almostFullValue', 0);
                    }
                  }}
                  disabled={form.values.fullValueCid === 0 || !editable}
                />
              </GridCol>

              {/* Full Amount */}
              <GridCol span={{ base: 6, md: 6, lg: 6 }}>
                <TextInput
                  label={lang?.EQUIPMENT_FULL_AMOUNT}
                  value={fullInput}
                  onChange={event => {
                    const inputValue = event.currentTarget.value;
                    setFullInput(inputValue);
                  }}
                  onBlur={() => {
                    const sanitizedInput = fullInput.replace(',', '.');
                    const numericValue = parseFloat(sanitizedInput);
                    if (!isNaN(numericValue)) {
                      form.setFieldValue('fullValue', numericValue * 100);
                    } else {
                      form.setFieldValue('fullValue', 0);
                    }
                  }}
                  disabled={form.values.fullValueCid === 0 || !editable}
                />
              </GridCol>
            </Group>
          </Grid>
        </form>

        {/* Submit button */}
        <Button
          onClick={e => {
            e.preventDefault();
            submitForm(form.values);
          }}
        >
          {lang?.GLOBAL_SAVE_BUTTON}
        </Button>

        <CustomModal
          opened={showSuccessDialog}
          onClose={() => setShowSuccessDialog(false)}
          message={successMessage}
          backgroundColor="#3BDF70"
          textColor="black"
        />

        <CustomModal
          opened={showErrorDialog}
          onClose={() => setShowErrorDialog(false)}
          message={errorMessage}
          backgroundColor="#E3353F"
          textColor="white"
        />
      </Container>
    </Background>
  );
};

export default EquipmentConfigForm;
