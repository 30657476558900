import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, Table, Container, Group } from '@mantine/core';
import { useContextMenu } from 'mantine-contextmenu';
import Header from '../../../components/util/header';
import Background from '../../../components/util/background';
import ShowEquipment from '../../../components/show-equipment';
import { useSelector } from 'react-redux';
import { fetchHelpdeskEntitiesList, IHelpdeskEntityResponse } from '../../../../libraries/helpdesk';
import { AppState } from '../../../client-redux/reducers';
import { Language } from '../../../languages/languageHandler';
import PaginationComponent from '../../../components/pagination';
import HelpdeskFilterBar from '../../components/helpdesk-filterbar';
import CustomModal from '../../../components/custom-modal';
import './styles.scss';

const HelpdeskEntities: React.FC = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const controller = new AbortController();
  const navigate = useNavigate();
  const { showContextMenu } = useContextMenu();

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [activeRow] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [entid, setEntid] = useState<number>(0);
  const [type, setType] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [nif, setNif] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [, setCurrentPage] = useState<number>(0);
  const [message, setMessage] = useState<IHelpdeskEntityResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();
  const [newEnt, setNewEnt] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      getEntitiesList();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const onSearch = () => {
    setSearchTrigger(true);
  };

  const getEntitiesList = () => {
    if (loading) {
      setLoading(false);
      controller.abort();
    }
    setLoading(true);

    fetchHelpdeskEntitiesList(offset, limit, totalRows, controller, type, entid, name, nif)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
          setLoading(false);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);

          if (response.statusOper?.message) {
            setErrorMessage(response.statusOper.message);
          } else {
            setErrorMessage('An error occurred, but no specific error message is available.');
          }

          setShowErrorDialog(true);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
          // Handle other errors
        }
      })
      .finally(() => setLoading(false));
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateName = (newName: string, callback?: () => void) => {
    setName(newName);
    if (callback) {
      callback();
    }
  };

  const updateTableName = (newName: string, callback?: () => void) => {
    updateName(newName);
    if (callback) {
      callback();
    }
  };

  const updateNif = (newNif: number, callback?: () => void) => {
    setNif(newNif);
    if (callback) {
      callback();
    }
  };

  const updateTableNif = (newNif: number, callback?: () => void) => {
    updateNif(newNif);
    if (callback) {
      callback();
    }
  };

  const updateType = (newType: string, callback?: () => void) => {
    setType(newType);
    if (callback) {
      callback();
    }
  };

  const updateTableType = (newType: string, callback?: () => void) => {
    updateType(newType);
    if (callback) {
      callback();
    }
  };

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  useEffect(() => {
    getEntitiesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit]);

  const detectTypeName = (type: number | undefined) => {
    switch (type) {
      case 0:
        return lang?.ENTITY_TYPE_0;
      case 1:
        return lang?.ENTITY_TYPE_1;
      case 2:
        return lang?.ENTITY_TYPE_2;
      case 3:
        return lang?.ENTITY_TYPE_3;
      default:
        return lang?.ENTITY_TYPE_NULL;
    }
  };

  const toggleNewEntity = () => {
    setNewEnt(!newEnt);
  };

  const handleRowRightClick = (event: React.MouseEvent, entid: number) => {
    event.preventDefault();
    showContextMenu([
      {
        key: 'view',
        title: lang?.EDIT_ENTITY_LABEL_READ,
        onClick: () => navigate(`/helpdesk/entities/read/${entid}`)
      },
      {
        key: 'edit',
        title: lang?.EDIT_ENTITY_LABEL_EDIT,
        onClick: () => navigate(`/helpdesk/entities/edit/${entid}`)
      },
      {
        key: 'equipment',
        title: lang?.EQUIPMENT_CONSULT,
        onClick: () => navigate(`/helpdesk/equipments?entid=${entid}`)
      }
    ])(event);
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'helpdesk-entities' }}>
        {openDetails && activeRow && lang && (
          <ShowEquipment eid={activeRow} openDetails={openDetails} hideBtn={() => setOpenDetails(false)} lang={lang} />
        )}

        <Header pageTitle={lang?.GLOBAL_ENTITIES} />

        <HelpdeskFilterBar
          onSearch={onSearch}
          tableLoaded={getEntitiesList}
          filterByEntid={updateTableEntid}
          filterByName={updateTableName}
          filterByNIF={updateTableNif}
          filterByType={updateTableType}
          newEntity={toggleNewEntity}
        />

        <Table.ScrollContainer minWidth={500}>
          <Table striped highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{lang?.GLOBAL_GROUP}</Table.Th>
                <Table.Th>{lang?.GLOBAL_ENTITY}</Table.Th>
                <Table.Th>{lang?.GLOBAL_NAME}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_DESCRIPTION}</Table.Th>
                <Table.Th>{lang?.GLOBAL_TYPE}</Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {loading && (!message || searchTrigger) ? (
                <Table.Tr>
                  <Table.Td colSpan={5}>
                    <Loader color="#016273" />
                  </Table.Td>
                </Table.Tr>
              ) : noResults ? (
                <Table.Tr>
                  <Table.Td colSpan={5} style={{ textAlign: 'center' }}>
                    {lang?.GLOBAL_NO_RESULTS}
                  </Table.Td>
                </Table.Tr>
              ) : (
                message?.entities?.map((entity, index) => (
                  <Table.Tr
                    key={index}
                    onContextMenu={event => handleRowRightClick(event, entity.entid)}
                    style={{ cursor: 'context-menu' }}
                  >
                    <Table.Td>{entity.gid}</Table.Td>
                    <Table.Td>{entity.entid}</Table.Td>
                    <Table.Td>{entity.name}</Table.Td>
                    <Table.Td>{entity.description}</Table.Td>
                    <Table.Td>{detectTypeName(entity.type)}</Table.Td>
                  </Table.Tr>
                ))
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>

        <Group>
          <PaginationComponent
            total={message?.totalRows}
            statusOperCode={statusOperCode}
            itemsPerPage={limit}
            onPageChange={changePage}
            onResultsChange={resultsSelect}
          />
        </Group>

        <CustomModal
          opened={showErrorDialog}
          onClose={() => setShowErrorDialog(false)}
          message={errorMessage}
          backgroundColor="#E3353F"
          textColor="white"
        />
      </Container>
    </Background>
  );
};

export default HelpdeskEntities;
