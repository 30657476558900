import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DateTimePicker } from '@mantine/dates';
import { Group, Button, TextInput, Select } from '@mantine/core';
import { useSelector } from 'react-redux';
import { AppState } from '../../../client-redux/reducers';
import { useState } from 'react';
import { Language } from '../../../languages/languageHandler';
import { IShop } from '../../../../libraries/shop';
import ChooseEntityModal from '../choose-entity-modal';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

const HelpdeskFilterBar = ({
  onSearch,
  tableLoaded,
  filterByEntid,
  filterBySid,
  filterByEtid,
  filterBySN,
  filterByName,
  filterByNIF,
  filterByType,
  filteredByTicket,
  filterByEid,
  filteredByClient,
  filteredByStatus,
  updateBeginDate,
  updateEndDate,
  XLSReport,
  newEntity,
  newTicket
}: {
  onSearch: () => void;
  tableLoaded: () => void;
  filterByEntid?: (newEntId: number, callback?: () => void) => void;
  filterBySid?: (newSid: number, callback?: () => void) => void;
  filterByEtid?: (newEtid: number, callback?: () => void) => void;
  filterBySN?: (newSN: string, callback?: () => void) => void;
  filterByName?: (newName: string, callback?: () => void) => void;
  filterByNIF?: (newNIF: number, callback?: () => void) => void;
  filterByType?: (newType: string, callback?: () => void) => void;
  filteredByTicket?: (newType: number, callback?: () => void) => void;
  filterByEid?: (newEid: number, callback?: () => void) => void;
  filteredByClient?: (newClient: number, newClientName?: string, callback?: () => void) => void;
  filteredByStatus?: (newType: string, callback?: () => void) => void;
  updateBeginDate?: (newBeginDate: string | null) => void;
  updateEndDate?: (newEndDate: string) => void;
  XLSReport?: () => void;
  newEntity?: () => void;
  newTicket?: () => void;
}) => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const shops = useSelector<AppState, IShop[]>(state => state.shops.shops);
  const navigate = useNavigate();

  const [entid, setEntid] = useState<number>();
  const [sid, setSid] = useState<number>(0);
  const [, setEid] = useState<number>();
  const [, setEtid] = useState<number>();
  const [, setType] = useState<string>('');
  const [, setSn] = useState<string>('');
  const [, setName] = useState<string>('');
  const [, setNIF] = useState<number>();
  const [, setCurrentPage] = useState(1);
  const [, setTicket] = useState<number>();
  const [client, setClient] = useState<number>();
  const [clientName, setClientName] = useState<string | undefined>();
  const [, setTicketStatus] = useState<string>();
  const [beginDate, setBeginDate] = useState<string | null>(null);
  const [, setEndDate] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000')) ?? null;

  const [chooseEntityModal, setChooseEntityModal] = useState<boolean>(false);

  const entityTypes = [
    { value: '', label: lang?.ENTITY_TYPE_NULL },
    { value: '0', label: lang?.ENTITY_TYPE_0 },
    { value: '1', label: lang?.ENTITY_TYPE_1 },
    { value: '2', label: lang?.ENTITY_TYPE_2 },
    { value: '3', label: lang?.ENTITY_TYPE_3 }
  ];

  const handleSearch = () => {
    tableLoaded();
    onSearch();
    if (!beginDate) {
      setBeginDate('');
    }
    setCurrentPage(1);
  };

  const chooseEntid = (client: number, name?: string) => {
    if (client) {
      setClient(client);
      if (name) {
        setClientName(name);
      }
      filteredByClient?.(client, name);
    }
    toggleChooseEntityModal();
  };

  /* Entity handle*/
  const handleEntid = (value: number) => {
    if (value !== null) {
      const selectedEntid = Number(value);
      filterByEntid?.(selectedEntid);
      setEntid(selectedEntid);
    }
  };

  /* Delegation handle */
  const handleNewTableBySid = (value: number | 0) => {
    if (value !== null) {
      const selectedSid = Number(value);
      filterBySid?.(selectedSid);
      setSid(selectedSid);
    }
  };

  /* Etid handle*/
  const handleEtid = (value: number) => {
    if (value !== null) {
      const selectedEtid = Number(value);
      filterByEtid?.(selectedEtid);
      setEtid(selectedEtid);
    }
  };

  /* SN handle*/
  const handleSN = (value: string) => {
    if (value !== null && filterBySN) {
      const selectedSN = String(value);
      filterBySN?.(selectedSN);
      setSn(selectedSN!);
    }
  };

  /* EID handle*/
  const handleEID = (value: number) => {
    if (value !== null) {
      const selectedEid = Number(value);
      filterByEid?.(selectedEid, () => {});
      setEid(selectedEid);
    }
  };

  /* Name handle*/
  const handlename = (value: string) => {
    if (value !== null) {
      const selectedName = String(value);
      filterByName?.(selectedName, () => {});
      setName(selectedName);
    }
  };

  /* NIF handle*/
  const handleNIF = (value: number) => {
    if (value !== null) {
      const selectedNIF = Number(value);
      filterByNIF?.(selectedNIF, () => {});
      setNIF(selectedNIF);
    }
  };

  /* Type handle*/
  const handleType = (value: string) => {
    if (value !== null) {
      const selectedType = String(value);
      filterByType?.(selectedType);
      setType(selectedType);
    }
  };

  /* Ticket handle*/
  const handleTicket = (value: number) => {
    if (value !== null) {
      const selectedTicket = Number(value);
      filteredByTicket?.(selectedTicket, () => {});
      setTicket(selectedTicket);
    }
  };

  /* Client handle*/
  const handleClient = (value: number, name?: string) => {
    if (value !== null) {
      const selectedClient = Number(value);
      filteredByClient?.(selectedClient, name, () => {});
      setClient(selectedClient);
      if (name) setClientName(name);
    }
  };

  const handleStatus = (value: string) => {
    if (value === '') {
      const selectedStatus = '';
      filteredByStatus?.(selectedStatus, () => {});
      setTicketStatus(selectedStatus);
    } else if (value === 'true') {
      const selectedStatus = 'true';
      filteredByStatus?.(selectedStatus, () => {});
      setTicketStatus(selectedStatus);
    } else {
      const selectedStatus = 'false';
      filteredByStatus?.(selectedStatus, () => {});
      setTicketStatus(selectedStatus);
    }
  };

  /* Begin handle*/
  const handleBeginDateChange = (date: Date | null) => {
    if (date) {
      const newBeginDate = moment(date).toISOString();
      updateBeginDate?.(newBeginDate);
      setBeginDate(newBeginDate);
    } else {
      updateBeginDate?.('');
      setBeginDate(null);
    }
  };

  /* End handle*/
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const newEndDate = moment(date).toISOString();
      updateEndDate?.(newEndDate!);
      setEndDate(newEndDate!);
    }
  };

  const toggleChooseEntityModal = () => {
    setChooseEntityModal(!chooseEntityModal);
  };

  return (
    <Group classNames={{ root: 'helpdesk-filterBar' }}>
      <ChooseEntityModal
        lang={lang}
        isOpen={chooseEntityModal}
        hideBtn={() => toggleChooseEntityModal()}
        chooseEntid={chooseEntid}
      />
      {/* Entid */}
      {filterByEntid && (
        <TextInput label={lang?.GLOBAL_ENTITY} onChange={event => handleEntid(Number(event.target.value))} />
      )}

      {filterBySid && (
        /* Delegation */
        <Select
          withCheckIcon={true}
          label={lang?.USER_DELEGATION}
          data={[
            { value: '0', label: lang?.GLOBAL_ALL },
            ...(shops
              ? shops
                  .filter((elem: IShop) => elem.entid === entid)
                  .map(shop => ({ value: shop.sid.toString(), label: shop.name }))
              : [])
          ]}
          value={sid.toString()}
          onChange={value => handleNewTableBySid(Number(value))}
        />
      )}
      {/* Etid */}
      {filterByEtid && (
        <TextInput label={lang?.GLOBAL_TYPE} onChange={event => handleEtid(Number(event.target.value))} />
      )}
      {/* Serial Number */}
      {filterBySN && <TextInput label={'SN'} onChange={event => handleSN(String(event.target.value))} />}
      {/* EID */}
      {filterByEid && <TextInput label={'EID'} onChange={event => handleEID(Number(event.target.value))} />}
      {/* Name */}
      {filterByName && (
        <TextInput label={lang?.GLOBAL_NAME} onChange={event => handlename(String(event.target.value))} />
      )}
      {/* NIF */}
      {filterByNIF && <TextInput label={'NIF'} onChange={event => handleNIF(Number(event.target.value))} />}
      {/* Type */}
      {filterByType && (
        <Select
          label={lang?.GLOBAL_TYPE}
          data={entityTypes}
          placeholder={lang?.GLOBAL_ALL}
          onChange={value => handleType(String(value))}
        />
      )}

      {/* Ticket */}
      {filteredByTicket && <TextInput label={'Ticket'} onChange={event => handleTicket(Number(event.target.value))} />}

      {/* Client */}
      {filteredByClient && (
        <Group>
          <TextInput
            label={lang?.GLOBAL_CLIENT}
            value={client ? `${client} - ${clientName || ''}` : ''}
            onChange={event => handleClient(Number(event.target.value))}
          />
          <Icon.IconSearch stroke={3} onClick={toggleChooseEntityModal} />
        </Group>
      )}

      {/* Status */}
      {filteredByStatus && (
        <Select
          label={lang?.EQUIPMENT_COM_STATE}
          data={[
            { value: '', label: lang?.ENTITY_TYPE_NULL },
            { value: 'false', label: lang?.HELPDESK_TICKET_STATUS_0 },
            { value: 'true', label: lang?.HELPDESK_TICKET_STATUS_1 }
          ]}
          placeholder={lang?.GLOBAL_ALL}
          onChange={value => handleStatus(String(value))}
        />
      )}

      {/* Begin */}
      {updateBeginDate && (
        <DateTimePicker
          clearable
          withSeconds
          label={lang?.EVENT_BEGIN_DATE_PLACEHOLDER}
          value={beginDate ? new Date(beginDate) : null}
          onChange={handleBeginDateChange}
          placeholder={lang?.GLOBAL_PICK_A_DATE}
        />
      )}

      {/* End */}
      {updateEndDate && (
        <DateTimePicker
          clearable
          withSeconds
          label={lang?.EVENT_END_DATE_PLACEHOLDER}
          defaultValue={moment().endOf('day').toDate()}
          onChange={date => handleEndDateChange(date)}
          placeholder={lang?.GLOBAL_PICK_A_DATE}
        />
      )}

      {/* Search */}
      <Button radius="md" className="search-button" onClick={handleSearch}>
        {lang?.GLOBAL_SEARCH}
      </Button>
      {/* Excel */}
      {XLSReport && (
        <Button radius="md" className="XLS-xutton" onClick={XLSReport}>
          <Icon.IconFileTypeXls stroke={2} />
          <span>XLS</span>
        </Button>
      )}
      {/* Add Entity */}
      {newEntity && (
        <Button
          radius="md"
          onClick={() => {
            navigate(`/helpdesk/entities/new/0`);
          }}
        >
          <Icon.IconUsersPlus />
        </Button>
      )}

      {/* Add Ticket */}
      {newTicket && (
        <Button
          radius="md"
          onClick={() => {
            navigate(`/helpdesk/ticket/new`);
          }}
        >
          <Icon.IconUsersPlus />
        </Button>
      )}
    </Group>
  );
};

export default HelpdeskFilterBar;
