import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Loader, Table, Container, TextInput, Select, Button, Group } from '@mantine/core';
import { useSelector } from 'react-redux';
import {
  fetchEquipmentCurrency,
  fetchEquipmentWCXLS,
  GetEquipmentCurrencyResponse,
  IEquipmentCurrency,
  updateEquipmentWorkingCapital
} from '../../../libraries/equipment';
import { AppState } from '../../client-redux/reducers';
import { IShop } from '../../../libraries/shop';
import { Language } from '../../languages/languageHandler';
import { LoginState } from '../../client-redux/login/reducer';
import Header from '../../components/util/header';
import Background from '../../components/util/background';
import CustomModal from '../../components/custom-modal';
import { delegationCheck, formatCurrAmount } from '../../utils/script';
import { ICurrency } from '../../../libraries/currencies';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

enum MODE {
  READ = 'read',
  EDIT = 'edit'
}

const EquipmentCurrency = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const login = useSelector<AppState, LoginState>(state => state.login);
  const shops = useSelector<AppState, IShop[]>(state => state.shops.shops);
  const currencies = useSelector<AppState, ICurrency[]>(state => state.currencies.currencies);
  const controller = new AbortController();
  const location = useLocation();
  const sid = location.state?.sid;

  const [loading, setLoading] = useState(false);
  const [entid] = useState<number>(login.user?.entid);
  const [cid] = useState<number>();
  const { eid } = useParams<string>();

  const [equipment, setEquipment] = useState<GetEquipmentCurrencyResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [, setStatusOperCode] = useState<number | undefined>();
  const [updatedValues, setUpdatedValues] = useState<Record<number, number>>({});
  const [selectedCid, setSelectedCid] = useState<number | null>(null);

  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const filteredEquipment = equipment?.list.filter(item => item.cid === selectedCid);

  useEffect(() => {
    getEquipmentCurrency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEquipmentCurrency = () => {
    if (!login.token) {
      return;
    }
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);
    fetchEquipmentCurrency(Number(eid), controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setEquipment(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
        }
      })
      .finally(() => setLoading(false));
  };

  const checkForCurrencyType = (type: number) => {
    if (type === 1) {
      return lang?.GLOBAL_COIN;
    } else if (type === 2) {
      return lang?.GLOBAL_BILL;
    } else {
      return 'Unknown Type';
    }
  };

  useEffect(() => {
    getEquipmentCurrency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entid, cid]);

  useEffect(() => {
    if (equipment?.list.length) {
      setSelectedCid(equipment.list[0].cid);
    }
  }, [equipment]);

  const determineMode = (path: any) => {
    switch (path) {
      case (path.match(/\/equipment\/currency\/read\/\d+/) || {}).input:
        return 'read';
      case (path.match(/\/equipment\/currency\/edit\/\d+/) || {}).input:
        return 'edit';
      default:
        return 'read';
    }
  };

  const mode = determineMode(location.pathname);

  const handleCurrencyChange = (cid: string | null) => {
    setSelectedCid(cid ? Number(cid) : null);
  };

  const handleInputChange = (cid: number, index: number, newValue: number) => {
    setUpdatedValues(prev => ({ ...prev, cid, [index]: newValue }));
  };

  const handleSaveWC = async (cid: number) => {
    const payload: IEquipmentCurrency = {
      cid,
      denominations: Object.entries(updatedValues)
        .filter(([index, wc]) => index !== 'null' && !isNaN(Number(index)) && wc !== undefined) // Filter out null or invalid indices
        .map(([index, wc]) => ({
          index: parseInt(index, 10),
          wc
        }))
    };

    try {
      if (mode === MODE.EDIT) {
        const response = await updateEquipmentWorkingCapital({ eid: Number(eid), currency: payload }, controller);

        if (response.statusOper?.code === 0) {
          const successMessage = lang?.EQUIPMENT_UPDATE_WC_SUCCESS || 'Update successful.';
          setSuccessMessage(successMessage);
          setShowSuccessDialog(true);

          setTimeout(() => {
            setShowSuccessDialog(false);
          }, 1500);

          setTimeout(() => {
            getEquipmentCurrency();
          }, 1500);
        } else {
          throw new Error(
            `${lang?.EQUIPMENT_UPDATE_WC_ERROR} ${response.statusOper.code}: ${response.statusOper.message}` ||
              'Update failed.'
          );
        }
      }
    } catch (error: any) {
      const errorMessage = error.message || 'Error occurred while updating.';
      setErrorMessage(errorMessage);
      setShowErrorDialog(true);

      setTimeout(() => {
        setShowErrorDialog(false);
      }, 2000);
    }
  };

  const handleClearWC = () => {
    if (!equipment) return;

    const clearedValues = equipment.list.reduce(
      (acc, equipmentCurrency) => {
        equipmentCurrency.denominations?.forEach(denomination => {
          acc[denomination.index] = 0;
        });
        return acc;
      },
      {} as Record<number, number>
    );
    setUpdatedValues(clearedValues);
  };

  const handleFillWithExistRecycler = () => {
    if (!equipment) return;

    const updated = equipment.list.reduce(
      (acc, equipmentCurrency) => {
        equipmentCurrency.denominations?.forEach(denomination => {
          if (denomination.existRecycler !== undefined) {
            acc[denomination.index] = denomination.existRecycler;
          }
        });
        return acc;
      },
      {} as Record<number, number>
    );

    setUpdatedValues(updated);
  };

  const getXLSReport = () => {
    return fetchEquipmentWCXLS(Number(eid), controller);
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'equipment-currency' }}>
        <Header pageTitle={`EID: ${eid} - ${delegationCheck(sid, shops) || 'undefined'}`} />

        {/* Currency Select */}
        <Group>
          <Select
            size="sm"
            label={lang?.GLOBAL_CURRENCY}
            data={
              equipment?.list.map((item: any) => ({
                value: item.cid.toString(),
                label: item.description
              })) || []
            }
            value={selectedCid?.toString() || ''}
            onChange={handleCurrencyChange}
          />

          {/* Excel */}
          <Button radius="md" className="XLS-button" onClick={getXLSReport}>
            <Icon.IconFileTypeXls stroke={2} />
            <span>XLS</span>
          </Button>
        </Group>

        <Table.ScrollContainer minWidth={500}>
          <Table striped highlightOnHover verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{lang?.GLOBAL_INDEX}</Table.Th>
                <Table.Th>{lang?.GLOBAL_VALUE}</Table.Th>
                <Table.Th>{lang?.GLOBAL_TYPE}</Table.Th>
                <Table.Th>{lang?.GLOBAL_WORKING_CAPITAL}</Table.Th>

                {login.permissions.hasFinancialConfigPermission() && (
                  <>
                    <Table.Th>{lang?.EQUIPMENT_EXISTENCE_RECYCLER}</Table.Th>
                    <Table.Th>{lang?.EQUIPMENT_WC_SURPLUS}</Table.Th>
                    <Table.Th>{lang?.EQUIPMENT_WC_LACKING}</Table.Th>
                    <Table.Th>{lang?.EQUIPMENT_EXISTENCE_SAFE}</Table.Th>
                    <Table.Th>{lang?.EQUIPMENT_TOTAL_EXISTENCE}</Table.Th>
                  </>
                )}
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {loading && !filteredEquipment ? (
                <Table.Tr>
                  <Table.Td colSpan={16}>
                    <Loader color="#016273" />
                  </Table.Td>
                </Table.Tr>
              ) : noResults ? (
                <Table.Tr>
                  <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                    {lang?.GLOBAL_NO_RESULTS}
                  </Table.Td>
                </Table.Tr>
              ) : (
                filteredEquipment?.map((equipmentCurrency, equipmentIndex) =>
                  equipmentCurrency.denominations?.map((denomination, denominationIndex) => {
                    const matchingCurrency = currencies.find(c => c.cid === equipmentCurrency.cid);
                    return (
                      <Table.Tr key={`${equipmentIndex}-${denominationIndex}`}>
                        <Table.Td>{denomination.index}</Table.Td>
                        <Table.Td>
                          {matchingCurrency ? formatCurrAmount(denomination.value || 0, matchingCurrency) : 'N/A'}
                        </Table.Td>
                        <Table.Td>{checkForCurrencyType(denomination.type || 0)}</Table.Td>
                        <Table.Td className="input">
                          {mode === MODE.EDIT ? (
                            <TextInput
                              value={updatedValues[denomination.index] ?? denomination.wc}
                              onChange={e =>
                                handleInputChange(
                                  equipmentCurrency.cid,
                                  denomination.index,
                                  Number(e.currentTarget.value)
                                )
                              }
                            />
                          ) : (
                            <Table.Td>{denomination.wc}</Table.Td>
                          )}
                        </Table.Td>

                        {login.permissions.hasFinancialConfigPermission() && (
                          <>
                            <Table.Td>{denomination.existRecycler}</Table.Td>
                            <Table.Td>{denomination.wcSurplus}</Table.Td>
                            <Table.Td>{denomination.wcLacking}</Table.Td>
                            <Table.Td>{denomination.existSafe}</Table.Td>
                            <Table.Td>{denomination.existTotal}</Table.Td>
                          </>
                        )}
                      </Table.Tr>
                    );
                  })
                )
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>

        {/* Buttons */}
        <Group className="edit-buttons" style={{ marginTop: '2vh' }}>
          {mode === MODE.EDIT && (
            <>
              <Button color="#e4ae00" onClick={handleFillWithExistRecycler}>
                {lang?.EQUIPMENT_FILL_EXISTENCE_WORKING_CAPITAL}
              </Button>
              <Button color="#e4ae00" onClick={handleClearWC}>
                {lang?.EQUIPMENT_CLEAR_WORKING_CAPITAL}
              </Button>
            </>
          )}
        </Group>

        {/* Buttons */}
        <Group style={{ marginTop: '2vh' }}>
          {mode === MODE.EDIT && (
            <>
              <Button className="save-button" onClick={() => handleSaveWC(selectedCid || 0)}>
                {lang?.GLOBAL_SAVE_BUTTON}
              </Button>
            </>
          )}
        </Group>

        <CustomModal
          opened={showSuccessDialog}
          onClose={() => setShowSuccessDialog(false)}
          message={successMessage}
          backgroundColor="#3BDF70"
          textColor="black"
        />

        <CustomModal
          opened={showErrorDialog}
          onClose={() => setShowErrorDialog(false)}
          message={errorMessage}
          backgroundColor="#E3353F"
          textColor="white"
        />
      </Container>
    </Background>
  );
};

export default EquipmentCurrency;
