import { Modal, Group, Button, Text } from '@mantine/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../client-redux/reducers';
import CustomModal from '../../../components/custom-modal';
import { Language } from '../../../languages/languageHandler';
import { deleteHelpdeskEntity } from '../../../../libraries/helpdesk';

interface DeleteHeldeskEntityProps {
  entity: number;
  onReload: () => void;
}

const DeleteHeldeskEntity = ({ entity, onReload }: DeleteHeldeskEntityProps) => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const controller = new AbortController();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    controller.abort();
  };

  const deleteEntity = async () => {
    setLoading(true);
    try {
      const response = await deleteHelpdeskEntity(Number(entity), controller);

      if (response.statusOper && response.statusOper.code !== 0) {
        const errorMessage = response.statusOper.message || 'An unknown error occurred.';
        setErrorMessage(
          `${lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR} (Code: ${response.statusOper.code}) ${errorMessage}`
        );
        setShowErrorDialog(true);
        setLoading(false);
      } else {
        setSuccessMessage(lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_SUCCESS || '');
        setShowSuccessDialog(true);
      }
    } catch (error) {
      console.error('Error during the delete API call:', error);
      setErrorMessage(`${lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR} (Status: ${errorMessage})`);
      setShowErrorDialog(true);
      setLoading(false);
    }
    setShowModal(false);
    onReload();
  };

  return (
    <>
      <Button onClick={openModal} color='#861724'>{lang?.GLOBAL_DELETE}</Button>
      <Modal opened={showModal} onClose={closeModal} centered withCloseButton={false}>
        <Text>{lang?.HELPDESK_ENTITY_REMOVE}</Text>
        <Group style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <Button onClick={deleteEntity} loading={loading}>
            {lang?.YES}
          </Button>
          <Button onClick={() => setShowModal(false)} style={{ marginRight: '0.5rem' }}>
            {lang?.NO}
          </Button>
        </Group>
      </Modal>

      <CustomModal
        opened={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        message={successMessage}
        backgroundColor="#3BDF70"
        textColor="black"
      />

      <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
        textColor="white"
      />
    </>
  );
};

export default DeleteHeldeskEntity;
