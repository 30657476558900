import moment from 'moment';
import { useEffect, useState } from 'react';
import { Loader, Container, Group, Table } from '@mantine/core';
import Header from '../../../components/util/header';
import { useSelector } from 'react-redux';
import { IAPMTransactionResponse } from '../../../../libraries/apm';
import { AppState } from '../../../client-redux/reducers';
import { Language } from '../../../languages/languageHandler';
import PaginationComponent from '../../../components/pagination';
import { LoginState } from '../../../client-redux/login/reducer';
import APMReceipt from '../../../components/apm-receipt';
import FilterBar from '../../../components/filterbar/index';
import { getShopName } from '../../../utils/script';
import { fetchAPMTransactionList } from '../../../../libraries/apm';
import { fetchAPMTransactionsXLS } from '../../../../libraries/transactions';
import Background from '../../../components/util/background';
import './styles.scss';

const APMTransactions = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const user = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const [loading, setLoading] = useState(false);
  const [beginDate, setBeginDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DDT00:00:00.000'));
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000'));
  const [entid, setEntid] = useState<number>(user.user?.entid);
  const [sid, setSid] = useState<number>(0);
  const [posid, setPosid] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [message, setMessage] = useState<IAPMTransactionResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();

  const [, setErrorMessage] = useState<string>('');
  const [, setShowErrorDialog] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (searchTrigger) {
      setOffset(0);
      const delay = setTimeout(() => {
        getAPMTransactionList();
      }, 300);
      return () => clearTimeout(delay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const onSearch = () => {
    setOffset(0);
    setCurrentPage(1);
    setSearchTrigger(true);
  };

  const getAPMTransactionList = () => {
    if (loading) {
      setNoResults(false);
      controller.abort();
      return;
    }

    setLoading(true);
    fetchAPMTransactionList(beginDate, endDate, entid, sid, posid, offset, limit, totalRows, controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
          setLoading(false);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);

          if (response.statusOper?.message) {
            setErrorMessage(response.statusOper.message);
          } else {
            setErrorMessage('An error occurred, but no specific error message is available.');
          }

          setShowErrorDialog(true);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
          // Handle other errors
        }
      })
      .finally(() => setLoading(false));
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updatePosid = (newPosid: number, callback?: () => void) => {
    setPosid(newPosid);
    if (callback) {
      callback();
    }
  };

  const updateTablePosList = (newPosid: number, callback?: () => void) => {
    updatePosid(newPosid);
    if (callback) {
      callback();
    }
  };

  const updateSid = (newSid: number, callback?: () => void) => {
    setSid(newSid);
    if (callback) {
      callback();
    }
  };

  const updateTableSid = (newSid: number, callback?: () => void) => {
    updateSid(newSid);
    if (callback) {
      callback();
    }
  };

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
    setSearchTrigger(true);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(1);
    setOffset(0);
    setSearchTrigger(true);
  };

  useEffect(() => {
    if (initialLoadComplete) {
      getAPMTransactionList();
    } else {
      setInitialLoadComplete(true);
      getAPMTransactionList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, offset, limit]);

  useEffect(() => {
    getAPMTransactionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entid, sid, posid, beginDate, endDate]);

  const getXLSReport = () => {
    return fetchAPMTransactionsXLS(beginDate, endDate, entid, sid, offset, limit, controller);
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'APMtransactions' }}>
        <Header pageTitle={lang?.APM_TRANSACTIONS} />

        <FilterBar
          onSearch={onSearch}
          tableLoaded={getAPMTransactionList}
          filterByEntid={updateTableEntid}
          filterBySid={updateTableSid}
          filterByPosId={updateTablePosList}
          updateBeginDate={setBeginDate}
          updateEndDate={setEndDate}
          XLSReport={getXLSReport}
        />

        <Table.ScrollContainer minWidth={500}>
          <Table striped verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>POS</Table.Th>
                <Table.Th>{lang?.USER_DELEGATION}</Table.Th>
                <Table.Th>{lang?.GLOBAL_PERIOD}</Table.Th>
                <Table.Th>{lang?.TRANSACTION_NUMBER}</Table.Th>
                <Table.Th>{lang?.GLOBAL_DATE_TIME}</Table.Th>
                <Table.Th>{lang?.GLOBAL_AMOUNT}</Table.Th>
                <Table.Th>{''}</Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {loading && (!message || searchTrigger) ? (
                <Table.Tr>
                  <Table.Td colSpan={12}>
                    <Loader color="#016273" />
                  </Table.Td>
                </Table.Tr>
              ) : (
                <>
                  {noResults ? (
                    <Table.Tr>
                      <Table.Td colSpan={12} style={{ textAlign: 'center' }}>
                        {lang?.GLOBAL_NO_RESULTS}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    message?.transactions?.map((transaction, index) => {
                      return (
                        <Table.Tr key={index}>
                          <Table.Td>{transaction.key.a001}</Table.Td>
                          <Table.Td>{getShopName(transaction.sid)}</Table.Td>
                          <Table.Td>{transaction.key.a031}</Table.Td>
                          <Table.Td>{transaction.key.a032}</Table.Td>
                          <Table.Td>{moment(transaction.dateTime).format('YYYY-MM-DD HH:mm:ss')}</Table.Td>
                          <Table.Td>{(transaction.amount / 100).toFixed(2) + ' €'}</Table.Td>

                          <Table.Td>
                            <APMReceipt
                              a001={transaction.key.a001}
                              movType={transaction.key.movType}
                              a031={transaction.key.a031}
                              a032={transaction.key.a032}
                              a029={transaction.key.a029}
                              dateTime={transaction.key.dateTime}
                            />
                          </Table.Td>
                        </Table.Tr>
                      );
                    })
                  )}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>

        <Group>
          <PaginationComponent
            total={message?.totalRows}
            statusOperCode={statusOperCode}
            itemsPerPage={limit}
            onPageChange={changePage}
            onResultsChange={resultsSelect}
            initialPage={currentPage}
          />
        </Group>
      </Container>
    </Background>
  );
};

export default APMTransactions;
