import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loader, Table, Container, Group } from '@mantine/core';
import { useContextMenu } from 'mantine-contextmenu';
import Header from '../../../components/util/header';
import Background from '../../../components/util/background';
import ShowEquipment from '../../../components/show-equipment';
import { useSelector } from 'react-redux';
import {
  fetchExistencesMSReport,
  fetchHelpdeskEquipmentList,
  IHelpdeskEquipmentResponse
} from '../../../../libraries/helpdesk';
import { AppState } from '../../../client-redux/reducers';
import { Language } from '../../../languages/languageHandler';
import moment from 'moment';
import PaginationComponent from '../../../components/pagination';
import HelpdeskFilterBar from '../../components/helpdesk-filterbar';
import CustomModal from '../../../components/custom-modal';
import './styles.scss';

const HelpdeskEquipment: React.FC = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const controller = new AbortController();
  const navigate = useNavigate();
  const { showContextMenu } = useContextMenu();

  const [searchParams, setSearchParams] = useSearchParams();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [entid, setEntid] = useState<number>(0);
  const [etid, setEtid] = useState<number>(0);
  const [sn, setSN] = useState<string>('');
  const [eid, setEid] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [, setCurrentPage] = useState<number>(0);
  const [equipments, setEquipments] = useState<IHelpdeskEquipmentResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();
  const [, setSelectedEid] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      getEquipmentList();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const onSearch = () => {
    setSearchParams(searchParams);
  };

  const getEquipmentList = () => {
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);

    fetchHelpdeskEquipmentList(offset, limit, totalRows, controller, entid, etid, sn, eid)
      .then(response => {
        if (response.statusOper.code === 0) {
          setEquipments(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
          setLoading(false);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);

          if (response.statusOper?.message) {
            setErrorMessage(response.statusOper.message);
          } else {
            setErrorMessage('An error occurred, but no specific error message is available.');
          }

          setShowErrorDialog(true);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
          // Handle other errors
        }
      })
      .finally(() => setLoading(false));
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateEtid = (newEtid: number, callback?: () => void) => {
    setEtid(newEtid);
    if (callback) {
      callback();
    }
  };

  const updateTableEtid = (newEtid: number, callback?: () => void) => {
    updateEtid(newEtid);
    if (callback) {
      callback();
    }
  };

  const updateSN = (newSN: string, callback?: () => void) => {
    setSN(newSN);
    if (callback) {
      callback();
    }
  };

  const updateTableSN = (newSN: string, callback?: () => void) => {
    updateSN(newSN);
    if (callback) {
      callback();
    }
  };

  const updateEid = (newEid: number, callback?: () => void) => {
    setEid(newEid);
    if (callback) {
      callback();
    }
  };

  const updateTableEid = (newEid: number, callback?: () => void) => {
    updateEid(newEid);
    if (callback) {
      callback();
    }
  };

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  const detectContractState = (agreeState: number | undefined) => {
    switch (agreeState) {
      case 0:
        return lang?.AGREE_STATE_0;
      case 1:
        return lang?.AGREE_STATE_1;
      case 2:
        return lang?.AGREE_STATE_2;
      case 3:
        return lang?.AGREE_STATE_3;
      case 4:
        return lang?.AGREE_STATE_4;
      default:
        return '';
    }
  };

  useEffect(() => {
    getEquipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, eid]);

  const getXLSReport = async () => {
    try {
      const response = await fetchExistencesMSReport(controller, entid, etid, sn, eid);
      if (response?.data.statusOper.code === 0) {
        setStatusOperCode(response?.data.statusOper.code);
      } else {
        setNoResults(true);
        setErrorMessage(
          response?.data.statusOper.message || 'An error occurred, but no specific error message is available.'
        );
        setShowErrorDialog(true);
      }
    } catch (error) {
      setShowErrorDialog(true);
    }
  };

  const showEquipment = (selectedEid: number) => {
    setActiveRow(selectedEid);
    setOpenDetails(true);
  };

  const handleRowRightClick = (event: React.MouseEvent, eid: number, entid: number) => {
    setSelectedEid(eid);
    event.preventDefault();
    showContextMenu([
      {
        key: 'event',
        title: lang?.GLOBAL_EVENT,
        disabled: true,
        onClick: () => {}
      },
      {
        key: 'register',
        title: lang?.EQUIPMENT_REGISTER,
        onClick: () => navigate(`/helpdesk/equipment/registation/${eid}`)
      },
      {
        key: 'entity',
        title: lang?.GLOBAL_ENTITY,
        onClick: () => navigate(`/helpdesk/entities/read/${entid}`)
      },
      {
        key: 'equipment',
        title: lang?.GLOBAL_EQUIPMENT,
        disabled: true,
        onClick: () => {}
      },
      {
        key: 'statistics',
        title: lang?.GLOBAL_STATISTICS,
        disabled: true,
        onClick: () => {}
      },
      {
        key: 'contract',
        title: lang?.EQUIPMENT_CONTRACT,
        disabled: true,
        onClick: () => {}
      },
      {
        key: 'ticket',
        title: lang?.EQUIPMENT_CREATE_TICKET,
        disabled: true,
        onClick: () => {}
      }
    ])(event);
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'helpdesk-equipment' }}>
        {openDetails && activeRow && lang && (
          <ShowEquipment eid={activeRow} openDetails={openDetails} hideBtn={() => setOpenDetails(false)} lang={lang} />
        )}

        <Header pageTitle={lang?.GLOBAL_EQUIPMENT} />

        <HelpdeskFilterBar
          onSearch={onSearch}
          tableLoaded={getEquipmentList}
          filterByEntid={updateTableEntid}
          filterByEtid={updateTableEtid}
          filterBySN={updateTableSN}
          filterByEid={updateTableEid}
          XLSReport={getXLSReport}
        />

        <Table.ScrollContainer minWidth={500}>
          <Table striped highlightOnHover verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{lang?.EQUIPMENT_ID}</Table.Th>
                <Table.Th>{lang?.GLOBAL_TYPE}</Table.Th>
                <Table.Th>SN</Table.Th>
                <Table.Th>{lang?.GLOBAL_MAINTENANCE}</Table.Th>
                <Table.Th>{lang?.GLOBAL_AGENT}</Table.Th>
                <Table.Th>{lang?.GLOBAL_ENTITY}</Table.Th>
                <Table.Th>{lang?.USER_DELEGATION}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_COM_STATE}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_SOFTWARE_VERSION_MIN}</Table.Th>
                <Table.Th>{lang?.GLOBAL_CONTRACT}</Table.Th>
                <Table.Th>{lang?.GLOBAL_LICENSE}</Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {loading && (!equipments || searchTrigger) ? (
                <Table.Tr>
                  <Table.Td colSpan={16}>
                    <Loader color="#016273" />
                  </Table.Td>
                </Table.Tr>
              ) : (
                <>
                  {noResults ? (
                    <Table.Tr>
                      <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                        {lang?.GLOBAL_NO_RESULTS}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    equipments?.list?.map((equipment, index) => (
                      <Table.Tr
                        key={index}
                        onClick={() => {
                          showEquipment(equipment.eid);
                        }}
                        onContextMenu={event => handleRowRightClick(event, equipment.eid, equipment.entity?.entid || 0)}
                        style={{ cursor: 'context-menu' }}
                      >
                        <Table.Td>{equipment.eid}</Table.Td>
                        <Table.Td>
                          {''}
                          {equipment.type && equipment.productCode
                            ? `${equipment.type} / ${equipment.productCode}`
                            : equipment.type || ''}
                        </Table.Td>
                        <Table.Td>{equipment.sn}</Table.Td>
                        <Table.Td>
                          {equipment.maintEntity
                            ? `${equipment.maintEntity.name ?? ''} #${equipment.maintEntity.entid ?? ''}`
                            : ''}
                        </Table.Td>
                        <Table.Td>
                          {equipment.maintEntity2
                            ? `${equipment.maintEntity2.name ?? ''} #${equipment.maintEntity2.entid ?? ''}`
                            : ''}
                        </Table.Td>
                        <Table.Td>
                          {equipment.entity ? `${equipment.entity.name ?? ''} #${equipment.entity.entid ?? ''}` : ''}
                        </Table.Td>
                        <Table.Td>
                          {''}
                          {equipment.sid && equipment.sidDescription
                            ? `${equipment.sid} - ${equipment.sidDescription}`
                            : equipment.sidDescription || ''}
                        </Table.Td>{' '}
                        <Table.Td
                          className={
                            equipment.status === 'OFFLINE' || equipment.status === 'DEFINED'
                              ? 'offline-text'
                              : 'online-text'
                          }
                        >
                          {equipment.status}
                        </Table.Td>
                        <Table.Td>{equipment.softwareVersion}</Table.Td>
                        <Table.Td className={equipment.agreeState === 1 ? 'valid-agree-state' : 'invalid-agree-state'}>
                          {detectContractState(equipment.agreeState)}
                        </Table.Td>
                        <Table.Td
                          className={
                            moment(equipment?.licenceExp).isBefore(moment()) ? 'expired-license' : 'valid-license'
                          }
                        >
                          {equipment?.licenceExp ? moment(equipment?.licenceExp).format('YYYY/MM/DD') : ''}
                        </Table.Td>
                      </Table.Tr>
                    ))
                  )}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>

        <Group>
          <PaginationComponent
            total={equipments?.totalRows}
            statusOperCode={statusOperCode}
            itemsPerPage={limit}
            onPageChange={changePage}
            onResultsChange={resultsSelect}
          />
        </Group>

        <CustomModal
          opened={showErrorDialog}
          onClose={() => setShowErrorDialog(false)}
          message={errorMessage}
          backgroundColor="#E3353F"
          textColor="white"
        />
      </Container>
    </Background>
  );
};

export default HelpdeskEquipment;
