import React from 'react';
import { Container, Group, Image, useMantineColorScheme } from '@mantine/core';
import logoDark from '../../../assets/img/Versão_horizontal_branco_sem_assinatura.svg';
import logoWhite from '../../../assets/img/Versão_horizontal_cores_sem_assinatura.svg';

export interface CenterProps {
  children: React.ReactNode;
  showSmallLogo?: boolean;
}

export function Background({ children, showSmallLogo = true }: CenterProps) {
  const { colorScheme } = useMantineColorScheme();

  return (
    <Container fluid className="background">
      {children}
      {showSmallLogo && (
        <Group className="logo">
          <Image src={colorScheme === 'dark' ? logoDark : logoWhite} />
        </Group>
      )}
    </Container>
  );
}

export default Background;
