import { Loader, Container, Table, Group } from '@mantine/core';
import { useQuery, UseQueryResult, useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { getLanguageName } from '../../languages/languageUtils';
import {
  fetchUsers,
  IUserResponse,
  fetchPermissions,
  PermissionOptionResponse,
  IUser,
  deleteUser
} from '../../../libraries/users/index';
import ShowUser from './show-user';
import Header from '../../components/util/header';
import Background from '../../components/util/background';
import { LoginState } from '../../client-redux/login/reducer';
import PaginationComponent from '../../components/pagination';
import { useState, useEffect } from 'react';
import { loadLanguageSettings } from '../../client-redux/languages';
import UsersFilterBar from '../../components/users-filterbar';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

function Users() {
  const dispatch = useDispatch();
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const permissionOptionData: UseQueryResult<PermissionOptionResponse> = useQuery([], () => fetchPermissions());
  const [loading, setLoading] = useState<boolean>(false);
  const [entid, setEntid] = useState<number>(loginInfo.user.entid);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [name, setName] = useState<string>();
  const [searchId, setSearchId] = useState<number>();
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [message, setMessage] = useState<IUserResponse>();
  const [noResults, setNoResults] = useState<boolean>(false);
  const [, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteUser);

  useEffect(() => {
    getUsersList();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit]);

  useEffect(() => {
    dispatch(loadLanguageSettings());
  }, [dispatch]);

  const onSearch = () => {
    setCurrentPage(1);
    setOffset(0);
    setSearchTrigger(true);
  };

  const getUsersList = () => {
    if (!loginInfo.token) {
      return;
    }
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    fetchUsers(controller, entid, limit, offset, totalRows, name, searchId)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
          setLoading(false);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
        }
      })
      .finally(() => setLoading(false));
  };

  const handleToggle =
    (index: number): (() => void) =>
    () => {
      setSelectedUser(message?.users[index]);
      setOpenDetails(!openDetails);
    };

  const isActive = (uid: number): boolean => {
    if (selectedUser) return false;

    return uid === selectedUser;
  };

  const closeDetailsModal = () => {
    setOpenDetails(false);
  };

  const userUpdated = () => {
    closeDetailsModal();
    getUsersList();
  };

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  const deleteActiveUser = async (selectedUser: number | null) => {
    if (selectedUser !== null && window.confirm(lang?.USER_DELETE_CONFIRM)) {
      await mutation.mutateAsync(selectedUser);
      queryClient.invalidateQueries('users');
      setOpenDetails(false);
      getUsersList();
    }
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateName = (newName: string, callback?: () => void) => {
    setName(newName);
    if (callback) {
      callback();
    }
  };

  const updateTableName = (newName: string, callback?: () => void) => {
    updateName(newName);
    if (callback) {
      callback();
    }
  };

  const updateUID = (newUID: number, callback?: () => void) => {
    setSearchId(newUID);
    if (callback) {
      callback();
    }
  };

  const updateTableUID = (newUID: number, callback?: () => void) => {
    updateUID(newUID);
    if (callback) {
      callback();
    }
  };

  return (
    <Background>
      <Container fluid classNames={{ root: 'users' }}>
        {selectedUser && lang && permissionOptionData.data && selectedUser && (
          <ShowUser
            user={selectedUser}
            lang={lang}
            openDetails={openDetails}
            closed={closeDetailsModal}
            updated={userUpdated}
            deleteBtn={() => {}}
            permissionOptions={permissionOptionData.data.list}
          />
        )}

        <Header pageTitle={lang?.GLOBAL_USERS} />

        <UsersFilterBar
          onSearch={onSearch}
          tableLoaded={getUsersList}
          filterByEntid={updateTableEntid}
          filterByName={updateTableName}
          filterByIDUser={updateTableUID}
        />

        <Table striped highlightOnHover verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>ID</Table.Th>
              <Table.Th>{lang?.GLOBAL_NAME}</Table.Th>
              <Table.Th>{lang?.USER_EMAIL}</Table.Th>
              <Table.Th>{lang?.USER_CARD}</Table.Th>
              <Table.Th>{lang?.USER_REGION}</Table.Th>
              <Table.Th>{lang?.USER_LANG}</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loading && !message && (
              <Table.Tr>
                <Table.Td colSpan={16}>
                  <Loader color="#016273" />
                </Table.Td>
              </Table.Tr>
            )}
            {noResults && !loading ? (
              <Table.Tr>
                <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                  {lang?.GLOBAL_NO_RESULTS}
                </Table.Td>
              </Table.Tr>
            ) : (
              message?.users?.map((user: IUser, index: number) => (
                <Table.Tr
                  key={user.uid}
                  className={`table-row ${isActive(user.uid) ? 'selectedUser' : ''}`}
                  onClick={handleToggle(index)}
                >
                  <Table.Td>{user.uid}</Table.Td>
                  <Table.Td>{user.name}</Table.Td>
                  <Table.Td>{user.email}</Table.Td>
                  <Table.Td>{user.card}</Table.Td>
                  <Table.Td>{user.region}</Table.Td>
                  <Table.Td>{getLanguageName(user.lang)}</Table.Td>
                  <Table.Td
                    onClick={() => {
                      deleteActiveUser(user.uid);
                    }}
                  >
                    <Icon.IconTrash stroke={2} />
                  </Table.Td>
                </Table.Tr>
              ))
            )}
          </Table.Tbody>
        </Table>

        <Group>
          <PaginationComponent
            total={message?.totalRows}
            statusOperCode={statusOperCode}
            itemsPerPage={limit}
            onPageChange={changePage}
            onResultsChange={resultsSelect}
            initialPage={currentPage}
          />
        </Group>
      </Container>
    </Background>
  );
}

export default Users;
