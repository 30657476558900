import axios from 'axios';
import { ApiResponse, zps_ms_ea_war } from '../zps';
import { handleDownload } from '../../app/utils/script';
import { IEntityLabel } from '../equipment';
import { IUser } from '../users';

export type IHelpdeskEquipment = {
  agreeId?: number;
  agreeState?: number;
  eid: number;
  entity?: IEntityLabel;
  gid: number;
  initAccepted?: boolean;
  licenceExp?: string;
  maintEntity?: IEntityLabel;
  maintEntity2?: IEntityLabel;
  productCode?: string;
  posid?: number;
  sid?: number;
  sidDescription?: string;
  sn: string;
  softwareVersion?: string;
  status?: string;
  type: number;
  tb520: any;
};

export type IPCTickets = {
  action?: number;
  cause?: number;
  fault?: number;
  problem?: number;
  seqn?: number;
};

export type IHelpdeskEntity = {
  description: string;
  entid: number;
  gid: number;
  lang: number;
  name: string;
  nif: number;
  ownerid: number;
  printType: number;
  region: string;
  reportType: number;
  type: number;
  usePos: number;
  useShifts: number;
};

export interface HeldeskEntityRequests {
  activity: number;
  address: string;
  billingEntity: number;
  billingVolume: number;
  contact: string;
  description: string;
  email: string;
  entid: number;
  gid?: number;
  lang: number;
  logo: string;
  mobile: string;
  name: string;
  nif: number;
  ownerid: number;
  postalCode: string;
  printType: number;
  region: string;
  reportType: number;
  sibsMerchantCode: string;
  type: number;
  usePos: number;
  useShifts: number;
}

export interface HeldeskTicketsRequests {
  agent?: {
    entid: number;
    name: string;
  };
  commChannel?: number;
  contact?: string;
  eid?: number;
  entid?: number;
  entityName?: string;
  intType?: number;
  issueType?: number;
  openDT?: string;
  pc?: IPCTickets[];
  sn?: string;
  status?: number;
  statusDT?: string;
  ticketID?: number;
  time?: number;
  urgency?: number;
}

export interface HeldeskClassificationItems {
  id: number;
  name: string;
}

export interface HeldeskClassificationRequest {
  actions: HeldeskClassificationItems[];
  causes: HeldeskClassificationItems[];
  faults: HeldeskClassificationItems[];
  problems: HeldeskClassificationItems[];
}

export type ticketInfoLines = {
  classification?: number;
  comments?: string;
  description?: string;
  impact?: number;
  line?: number;
  origin?: string;
  soluClassif?: boolean;
  status?: number;
  statusDT?: string;
  ticketID?: number;
  time?: number;
  urgency?: number;
  user: IUser;
};

export type ticketInfoPc = {
  action: number;
  cause: number;
  fault: number;
  problem: number;
};

export interface HeldeskTicketInfoRequests {
  agent?: {
    entid: number;
    name: string;
  };
  commChannel?: number;
  contact?: string;
  eid?: number;
  entid?: number;
  entityName?: string;
  intType?: number;
  issueType?: number;
  lines?: ticketInfoLines[];
  openDT?: string;
  pc?: ticketInfoPc[];
  product: string;
  sn?: string;
  status?: number;
  statusDT?: string;
  system?: string;
  ticketID: number;
  time?: number;
  urgency?: number;
}

export interface HeldeskTicketInfoUpdateRequests {
  commChannel?: number;
  contact?: string;
  eid: number;
  entid: number;
  intType?: number;
  issueType?: number;
  openDT?: string;
  pc?: ticketInfoPc[];
  system?: null;
  ticketID?: number;
}

export interface HeldeskTickeLineRequests {
  classification: string;
  comments: string;
  description: string;
  impact: string;
  line: string;
  origin: string;
  soluClassif: string;
  status: string;
  statusDT: string;
  statusName: string;
  ticketID: number;
  time: string;
  urgency: string;
  user: IUser;
}

export interface IHelpdeskEquipmentResponse extends ApiResponse {
  list: IHelpdeskEquipment[];
  totalRows: number;
  type: string;
}

export interface IHelpdeskEntityResponse extends ApiResponse {
  entities: IHelpdeskEntity[];
  totalRows: number;
  type: string;
}

export interface IHelpdeskHeldeskEntityRequestsResponse extends ApiResponse {
  entities: HeldeskEntityRequests[];
  totalRows: number;
  type: string;
}

export interface IHelpdeskTicketsResponse extends ApiResponse {
  tickets: HeldeskTicketsRequests[];
  totalRows: number;
  type: string;
}

export interface IHelpdeskTicketInfoResponse extends ApiResponse {
  tickets: HeldeskTicketInfoRequests[];
  totalRows: number;
  type: string;
}

export interface EquipmentRegistrationRequest {
  eid: number;
  entid: number;
  maintid?: number;
  maintid2?: number;
  dateTimeExp: string;
  shop: { sid: number; name: string };
  posid?: number;
}

export const fetchEntitiesActivity = async () => {
  const response = await axios.get(`${zps_ms_ea_war}/entity/activity`);
  return response.data;
};

export const fetchHelpdeskEquipmentList = async (
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController,
  entid: number,
  etid?: number,
  sn?: string,
  eid?: number
) => {
  const { data } = await axios.get(
    `${zps_ms_ea_war}/equipment/?` +
      `${entid ? '&entid=' + entid : ''}` +
      `${etid ? '&etid=' + etid : ''}` +
      `${sn ? '&sn=' + sn : ''}` +
      `${eid ? '&eid=' + eid : ''}` +
      `&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );
  return data;
};

export const fetchHelpdeskEntitiesList = async (
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController,
  type?: string,
  entid?: number,
  name?: string,
  nif?: number
) => {
  const { data } = await axios.get(
    `${zps_ms_ea_war}/entity/?` +
      `${type ? '&type=' + type : ''}` +
      `${entid ? '&entid=' + entid : ''}` +
      `${name ? '&name=' + name : ''}` +
      `${nif ? '&nif=' + nif : ''}` +
      `&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );
  return data;
};

export const fetchHelpdeskEquipInfo = async (eid: number, controller: AbortController) => {
  const { data } = await axios.get(`${zps_ms_ea_war}/equipment/?&eid=${eid}`, {
    signal: controller.signal
  });
  return data;
};

export const updateHelpdeskEquipment = async (request: EquipmentRegistrationRequest, controller: AbortController) => {
  const { data } = await axios.post(`${zps_ms_ea_war}/equipment/registration`, request, {
    signal: controller.signal
  });
  return data;
};

export const fetchExistencesMSReport = async (
  controller: AbortController,
  entid: number,
  etid?: number,
  sn?: string,
  eid?: number
) => {
  try {
    const response = await axios.get(
      `${zps_ms_ea_war}/equipment/report?format=xls` +
        `${entid ? '&entid=' + entid : ''}` +
        `${etid ? '&etid=' + etid : ''}` +
        `${sn ? '&sn=' + sn : ''}` +
        `${eid ? '&eid=' + eid : ''}`,
      {
        signal: controller.signal
      }
    );
    handleDownload(response.data.report, response.data.name, response.data.mime);
    return response;
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};

export const fetchHelpdeskEntityInfo = async (entid: number, controller: AbortController) => {
  const { data } = await axios.get(`${zps_ms_ea_war}/entity/?&entid=${entid}`, {
    signal: controller.signal
  });
  return data;
};

export const fetchHelpdeskTickets = async (
  eDT: string,
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController,
  ticketID?: number,
  eid?: number,
  entid?: number,
  closed?: string,
  bDT?: string | null
) => {
  const { data } = await axios.get(
    `${zps_ms_ea_war}/ticket/?` +
      `${ticketID ? '&ticketID=' + ticketID : ''}` +
      `${eid ? '&eid=' + eid : ''}` +
      `${entid ? '&entid=' + entid : ''}` +
      `${bDT ? '&bDT=' + bDT : ''}` +
      `${closed ? '&closed=' + closed : ''}` +
      `&eDT=${eDT}&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );
  return data;
};

export const fetchHelpdeskTicketInfo = async (ticketID: number, controller: AbortController) => {
  const { data } = await axios.get(`${zps_ms_ea_war}/ticket/${ticketID}`, { signal: controller.signal });
  return data;
};

export const UpdateHelpdeskTicketInfo = async (
  request: HeldeskTicketInfoUpdateRequests,
  controller: AbortController
) => {
  const { data } = await axios.put(`${zps_ms_ea_war}/ticket/`, request, { signal: controller.signal });
  return data;
};

export const createHelpdeskEntity = async (request: HeldeskEntityRequests, controller: AbortController) => {
  const { data } = await axios.post(`${zps_ms_ea_war}/entity/`, request, {
    signal: controller.signal
  });
  return data;
};

export const deleteHelpdeskEntity = async (entid: number, controller: AbortController) => {
  const { data } = await axios.delete(`${zps_ms_ea_war}/entity/?entid=${entid}`, {
    signal: controller.signal
  });
  return data;
};

export const updateHelpdeskEntity = async (request: HeldeskEntityRequests, controller: AbortController) => {
  try {
    const { data } = await axios.put(`${zps_ms_ea_war}/entity/`, request, {
      signal: controller.signal
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchTicketClassifications = async (controller: AbortController) => {
  const { data } = await axios.get(`${zps_ms_ea_war}/ticket/classification`, { signal: controller.signal });
  return data;
};

export const updateTicket = async (request: HeldeskTicketInfoUpdateRequests, controller: AbortController) => {
  try {
    const { data } = await axios.put(`${zps_ms_ea_war}/ticket/`, request, {
      signal: controller.signal
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateTicketLine = async (request: HeldeskTickeLineRequests, controller: AbortController) => {
  try {
    const { data } = await axios.put(`${zps_ms_ea_war}/ticket/line`, request, {
      signal: controller.signal
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const addTicketLine = async (request: HeldeskTickeLineRequests, controller: AbortController) => {
  try {
    const { data } = await axios.post(`${zps_ms_ea_war}/ticket/line`, request, {
      signal: controller.signal
    });
    return data;
  } catch (error) {
    throw error;
  }
};
