import moment from 'moment';
import { useState, useEffect } from 'react';
import { Group, Loader, Container, Table } from '@mantine/core';
import Header from '../../components/util/header';
import { useSelector } from 'react-redux';
import {
  fetchBankTransactionList,
  fetchBankTransactionReport,
  IBankTransaction,
  IBankTransactionResponse
} from '../../../libraries/bank-transactions';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { formatCurrAmount, formatTransactionStatus, getShopName, handleShortTransactionType } from '../../utils/script';
import FilterBar from '../../components/filterbar/index';
import PaginationComponent from '../../components/pagination';
import { fetchLog } from '../../../libraries/transactions';
import ShowBankTransaction from './show-bank-transaction-details';
import { ICurrency } from '../../../libraries/currencies';
import { LoginState } from '../../client-redux/login/reducer';
import Background from '../../components/util/background';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

const BankTransactions = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const currencies = useSelector<AppState, ICurrency[]>(state => state.currencies.currencies);
  const user = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const [loading, setLoading] = useState<boolean>(false);
  const [beginDate, setBeginDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DDT00:00:00.000'));
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000'));
  const [entid, setEntid] = useState<number>(user.user ? user.user.entid : 0);
  const [sid, setSid] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  /* const itemsPerPage = window.innerHeight < 600 ? 5 : Math.floor(window.innerHeight / 55 - 2); */
  const [limit, setLimit] = useState<number>(10);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRows] = useState<boolean>(true);
  const [message, setMessage] = useState<IBankTransactionResponse>();
  const [noResults, setNoResults] = useState<boolean>(false);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<IBankTransaction>();
  const [selectedTransactionCurrency, setSelectedTransactionCurrency] = useState<ICurrency>();
  const [statusOperCode, setStatusOperCode] = useState<number>();

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      getBankTransactionList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const onSearch = () => {
    setCurrentPage(1);
    setOffset(0);
    setSearchTrigger(true);
  };

  const getBankTransactionList = () => {
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);
    fetchBankTransactionList(`${beginDate}`, `${endDate}`, entid, sid, offset, limit, totalRows, controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          // console.log('API request aborted');
        } else {
          console.error('API request error:', error);
        }
      })
      .finally(() => setLoading(false));
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateSid = (newSid: number, callback?: () => void) => {
    setSid(newSid);
    if (callback) {
      callback();
    }
  };

  const updateTableSid = (newSid: number, callback?: () => void) => {
    updateSid(newSid);
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    getBankTransactionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, offset, limit, entid, sid, beginDate, endDate]);

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
    setSearchTrigger(true);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(1);
    setOffset(0);
    setSearchTrigger(true);
  };

  const getBankTransactionsReport = (format: string, eid: number, period: number, code: number) => {
    if (!format || !eid || !period) return;
    return fetchBankTransactionReport(format, eid, period, code, controller);
  };

  // ----------------- Modal information with logs -------------------//
  const showTransactionDetails = (n: number) => {
    if (message) {
      const transactionToShow = message.transactions[n];
      setSelectedTransaction(transactionToShow);
      setSelectedTransactionCurrency(currencies.find(currency => currency.cid === transactionToShow.cid));
      setOpenDetails(true);
    }
  };

  const getLog = () => {
    if (!selectedTransaction) return;
    return fetchLog(selectedTransaction.eid, selectedTransaction.period, selectedTransaction.code, controller);
  };
  //-------------------------------------------------------------//

  return (
    <Background>
      <Container fluid classNames={{ root: 'bankTransactions' }}>
        {openDetails || selectedTransaction || selectedTransactionCurrency ? (
          <ShowBankTransaction
            transaction={selectedTransaction}
            openDetails={openDetails}
            hideBtn={() => setOpenDetails(false)}
            lang={lang}
            currency={selectedTransactionCurrency}
            getLog={async () => getLog()}
          />
        ) : null}

        <Header pageTitle={lang?.BANK_TRANSACTIONS} />

        <FilterBar
          onSearch={onSearch}
          tableLoaded={getBankTransactionList}
          filterByEntid={updateTableEntid}
          filterBySid={updateTableSid}
          updateBeginDate={setBeginDate}
          updateEndDate={setEndDate}
        />

        <Table.ScrollContainer minWidth={500}>
          <Table striped highlightOnHover verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{lang?.USER_DELEGATION}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_ID}</Table.Th>
                <Table.Th>{lang?.PROCESSED_GLASS_PERIOD}</Table.Th>
                <Table.Th>{lang?.GLOBAL_CODE}</Table.Th>
                <Table.Th>{lang?.TRANSACTION_DEPOSITOR}</Table.Th>
                <Table.Th>{lang?.GLOBAL_ACCOUNT}</Table.Th>
                <Table.Th>{lang?.GLOBAL_DATE_TIME}</Table.Th>
                <Table.Th>{lang?.GLOBAL_TYPE}</Table.Th>
                <Table.Th>{lang?.GLOBAL_AMOUNT}</Table.Th>
                <Table.Th>{lang?.EQUIPMENT_COM_STATE}</Table.Th>
                <Table.Th>{lang?.TRANSACTION_EXTERNAL_ID}</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {loading && (!message || searchTrigger) ? (
                <Table.Tr>
                  <Table.Td colSpan={16}>
                    <Loader color="#016273" />
                  </Table.Td>
                </Table.Tr>
              ) : (
                <>
                  {noResults ? (
                    <Table.Tr>
                      <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                        {lang?.GLOBAL_NO_RESULTS}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    message?.transactions.map((transaction, index) => {
                      return (
                        <Table.Tr key={index} onClick={() => showTransactionDetails(index)}>
                          <Table.Td>{getShopName(transaction.sid)}</Table.Td>
                          <Table.Td>{transaction.eid}</Table.Td>
                          <Table.Td>{transaction.period}</Table.Td>
                          <Table.Td>{transaction.code}</Table.Td>
                          <Table.Td>{transaction.depositor}</Table.Td>
                          <Table.Td>{transaction.account}</Table.Td>
                          <Table.Td>{moment(transaction.dateTime).format('YYYY-MM-DD HH:mm')}</Table.Td>
                          <Table.Td>{handleShortTransactionType(lang, transaction.type)}</Table.Td>
                          <Table.Td>{formatCurrAmount(transaction.value || 0, currencies[0])}</Table.Td>
                          <Table.Td>{formatTransactionStatus(lang, transaction.status)}</Table.Td>
                          <Table.Td>{transaction.hostTransactionID}</Table.Td>
                          <Table.Td
                            onClick={() =>
                              getBankTransactionsReport('pdf', transaction.eid, transaction.period, transaction.code)
                            }
                          >
                            <Icon.IconFileTypePdf stroke={2} />
                          </Table.Td>
                        </Table.Tr>
                      );
                    })
                  )}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>

        <Group>
          <PaginationComponent
            total={message?.totalRows}
            statusOperCode={statusOperCode}
            itemsPerPage={limit}
            onPageChange={changePage}
            onResultsChange={resultsSelect}
            initialPage={currentPage}
          />
        </Group>
      </Container>
    </Background>
  );
};

export default BankTransactions;
