import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Container, Group, GridCol, Grid, Button } from '@mantine/core';
import { IBankTransaction } from '../../../libraries/bank-transactions';
import { Language } from '../../languages/languageHandler';
import { formatCurrAmount, formatTransactionStatus, handleTransactionType } from '../../utils/script';
import ModalComponent from '../../components/modal';
import CurrencyTable from '../../components/util/currency-table';
import { ICurrency } from '../../../libraries/currencies';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

interface ShowTransactionProps {
  transaction?: IBankTransaction;
  openDetails?: boolean;
  hideBtn: () => void;
  lang: Language;
  currency?: ICurrency;
  getLog: () => void;
}

const ShowBankTransaction = ({ transaction, openDetails, hideBtn, lang, currency, getLog }: ShowTransactionProps) => {
  const currencies = useSelector<AppState, ICurrency[]>(state => state.currencies.currencies);
  const matchingCurrency = currencies?.find(c => c.cid === transaction?.cid);

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={lang.TRANSACTION_DETAILS}
        size={'xl'}
        content={
          <>
            <Container classNames={{ root: 'bank-transaction-details' }}>
              <Group>
                <Grid justify="flex-start" align="flex-start" gutter="sm">
                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.USER_DELEGATION}</strong>
                    <GridCol>{transaction?.entid}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.EQUIPMENT_ID}</strong>
                    <GridCol>{transaction?.eid}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_PERIOD}</strong>
                    <GridCol>{transaction?.period}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLASS_CODE}</strong>
                    <GridCol>{transaction?.code}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.TRANSACTION_DEPOSITOR}</strong>
                    <GridCol>{transaction?.depositor}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_ACCOUNT}</strong>
                    <GridCol>{transaction?.account}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_DATE_TIME}</strong>
                    <GridCol>{moment(transaction?.dateTime).format('DD-MM-YYYY HH:mm:ss')}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_AMOUNT}</strong>
                    <GridCol>
                      {matchingCurrency ? formatCurrAmount(transaction?.value || 0, matchingCurrency) : '-'}
                    </GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.TRANSACTION_RECEIVED}</strong>
                    <GridCol>
                      {matchingCurrency ? formatCurrAmount(transaction?.valueRec || 0, matchingCurrency) : '-'}
                    </GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.TRANSACTION_DISPENSED}</strong>
                    <GridCol>
                      {matchingCurrency ? formatCurrAmount(transaction?.valueDis || 0, matchingCurrency) : '-'}
                    </GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_REASON}</strong>
                    <GridCol>{transaction?.reason}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_TYPE}</strong>
                    <GridCol>{handleTransactionType(lang, transaction?.type)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.EQUIPMENT_COM_STATE}</strong>
                    <GridCol>{formatTransactionStatus(lang, transaction?.status)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.TRANSACTION_EXTERNAL_ID}</strong>
                    <GridCol>{formatTransactionStatus(lang, transaction?.hostTransactionID)}</GridCol>
                  </GridCol>
                </Grid>

                {transaction?.cashIn && currency && (
                  <>
                    <strong>{lang.CURRENCY_TABLE_CASH_IN}</strong>
                    <CurrencyTable lang={lang} currency={currency} cash={transaction.cashIn} />
                  </>
                )}

                <Button color="#016273" radius="md" onClick={() => getLog()}>
                  <Icon.IconDownload stroke={2} />
                  Logs
                </Button>
              </Group>
            </Container>
          </>
        }
      />
    </>
  );
};

export default ShowBankTransaction;
