import { Permission } from '../../libraries/users';

export class UserPermissionsHandler {
  permissions: Permission[];

  constructor(permissions: Permission[]) {
    this.permissions = permissions;
  }

  public hasUserConfigPermission = () => {
    return hasPermissions(1, 0x02, this.permissions);
  };

  public hasFinancialConfigPermission = () => {
    return hasPermissions(2, 0x01, this.permissions);
  };
}

/**
 * Verifies if logged user has permissions\
 * Returns true or false
 * @param {*} appId Application ID
 * @param {*} mask permission mask
 * @param {*} userPermissions option
 */
export const hasPermissions = function (appId: number, mask: number, userPermissions: any) {
  // if (!userPermissions) {
  //   userPermissions = JSON.parse(sessionStorage.userPermissions);
  // }
  if (!userPermissions) {
    return false;
  }
  for (let index = 0; index < userPermissions.length; index++) {
    let userPermission = userPermissions[index];
    if (appId === userPermission.appId) {
      return (userPermission.permissionCode & mask) !== 0;
    }
  }
};
