import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Group, Select, TextInput, Button, Grid, GridCol, Textarea } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import ModalComponent from '../../../components/modal';
import { Language } from '../../../languages/languageHandler';
import { HeldeskTickeLineRequests, updateTicketLine } from '../../../../libraries/helpdesk';
import CustomModal from '../../../components/custom-modal';
import './styles.scss';

interface ModalProps {
  isOpen: boolean;
  hideBtn: () => void;
  lang: Language;
  ticketData: any;
  onReload: () => void;
}

const TicketEditModal = ({ isOpen, hideBtn, lang, ticketData, onReload }: ModalProps) => {
  const [ticket, setTicket] = useState(ticketData);

  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const location = useLocation();

  const ticketStatus = [
    { value: '0', label: lang?.HELPDESK_TICKET_STATUS_0 },
    { value: '1', label: lang?.HELPDESK_TICKET_STATUS_1 },
    { value: '2', label: lang?.HELPDESK_TICKET_STATUS_2 },
    { value: '3', label: lang?.HELPDESK_TICKET_STATUS_3 },
    { value: '4', label: lang?.HELPDESK_TICKET_STATUS_4 },
    { value: '5', label: lang?.HELPDESK_TICKET_STATUS_5 },
    { value: '6', label: lang?.HELPDESK_TICKET_STATUS_6 }
  ];

  const ticketSolution = [
    { value: 'true', label: lang?.YES },
    { value: 'false', label: lang?.NO }
  ];

  const ticketImpact = [
    { value: '0', label: lang?.GLOBAL_GENERAL },
    { value: '1', label: lang?.GLOBAL_PARTICULAR }
  ];

  const ticketUrgency = [
    { value: '0', label: lang?.HELPDESK_TICKETS_URGENCY_0 },
    { value: '1', label: lang?.HELPDESK_TICKETS_URGENCY_1 },
    { value: '2', label: lang?.HELPDESK_TICKETS_URGENCY_2 },
    { value: '3', label: lang?.HELPDESK_TICKETS_URGENCY_3 },
    { value: '4', label: lang?.HELPDESK_TICKETS_URGENCY_4 }
  ];

  const ticketClassification = [
    { value: '0', label: lang?.HELPDESK_TICKET_CLASSIFICATION_0 },
    { value: '1', label: lang?.HELPDESK_TICKET_CLASSIFICATION_1 },
    { value: '2', label: lang?.HELPDESK_TICKET_CLASSIFICATION_2 },
    { value: '3', label: lang?.HELPDESK_TICKET_CLASSIFICATION_3 }
  ];

  useEffect(() => {
    setTicket(ticketData);
  }, [ticketData]);

  useEffect(() => {
    const path = location.pathname;
    const editable = /\/helpdesk\/ticket\/(edit|new)/.test(path);
    setIsEditable(editable);
  }, [location]);

  // Submit form logic
  const submitLine = async (ticket: any) => {
    let request: HeldeskTickeLineRequests = {
      classification: ticket.classification,
      comments: ticket.comments,
      description: ticket.description,
      impact: ticket.impact,
      line: ticket.line.toString(),
      origin: ticket.origin,
      soluClassif: ticket.soluClassif,
      status: ticket.status,
      statusDT: ticket.statusDT,
      statusName: ticket.statusName,
      ticketID: ticket.ticketID,
      time: ticket.time,
      urgency: ticket.urgency,
      user: ticket.user
    };

    const formController = new AbortController();

    try {
      const response = await updateTicketLine(request, formController);

      if (response.statusOper && response.statusOper.code !== 0) {
        const errorMessage = response.statusOper.message || 'An unknown error occurred.';
        setErrorMessage(
          `${lang?.HELPDESK_EDIT_TICKET_ALERT_UPDATED_ERROR} (Code: ${response.statusOper.code}) ${errorMessage}`
        );
        setShowErrorDialog(true);
      } else {
        setSuccessMessage(lang?.HELPDESK_EDIT_TICKET_ALERT_UPDATED_SUCCESS || 'Ticket edited successfully');
        setShowSuccessDialog(true);

        setTimeout(() => {
          setShowSuccessDialog(false);
          hideBtn();
          onReload();
        }, 1000);
      }
    } catch (error) {
      console.error('Error during the API call:', error);
      setErrorMessage(`${lang?.HELPDESK_EDIT_TICKET_ALERT_UPDATED_ERROR} (Status: ${errorMessage}`);
      setShowErrorDialog(true);
    }
  };

  return (
    <>
      <ModalComponent
        show={isOpen}
        handleClick={hideBtn}
        animation={true}
        title={lang?.HELPDESK_EDIT_TICKET_MODAL_TITLE}
        size={'lg'}
        content={
          <Modal.Body style={{ margin: '0', padding: '0' }}>
            <Group classNames={{ root: 'ticket-edit' }}>
              <Grid>
                {/* Open date */}
                <GridCol span={12}>
                  <DateTimePicker
                    label={lang?.HELPDESK_TICKET_UPDATE_OPEN_DT}
                    value={ticket?.statusDT ? new Date(ticket?.statusDT) : null}
                    onChange={date =>
                      setTicket((prev: any) => ({
                        ...prev,
                        statusDT: date ? date.toISOString() : null
                      }))
                    }
                    disabled={!isEditable}
                  />
                </GridCol>

                {/* Status */}
                <GridCol span={12}>
                  <Select
                    label={lang?.EQUIPMENT_COM_STATE}
                    data={ticketStatus}
                    value={ticket?.status?.toString() || ''}
                    onChange={value =>
                      setTicket((prev: any) => ({
                        ...prev,
                        status: value
                      }))
                    }
                    disabled={!isEditable}
                  />
                </GridCol>

                {/* Origin */}
                <GridCol span={12}>
                  <TextInput
                    label={lang?.GLOBAL_ORIGIN}
                    value={ticket?.origin || ''}
                    onChange={e =>
                      setTicket((prev: any) => ({
                        ...prev,
                        origin: e.target.value
                      }))
                    }
                    disabled={!isEditable}
                  />
                </GridCol>

                {/* Solution */}
                <GridCol span={12}>
                  <Select
                    label={lang?.GLOBAL_SOLUTION}
                    data={ticketSolution}
                    value={ticket?.soluClassif?.toString() || ''}
                    onChange={value =>
                      setTicket((prev: any) => ({
                        ...prev,
                        soluClassif: value
                      }))
                    }
                    disabled={!isEditable}
                  />
                </GridCol>

                {/* Time */}
                <GridCol span={12}>
                  <TextInput
                    label={lang?.GLOBAL_TIME}
                    value={ticket?.time || ''}
                    onChange={e =>
                      setTicket((prev: any) => ({
                        ...prev,
                        time: e.target.value
                      }))
                    }
                    disabled={!isEditable}
                  />
                </GridCol>

                {/* Impact */}
                <GridCol span={12}>
                  <Select
                    label={lang?.GLOBAL_IMPACT}
                    data={ticketImpact}
                    value={ticket?.impact?.toString() || ''}
                    onChange={value =>
                      setTicket((prev: any) => ({
                        ...prev,
                        impact: value
                      }))
                    }
                    disabled={!isEditable}
                  />
                </GridCol>

                {/* Urgency */}
                <GridCol span={12}>
                  <Select
                    label={lang?.GLOBAL_URGENCY}
                    data={ticketUrgency}
                    value={ticket?.urgency?.toString() || ''}
                    onChange={value =>
                      setTicket((prev: any) => ({
                        ...prev,
                        urgency: value
                      }))
                    }
                    disabled={!isEditable}
                  />
                </GridCol>

                {/* Classification */}
                <GridCol span={12}>
                  <Select
                    label={lang?.GLOBAL_CLASSIFICATION}
                    data={ticketClassification}
                    value={ticket?.classification?.toString() || ''}
                    onChange={value =>
                      setTicket((prev: any) => ({
                        ...prev,
                        classification: value
                      }))
                    }
                    disabled={!isEditable}
                  />
                </GridCol>
              </Grid>
            </Group>

            <Group className="description-comment">
              {/* Description */}
              <Textarea
                label={lang?.EQUIPMENT_DESCRIPTION}
                value={ticket?.description || ''}
                onChange={e =>
                  setTicket((prev: any) => ({
                    ...prev,
                    description: e.target.value
                  }))
                }
                disabled={!isEditable}
              />

              {/* Comments */}
              <Textarea
                label={lang?.GLOBAL_COMMENTS}
                value={ticket?.comments || ''}
                onChange={e =>
                  setTicket((prev: any) => ({
                    ...prev,
                    comments: e.target.value
                  }))
                }
                disabled={!isEditable}
              />
            </Group>

            {/* Save button */}
            {isEditable && (
              <Button
                onClick={e => {
                  e.preventDefault();
                  submitLine(ticket);
                }}
              >
                {lang?.GLOBAL_SAVE_BUTTON}
              </Button>
            )}
          </Modal.Body>
        }
      />
      <CustomModal
        opened={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        message={successMessage}
        backgroundColor="#3BDF70"
        textColor="black"
      />

      <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
        textColor="white"
      />
    </>
  );
};

export default TicketEditModal;
