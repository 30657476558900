import React, { useEffect, useState } from 'react';
import { Table, Loader, Select } from '@mantine/core';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { getLanguageName } from '../../languages/languageUtils';
import { IUser } from '../../../libraries/users';
import { useParams } from 'react-router-dom';
import * as Icon from '@tabler/icons-react';
import { ILanguage } from '../../../libraries/language';

interface ILanguageTableProps {
  selectedLanguages: {
    gid: number;
    eid: number;
    langid: number;
    langidx: number;
  }[];
  languageList?: ILanguage[];
  loading: boolean;
  editable: boolean;
  onRemoveLanguage: (langId: number) => void;
  filteredLangId: ILanguage[];
  form: any;
}

const LanguageTable: React.FC<ILanguageTableProps> = ({
  selectedLanguages,
  filteredLangId,
  loading,
  editable,
  onRemoveLanguage,
  form
}) => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, IUser>(state => state.login.user);
  const { eid } = useParams<string>();

  const [updatedLanguages, setUpdatedLanguages] = useState<
    { gid: number; eid: number; langid: number; langidx: number }[]
  >(selectedLanguages);

  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>([]);

  // Update state when selectedLanguages prop changes
  useEffect(() => {
    setUpdatedLanguages(selectedLanguages);
  }, [selectedLanguages]);

  // Recalculate available languages when updatedLanguages changes
  useEffect(() => {
    const availableLangs =
      filteredLangId?.filter(language => !updatedLanguages.some(selected => selected.langid === language.langid)) || [];
    setAvailableLanguages(availableLangs);
  }, [updatedLanguages, filteredLangId]);

  // Language selection handler
  const handleLanguageSelect = (langid: number | null) => {
    if (langid != null) {
      const newLangIdx =
        updatedLanguages.length > 0 ? Math.max(...updatedLanguages.map(lang => lang.langidx)) + 1 : 1;
      const selectedLanguage = filteredLangId?.find(l => l.langid === langid);
      if (selectedLanguage) {
        const newLang = {
          gid: loginInfo.gid ?? 0,
          eid: Number(eid),
          langid: selectedLanguage.langid,
          langidx: newLangIdx
        };
        setUpdatedLanguages(prevSelectedLanguages => {
          if (prevSelectedLanguages.some(lang => lang.langid === newLang.langid)) {
            return prevSelectedLanguages;
          }
          const updatedLanguages = [...prevSelectedLanguages, newLang];
          form.setFieldValue('languages', updatedLanguages);
          return updatedLanguages;
        });
      }
    }
  };

  // Handler for removing a language from the table
  const handleRemoveLanguage = (langid: number) => {
    const filteredLanguages = updatedLanguages.filter(language => language.langid !== langid);
    setUpdatedLanguages(filteredLanguages);
    form.setFieldValue('languages', filteredLanguages);
    onRemoveLanguage(langid);
  };

  return (
    <>
      {/* Languages Select */}
      <Select
        label={lang?.GLOBAL_LANGUAGE}
        data={
          availableLanguages.map(language => ({
            value: language.langid.toString(),
            label: language.nativeName
          })) || []
        }
        onChange={value => {
          const selectedLang = filteredLangId.find(lang => lang.langid.toString() === value);
          if (selectedLang) {
            handleLanguageSelect(selectedLang.langid);
          }
        }}
        value={''}
        disabled={!editable}
      />

      <Table.ScrollContainer minWidth={100}>
        <Table striped highlightOnHover verticalSpacing="md">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{lang?.GLOBAL_NAME}</Table.Th>
              <Table.Th>{lang?.EVENT_AD_ORDER}</Table.Th>
              {editable && <Table.Th></Table.Th>}
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {loading ? (
              <Table.Tr>
                <Table.Td colSpan={4}>
                  <Loader color="#016273" />
                </Table.Td>
              </Table.Tr>
            ) : updatedLanguages.length === 0 ? (
              <Table.Tr>
                <Table.Td colSpan={4} style={{ textAlign: 'center' }}>
                  {lang?.GLOBAL_NO_RESULTS}
                </Table.Td>
              </Table.Tr>
            ) : (
              updatedLanguages.map(language => (
                <Table.Tr key={language.langid}>
                  <Table.Td>{getLanguageName(language.langid)}</Table.Td>
                  <Table.Td>{language.langid}</Table.Td>
                  {editable && (
                    <Table.Td>
                      <Icon.IconX stroke={2} onClick={() => handleRemoveLanguage(language.langid)} style={{ cursor: 'pointer' }} />
                    </Table.Td>
                  )}
                </Table.Tr>
              ))
            )}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
};

export default LanguageTable;
