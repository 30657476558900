export const viLang_pt = {
  GLOBAL_LANGUAGE_NAME: 'Português',
  GLOBAL_LANGUAGE_EN: 'en-GB',
  GLOBAL_LANGUAGE_PT: 'pt-PT',
  //Common Wording
  GLOBAL_ADD: 'Adicionar',
  GLOBAL_DELETE: 'Eliminar',
  GLOBAL_UPDATE: 'Atualizar',
  GLOBAL_CONTENT: 'Conteúdo',
  GLOBAL_SAVE_BUTTON: 'Guardar',
  GLOBAL_CANCEL: 'Cancelar',
  GLOBAL_RETURN_BUTTON: 'Voltar',
  GLOBAL_EQUIPMENT: 'Equipamento',
  GLOBAL_EQUIPMENTS: 'Equipamentos',
  GLOBAL_GLASSES: 'Copos',
  GLOBAL_DONATION: 'Instituições Caridade',
  GLOBAL_SECTION_TABLE_TITLE: 'Gerir',
  GLOBAL_NAME: 'Nome',
  GLOBAL_SEARCH: 'Pesquisar',
  GLOBAL_PROFILE: 'Perfil',
  GLOBAL_USERS: 'Utilizadores',
  GLOBAL_PASSWORD: 'Password',
  GLOBAL_EVENT: 'Evento',
  GLOBAL_LOGOUT: 'Sair',
  GLOBAL_ALL: 'Todas',
  GLOBAL_SHOWING: 'Mostrando',
  GLOBAL_RESULTS: 'resultados',
  GLOBAL_LANGUAGE: 'Língua',
  GLOBAL_PERIOD: 'Período',
  GLOBAL_TYPE: 'Tipo',
  GLOBAL_AMOUNT: 'Montante',
  GLOBAL_QUANTITY: 'Quantidade',
  GLOBAL_NO_RESULTS: 'Sem Resultados',
  GLOBAL_CODE: 'Código',
  GLOBAL_ACCOUNT: 'Conta',
  GLOBAL_TIME: 'Tempo',
  GLOBAL_DATE: 'Data',
  GLOBAL_DATE_TIME: 'Data Hora',
  GLOBAL_DATE_TIME_CLOSE: 'Data Hora Fecho',
  GLOBAL_RECEIPT: 'Recibo',
  GLOBAL_COMISSIONS: 'Comissões (%)',
  GLOBAL_CONTRACT: 'Contrato',
  GLOBAL_ENTITY: 'Entidade',
  GLOBAL_LOCALS: 'Locais',
  GLOBAL_HOURS: 'Horas',
  GLOBAL_MINUTES: 'Minutos',
  GLOBAL_STORE: 'Loja',
  GLOBAL_VAULT: 'Cofre',
  GLOBAL_BILLS: 'Notas',
  GLOBAL_BILL: 'Nota',
  GLOBAL_COINS: 'Moedas',
  GLOBAL_COIN: 'Moeda',
  GLOBAL_CURRENCY: 'Moeda',
  GLOBAL_USER_EQUIPMENTS: 'Os seus equipamentos',
  GLOBAL_PICK_A_DATE: 'Escolha uma data',
  GLOBAL_REASON: 'Motivo',
  GLOBAL_MAINTENANCE: 'Manutenção',
  GLOBAL_AGENT: 'Agente',
  GLOBAL_LICENSE: 'Licença',
  GLOBAL_STATISTICS: 'Estatísticas',
  GLOBAL_AUDITLOG: 'AuditLog',
  GLOBAL_IP: 'IP',
  GLOBAL_IP_LOCAL: 'IP local',
  GLOBAL_GROUP: 'Grupo',
  GLOBAL_ENTITIES: 'Entidades',
  GLOBAL_ADDRESS: 'Morada',
  GLOBAL_LANGUAGES: 'Linguagens',
  GLOBAL_METHOD: 'Método',
  GLOBAL_ADDITIONAL_INFORMATION: 'Informação adicional',
  GLOBAL_CLEAR: 'Limpar',
  GLOBAL_DENOMINATION: ' Denominação',
  GLOBAL_INDEX: 'Índice',
  GLOBAL_VALUE: 'Valor',
  GLOBAL_WORKING_CAPITAL: 'Fundo Maneio',
  GLOBAL_TICKET: 'Ticket',
  GLOBAL_TICKETS: 'Tickets',
  GLOBAL_CLIENT: 'Cliente',
  GLOBAL_URGENCY: 'Urgência',
  GLOBAL_OTHER: ' Outro',
  GLOBAL_PRODUCT: 'Produto',
  GLOBAL_PRODUCTS: 'Produtos',
  GLOBAL_COMMENTS: 'Comentários',
  GLOBAL_ORIGIN: 'Origem',
  GLOBAL_SOLUTION: 'Solução',
  GLOBAL_GENERAL: 'Geral',
  GLOBAL_PARTICULAR: 'Particular',
  GLOBAL_IMPACT: 'Impacto',
  GLOBAL_CLASSIFICATION: 'Classificação',

  //Product
  PRODUCT_DETAILS: 'Product details',
  PRODUCT_ID: 'Product ID',
  PRODUCT_PRICE: 'Price',

  //Events Section
  EVENTS: 'Eventos',
  EVENTS_NEW: 'Adicionar Novo Evento',
  EVENT_ADDRESS: 'Endereço',
  EVENT_ZIP_CODE: 'Código Postal',
  EVENT_BEGIN_DATE: 'Data de Início',
  EVENT_BEGIN_DATE_PLACEHOLDER: 'Início',
  EVENT_END_DATE: 'Data de Fim',
  EVENT_END_DATE_PLACEHOLDER: 'Fim',
  EVENT_LAT: 'Lat',
  EVENT_LONG: 'Long',
  EVENT_ID: 'ID do Evento',
  EVENT_DATE_INFORMATION: 'Informações de Data',
  EVENT_INSTALLATION: 'Instalação',
  EVENT_UNINSTALLATION: 'Desinstalação',
  EVENT_DETAILS_GENERAL: 'Informações Gerais',
  EVENT_DETAILS_GENERAL_EVENT_DATES: 'Datas do Evento',
  EVENT_DETAILS_GENERAL_EVENT_DATES_FROM: 'De ',
  EVENT_DETAILS_GENERAL_EVENT_DATES_TO: 'até',
  EVENT_DETAILS_GENERAL_DIRECTIONS: 'Informação Geográfica',
  EVENT_GLASS_RECEPTACLE: 'Receptáculo',
  EVENT_GLASS_DELIVERY_ANIMATION: 'Animação de Entrega',
  EVENT_AD_ORDER: 'Ordem',
  EVENT_ID_PLACEHOLDER: 'Introduza ID do Evento',
  EVENT_NAME_PLACEHOLDER: 'Introduza Nome do Evento',
  EVENT_ZIP_CODE_PLACEHOLDER: '0000-000 Cidade',
  EVENT_LAT_PLACEHOLDER: '0.000',
  EVENT_LONG_PLACEHOLDER: '0.000',
  EVENT_EQUIPMENT_FORM_TITLE: 'Adicionar Equipamento ao Evento',
  EVENT_EQUIPMENT_FORM_SELECT: 'Selecione o Equipamento',
  EVENT_GLASS_FORM_TITLE: 'Adicionar Copo ao Evento',
  EVENT_GLASS_FORM_GLAS: 'Selecione o Copo',
  EVENT_GLASS_FORM_SELECT_RECEPTACLE: 'Selectione o Receptáculo',
  EVENT_GLASS_FORM_RECEPTACLE_1: 'Receptáculo 1',
  EVENT_GLASS_FORM_RECEPTACLE_2: 'Receptáculo 2',
  EVENT_GLASS_FORM_DELIVERY: 'Selecione a Animação de Entrega',
  EVENT_GLASS_FORM_RIGHT: 'Direccionar para a direita',
  EVENT_GLASS_FORM_LEFT: 'Direccionar para a esquerda',
  EVENT_AD_FORM_TITLE: 'Adicionar Anúncio ao Evento',
  EVENT_AD_FORM_SELECT: 'Selecione o Anúncio',
  EVENT_AD_FORM_ORDER: 'Escolha a ordem',
  EVENT_DON_FORM_TITLE: 'Adicionar Instituição de Caridade ao Evento',
  EVENT_DON_SELECT: 'Selecione a Instituição de Caridade',

  // Delegation Section
  SHOP_ADD_ALERT_SUCCESS: 'Local adicionado com sucesso',
  SHOP_UPDATE_ALERT_SUCCESS: 'Local editado com sucesso',
  SHOP_ADD_ALERT_ERROR: 'Erro ao adicionar local',
  SHOP_UPDATE_ALERT_ERROR: 'Erro ao atualizar local',
  SHOP_DELETE_ALERT_SUCCESS: 'Local apagado com sucesso',
  SHOP_DELETE_ALERT_ERROR: 'Erro ao apagar local',
  //Equipment Section
  EQUIPMENT_ID: 'EID',
  EQUIPMENT_SERIAL_NUMBER: 'Número de Série',
  EQUIPMENT_DESCRIPTION: 'Descrição',
  EQUIPMENT_IDENTIFICATION: 'Identificação',
  EQUIPMENT_SOFTWARE_VERSION: 'Versão de Software',
  EQUIPMENT_SOFTWARE_VERSION_MIN: 'Versão de Soft.',
  EQUIPMENT_CYCLE: 'Ciclo',
  EQUIPMENT_OCCUPATION: 'Ocupação',
  EQUIPMENT_COM_STATE: 'Estado',
  EQUIPMENT_STATE: 'Estado Equipamento',
  EQUIPMENT_STATE_MIN: 'Est. Equip.',
  EQUIPMENT_COM_DATE: 'Última Comunicação',
  EQUIPMENT_LAST_COM_DT: 'Data Última Comunicação',
  EQUIPMENT_COM_DATE_MIN: 'Últ. Com.',
  EQUIPMENT_LAST_OPER: 'Última Operação',
  EQUIPMENT_LAST_OPER_MIN: 'Últ. Oper.',
  EQUIPMENT_LAST_OPER_DT: 'Data Última Operação',
  EQUIPMENT_LAST_OPER_DT_MIN: 'DT Últ. Oper.',
  EQUIPMENT_OCCUPATION_PER: '% Ocupação',
  EQUIPMENT_LOCAL: 'Local',
  EQUIPMENT_DETAILS_TITLE: 'Detalhes do Equipamento',
  EQUIPMENT_DETAILS_SUBTITLE: 'Informações do Equipamento',
  EQUIPMENT_ID_PLACEHOLDER: 'Por favor introduza o ID do Equipamento',
  EQUIPMENT_FORM_TITLE: 'Adicionar Novo Equipamento',
  EQUIPMENT_EDIT: 'Editar equipamento',
  EQUIPMENT_AUTOMATIC_CLOSE: 'Hora de fecho automático',
  EQUIPMENT_TOTAL_AMOUNT: 'Total no Equipamento',
  EQUIPMENT_BILLS_RECYCLE: 'Reciclador de notas',
  EQUIPMENT_COINS_RECYCLE: 'Reciclador de moedas',
  EQUIPMENT_STATE_OK: 'Ok',
  EQUIPMENT_STATE_INACTIVE: 'Inativa',
  EQUIPMENT_STATE_PICKUP: 'É necessário recolha',
  EQUIPMENT_STATE_CHARGE: 'É necessário recarregamento',
  EQUIPMENT_STATE_ERROR: 'Algum dispositivo em erro',
  EQUIPMENT_LIMIT_MAX: 'Limite máximo atingido',
  EQUIPMENT_LIMIT_PICKUP: 'Limite de recolha atingido',
  EQUIPMENT_LIMIT_MIN: 'Limite mínimo atingido',
  EQUIPMENT_REGISTER: 'Registar Equipamento',
  EQUIPMENT_CONTRACT: 'Consultar contracto',
  EQUIPMENT_CONSULT: 'Consultar equipamento',
  EQUIPMENT_CREATE_TICKET: 'Criar ticket',
  EQUIPMENT_CONSULT_TICKET: 'Consultar ticket',
  EQUIPMENT_AUTHORIZE_INITIATION: 'Autorizar inicialização',
  EQUIPMENT_REASONS_DECLARATIONS: 'Razões e Declarações',
  EQUIPMENT_ADD_NEW_REASON: 'Adicionar novo motivo',
  EQUIPMENT_ADD_NEW_DECLARATION: 'Adicionar nova declaração',
  EQUIPMENT_UPDATE_REASON: 'Editar motivo',
  EQUIPMENT_UPDATE_DECLARATION: 'Editar declaração',
  EQUIPMENT_LOCAL_IP: 'IP do local',

  EQUIPMENT_MAX_COINS_SAFE: 'Máximo de moedas no cofre',
  EQUIPMENT_MAX_BILLS_SAFE: 'Máximo de notas no cofre',
  EQUIPMENT_ALMOST_FULL_SAFE: '% cofre quase cheio',
  EQUIPMENT_FULL_SAFE: '% cofre cheio',
  EQUIPMENT_CARD_INSERTION_TIME: 'Tempo limite para inserir cartão',
  EQUIPMENT_CARD_REMOVAL_TIME: 'Tempo limite para remover cartão',
  EQUIPMENT_DATA_INSERTION_TIME: 'Tempo limite para inserção de dados',
  EQUIPMENT_DEPOSIT_INSERTION_TIME: 'Tempo limite para depositar',
  EQUIPMENT_DECLARATION_TIME: 'Tempo limite para selecionar declaração',
  EQUIPMENT_AMOUNT_CURRENCY: 'Conversão do valor dentro do cofre',
  EQUIPMENT_ALMOST_FULL_AMOUNT: 'Montante para cofre quase cheio',
  EQUIPMENT_FULL_AMOUNT: 'Montante para cofre cheio',

  EQUIPMENT_EXISTENCE_RECYCLER: 'Exist. em Reciclagem',
  EQUIPMENT_WC_SURPLUS: 'F.M. Excedente',
  EQUIPMENT_WC_LACKING: 'F.M. em Falta',
  EQUIPMENT_EXISTENCE_SAFE: 'Exist. em Cofre',
  EQUIPMENT_TOTAL_EXISTENCE: 'Totao Existências',

  EQUIPMENT_CONSULT_WORKING_CAPITAL: 'Consultar existências e fundo de maneio',
  EQUIPMENT_EDIT_WORKING_CAPITAL: 'Editar config. moeda/fundo maneio',
  EQUIPMENT_FILL_EXISTENCE_WORKING_CAPITAL: 'Preencher F.M. com existências',
  EQUIPMENT_CLEAR_WORKING_CAPITAL: 'Limpar fundo maneio',

  EQUIPMENT_UPDATE_WC_SUCCESS: 'Fundo de maneio actualizado com sucesso',
  EQUIPMENT_UPDATE_WC_ERROR: 'Erro ao actualizar fundo de maneio',
  //Equipment Status Label
  EQUIPMENT_STATUS_LABEL_NOT_DEFINED: 'Não Definido',
  EQUIPMENT_STATUS_LABEL_DEFINED: 'Definido',
  EQUIPMENT_STATUS_LABEL_INICIALIZED: 'Inicializado',
  EQUIPMENT_STATUS_LABEL_ONLINE: 'Online',
  EQUIPMENT_STATUS_LABEL_OFFLINE: 'Offline',
  EQUIPMENT_STATE_BUTTON_IN_SERVICE: 'Em serviço',
  EQUIPMENT_STATE_BUTTON_OUT_OF_SERVICE: 'Fora de serviço',
  EQUIPMENT_STATE_LABEL_OUT_OF_SERVICE_FAKE_NOTE: 'Fora de Serviço com notas suspeitas retidas',
  EQUIPMENT_STATE_LABEL_TITLE: 'Estado de Equipamento',
  EQUIPMENT_STATE_LABEL_PRINTER: 'Impressora',
  EQUIPMENT_STATE_LABEL_CARD_READER: 'Leitor de cartões',
  EQUIPMENT_STATE_LABEL_UPS: 'UPS',
  EQUIPMENT_STATE_LABEL_PAY_SYSTEM: 'Sistema de pagamento',
  EQUIPMENT_STATE_LABEL_MANUAL_STATE: 'Estado manual',
  EQUIPMENT_STATE_LABEL_QUANTITY: 'Quantidade',
  EQUIPMENT_STATE_LABEL_MAX_AMOUNT: 'Montante máximo',
  EQUIPMENT_STATE_LABEL_MIN_AMOUNT: 'Montante mínimo',
  EQUIPMENT_STATE_LABEL_DEC_AMOUNT: 'Montante  para declarado',
  EQUIPMENT_STATE_BUTTON_RESTART: 'Reiniciar  ',
  EQUIPMENT_STATE_BUTTON_SHUTDOWN: 'Desligar',
  EQUIPMENT_STATE_BUTTON_OPEN_ESCROW: 'Abrir escrow',
  EQUIPMENT_STATE_BUTTON_CLOSE_ESCROW: 'Fechar escrow',
  EQUIPMENT_NO_INFORMATION: 'Sem informação disponível',
  EQUIPMENT_NO_DATA: 'Sem data disponível',
  EQUIPMENT_NO_STATUS: 'Sem status',

  S_LEFT_DOOR_LABEL: 'Porta esquerda',
  S_RIGTH_DOOR_LABEL: 'Porta direita',
  S_MAINT_STRUCTURE_LABEL: 'Estrutura de manutenção',
  S_SAFE_DOOR_LABEL: 'Porta do cofre',
  S_SAFE_DOOR_LOCK_LABEL: 'Fechadura da porta do cofre',
  S_BAG_LABEL: 'Saco',
  S_ESCROW_LABEL: 'Escrow',
  S_ESCROW_MONEY_INSIDE_LABEL: 'Notas no escrow',
  S_COINS_DOOR_LABEL: 'Porta das moedas',
  S_COINS_DOOR_LOCK_LABEL: 'Tranca porta das moedas',
  S_PROCESSOR_DRAWER_LABEL: 'Gaveta do processamento',
  S_BILLS_DOOR_LABEL: 'Porta das notas',
  S_BILLS_DOOR_LOCK_LABEL: 'Tranca porta das notas',
  S_COINS_DRAWER_LABEL: 'Gaveta de moedas',
  S_COINS_SAFE_LABEL: 'Cofre das moedas',

  //Equipment Status
  OK: 'Ok',
  YES: 'Sim',
  NO: 'Não',
  IN_ERROR: 'Em erro',
  NO_PAPER: 'Sem papel',
  CARD_INSIDE: 'Cartão no leitor',
  LOW_PAPER: 'Pouco papel',
  OUT_OF_SERVICE: 'Fora de serviço',
  FULL: 'Cheio',
  NEAR_FULL: 'Quase cheio',
  OPEN: 'Aberto',
  CLOSE: 'Fechado',
  LOCKED: 'Trancado',
  UNLOCKED: 'Destrancado',
  NOT_INSTALLED: 'Não instalado',
  VALIDATOR_IN_ERROR: 'Validador(es) em erro',
  UPS_ON_BATTERY: 'UPS em bateria',
  EDIT_EQUIPMENTS_ALERT_UPDATED_SUCCESS: 'Equipamento editado com sucesso',
  EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR: 'Erro ao editar equipamento',
  EQUIPMENT_STATE_LABEL_BILL_VALIDATOR: 'Estado validador de notas',
  EQUIPMENT_STATE_LABEL_COIN_SAFE: 'Estado cofre moedas',

  REMOVE_REASON: 'Tem a certeza que pretende apagar este motivo?',
  REMOVE_DECLARATION: 'Tem a certeza que pretende apagar esta declaração?',

  ADD_REASON_SUCCESS: 'Motivo adicionado com sucesso',
  ADD_DECLARATION_SUCCESS: 'Declaração adicionada com sucesso',
  ADD_REASON_ERROR: 'Erro ao adicionar motivo',
  ADD_DECLARATION_ERROR: 'Erro ao adicionar declaração',

  UPDATE_REASON_SUCCESS: 'Motivo actualizado com sucesso',
  UPDATE_DECLARATION_SUCCESS: 'Declaração actualizada com sucesso',
  UPDATE_REASON_ERROR: 'Erro ao actualizar motivo',
  UPDATE_DECLARATION_ERROR: 'Erro ao actualizar declaração',

  REMOVE_REASON_SUCCESS: 'Motivo removido com sucesso',
  REMOVE_DECLARATION_SUCCESS: 'Declaração removida com sucesso',
  REMOVE_REASON_ERROR: 'Erro ao remover motivo',
  REMOVE_DECLARATION_ERROR: 'Erro ao remover declaração',

  //Equipment agreeType
  AGREE_STATE_0: 'n/d',
  AGREE_STATE_1: 'Ativo',
  AGREE_STATE_2: 'Suspenso',
  AGREE_STATE_3: 'Cancelado',
  AGREE_STATE_4: 'Incumprimento',

  //Glass Section
  GLASS: 'Copo',
  GLASS_NEW: 'Adicionar Novo Copo',
  GLASS_IMAGE: 'Imagem',
  GLASS_DESCRIPTION: 'Descrição',
  GLASS_DETAILS_TITLE: 'Detalhes do Copo',
  GLASS_ID: 'ID do Copo',
  GLASS_ID_PLACEHOLDER: 'Introduza ID do Copo',
  GLASS_CODE: 'Código',
  GLASS_CODE_PLACEHOLDER: 'Introduza o código',
  GLASS_SELECT_IMAGE: 'Selecione uma imagem',
  GLASS_DESCRIPTION_PLACEHOLDER: 'Descrição do copo',
  GLASS_SIZE_TYPE: 'Categoria de Tamanho',
  GLASS_SIZE_TYPE_PLACEHOLDER: 'Categoria de tamanho',
  GLASS_READ: 'Ler Copo',

  //Glass Category
  CATEGORY: 'Categoria',
  CATEGORIES: 'Categorias',
  CATEGORY_DESCRIPTION: 'Descrição',
  CATEGORY_NEW: 'Nova Categoria',

  //Advertising Section
  ADVERTISEMENTS: 'Anúncios',
  ADVERTISING_NEW: 'Adicionar Novo Anúncio',
  ADVERTISING_FILE: 'Ficheiro',
  ADVERTISING_DURATION: 'Duração',
  ADVERTISING_SECONDS: 'segundos',
  ADVERTISING_SECS: 'segs',
  ADVERTISING_DETAILS_TITLE: 'Detalhes do Anúncio',
  ADVERTISING: 'Anúncio',
  ADVERTISING_ID: 'ID do Anúncio',
  ADVERTISING_ID_PLACEHOLDER: 'Introduza ID do Anúncio',

  //Donation Entities Section
  DONATION_ENTITY: 'Instituição',
  DONATION_ENTITIES: 'Instituições',
  DONATION_ENTITIES_LOGO: 'Logo',
  DONATION_ENTITY_ID: 'Id da Instituição',
  DONATION_ENTITY_ID_PLACEHOLDER: 'Introduza o Id da Instituição',
  DONATION_ENTITY_NAME_PLACEHOLDER: 'Introduza o nome da Instituição',
  DONATION_ENTITY_SELECT_LOGO: 'Selecione o logo',
  DONATION_ENTITY_FORM_TITLE: 'Adicionar Nova Insituição',

  //Login
  LOGIN_ENTITY: 'Entidade',
  LOGIN_USER: 'Utilizador',
  LOGIN_BUTTON: 'Entrar',
  LOGIN_EMAIL: 'Entrar com e-mail',
  LOGIN_USERID: 'Entrar com UserID',

  //Alert
  ALERT_ERRORCODE: 'Código Erro',
  ALERT_ERRORMESSAGE: 'Mensagem',

  //Users
  USER_UID: 'ID Utilizador',
  USER_EMAIL: 'Email',
  USER_EMAIL_PLACEHOLDER: 'exemplo@email.com',
  USER_CARD: 'Cartão',
  USER_CARD_PLACEHOLDER: 'Indique cartão do Utilizador',
  USER_REGION: 'Região',
  USER_LANG: 'Idioma',
  USER_LANG_EN: 'Inglês',
  USER_LANG_PT: 'Português',
  USER_NEW: 'Adicionar Novo Utilizador',
  USER_INFO: 'Detalhes Pessoais',
  USER_ENTITY: 'Entidade',
  USER_ENTITY_PLACEHOLDER: 'Selecione a Entidade',
  USER_ID: 'ID do Utilizador',
  USER_ID_PLACEHOLDER: 'Selecione o ID do Utilizador',
  USER_NAME_PLACEHOLDER: 'Indique o nome do Utilizador',
  USER_MOBILE: 'Telemóvel',
  USER_PHONE: 'Telefone',
  USER_MOBILE_PLACEHOLDER: 'Indique o número de telefone do Utilizador',
  USER_PERMISSIONS: 'Permissões',
  USER_CONFIRM_PASSWORD: 'Confirmar Password',
  USER_CONFIRM_CURRENT_PASSWORD: 'Confirme a sua Password atual',
  USER_CHANGE_PASSWORD: 'Alterar Password',
  USER_NEW_PASSWORD: 'Nova Password',
  USER_NEW_PASSWORD_ERROR: 'Passwords devem ser iguais',
  USER_PIN: 'Código PIN',
  USER_CHANGE_PIN: 'Alterar PIN',
  USER_NEW_PIN: 'Novo PIN',
  USER_CONFIRM_PIN: 'Confirmar Código PIN',
  USER_NEW_PIN_ERROR: 'Pins devem ser iguais',
  USER_DELETE_CONFIRM: 'Tem a certeza que pretende eliminar este utilizador? Esta ação é irreversível',
  USER_CONTACT_INFO: 'Contactos',
  ADD_USER_ALERT_UPDATE_SUCCESS: 'Utilizador criado com sucesso',
  ADD_USER_ALERT_UPDATE_ERROR: 'Erro ao criar utilizador, verifique os campos e tente novamente',
  EDIT_USER_ALERT_UPDATE_SUCCESS: 'Utilizador atualizado com sucesso',
  EDIT_USER_ALERT_UPDATE_ERROR: 'Erro ao atualizar utilizador',
  EDIT_USER_ALERT_UPDATE__PASSWORD_ERROR: 'Password inválida',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_1: 'Gestão de utilizadores e permissões',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_2: 'Configurações de sistema',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_3: 'Operações remotas não financeiras a equipamentos',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_4: 'Técnico',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_1: 'Dados financeiros',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_2: 'CIT',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_3: 'Operações remotas financeiras a equipamentos',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_4: 'Operador',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_5: 'APM',

  //app4
  APP4_OPT0: 'Gestão Contratos',
  APP4_OPT1: 'Gestão Clientes',
  APP4_OPT3: 'Helpdesk',
  APP4_OPT5: 'Gestão eventos & Contadores',
  //app5
  APP5_OPT0: 'Gestão de produtos',
  APP5_OPT1: 'Gestão de produções',
  APP5_OPT2: 'Receção de produtos',
  //app100
  APP100_OPT0: 'Relatórios financeiros',
  APP100_OPT1: 'Stock',
  APP100_OPT2: 'Documentos',

  USER_DELEGATIONS: 'Delegações',
  USER_DELEGATION: 'Delegação',
  USER_MY_PROFILE: 'O meu Perfil',
  USER_PROFILE: 'Perfil',

  //Movements
  MOVEMENTS: 'Movimentos',
  PROCESSED_GLASS_PERIOD: 'Período',
  PROCESSED_GLASS_ID: 'ID',
  PROCESSED_GLASS_STATUS: 'Estado',
  SUCCESS: 'Recolhido',
  CANCELLED: 'Cancelado',
  ERROR: 'Erro',
  PROCESSED_GLASS_ERROR: 'Erro',
  NONE: ' ',
  INTERNAL: 'Erro Interno',
  NO_GLASS_ID: 'Copo Não Reconhecido',
  PROCESSED_GLASS_REFUND_TYPE: 'Tipo Devolução',
  CASH: 'Dinheiro',
  DONATION: 'Doação',
  PROCESSED_GLASS_TUBE: 'Tubo',
  PROCESSED_GLASS_INITIAL_DATE: 'Data Inicial',
  PROCESSED_GLASS_END_DATE: 'Data Final',
  PROCESSED_GLASS_SEARCH_PLACEHOLER: 'Seleccione uma opção',

  //Transactions
  TRANSACTIONS: 'Transações',
  TRANSACTION_DETAILS: 'Detalhes da Transação',
  TRANSACTION_RECEIVED: 'Recebido',
  TRANSACTION_DISPENSED: 'Dispensado',
  TRANSACTION_PAYMENT_METHOD: 'Método de Pagamento',
  TRANSACTION_USER_CASHIER: 'Utilizador/Caixa',
  TRANSACTION_DEPOSITOR: 'Depositante',
  TRANSACTION_EXTERNAL_ID: 'ID Externo',
  TRANSACTION_NUMBER: 'Nº Transação',
  TRANSACTION_NUMBERS: 'Nº Transações',
  TRANSACTION_NUMBERS_FULL: 'Número de Transações',
  TRANSACTION_AMOUNT_CHARGED: 'Montante Cobrado',
  TRANSACTION_COMISSION_AMOUNT: 'Montante Comissão',
  TRANSACTION_AMOUNT_CREDITED: 'Montante Creditado',

  //Currency Table
  CURRENCY_TABLE_CASH_IN: 'Detalhes de Entrada',
  CURRENCY_TABLE_CASH_OUT: 'Detalhes de Saída',

  //Enterprise Equipment Periods
  ENTERPRISE_EQUIP_PERIODS: 'Períodos de Equipamentos Empresariais',
  ENTERPRISE_EQUIP_PERIODS_SHORT: 'Períodos Equip. Emp.',
  ENTERPRISE_EQUIP_PERIODS_DT_OPEN: 'Data/Hora abertura',
  ENTERPRISE_EQUIP_PERIODS_DT_CLOSE: 'Data/Hora fecho',
  ENTERPRISE_EQUIP_PERIODS_PAYMENT_AMOUNTS: 'Montante Pagamentos',
  ENTERPRISE_EQUIP_PERIODS_PAYMENT_AMOUNTS_MIN: 'Mont. Pagamentos',

  //Bank Transactions
  BANK_TRANSACTIONS: 'Transações Bancárias',

  //Dashboard
  DASHBOARD: 'Dashboard',
  DASHBOARD_CURRENT_PERIOD: 'Período corrente',

  // Transactions type
  OP_01010101_LABEL: 'Pagamento',
  OP_01010101_LABEL_MIN: 'Pagamento',
  OP_01010122_LABEL: 'Pagamento Cancelado',
  OP_01010122_LABEL_MIN: 'P. Cancelado',
  OP_01010123_LABEL: 'Pagamento Recuperado',
  OP_01010123_LABEL_MIN: 'P. Recuperado',
  OP_01010125_LABEL: 'Pagamento Rejeitado',
  OP_01010125_LABEL_MIN: 'P. Rejeitado',
  OP_01010128_LABEL: 'Pagamento Rejeitado por erro',
  OP_01010128_LABEL_MIN: 'P.Rej. por erro',
  OP_01010201_LABEL: 'Depósito',
  OP_01010201_LABEL_MIN: 'Depósito',
  OP_01010202_LABEL: 'Depósito por tempo esgotado',
  OP_01010202_LABEL_MIN: 'Depósito',
  OP_01010222_LABEL: 'Depósito cancelado',
  OP_01010222_LABEL_MIN: 'D. cancelado',
  OP_01010224_LABEL: 'Depósito cancelado por tempo esgotado',
  OP_01010224_LABEL_MIN: 'D. cancelado',
  OP_01010225_LABEL: 'Depósito rejeitado',
  OP_01010225_LABEL_MIN: 'D. cancelado',
  OP_01010226_LABEL: 'Depósito rejeitado com dinheiro com tempo esgotado',
  OP_01010226_LABEL_MIN: 'D. rejeitado',
  OP_01010227_LABEL: 'Depósito com erro rejeitado por tempo esgotado',
  OP_01010227_LABEL_MIN: 'D. rejeitado',
  OP_01010228_LABEL: 'Depósito com erro rejeitado',
  OP_01010228_LABEL_MIN: 'D. rejeitado',
  OP_01010229_LABEL: 'Depósito com erro rejeitado com dinheiro com tempo esgotado',
  OP_01010229_LABEL_MIN: 'D. rejeitado',
  OP_01010230_LABEL: 'Transação cancelada por falta de resposta, necessita revisão manual',
  OP_01010230_LABEL_MIN: 'Transação cancelada por falta de resposta, necessita revisão manual',
  OP_01010231_LABEL: 'Depósito com notas suspeitas rejeitado por tempo esgotado',
  OP_01010231_LABEL_MIN: 'Depósito com notas suspeitas rejeitado por tempo esgotado',
  OP_01010232_LABEL: 'Depósito com notas suspeitas',
  OP_01010232_LABEL_MIN: 'Depósito com notas suspeitas',
  OP_01010233_LABEL: 'Depósito com notas suspeitas com tempo esgotado',
  OP_01010233_LABEL_MIN: 'Depósito com notas suspeitas com tempo esgotado',
  OP_01010301_LABEL: 'Levantamento',
  OP_01010301_LABEL_MIN: 'Levantamento',
  OP_01010322_LABEL: 'Levantamento Cancelado',
  OP_01010322_LABEL_MIN: 'Lev. Cancelado',
  OP_01010328_LABEL: 'Levantamento rejeitado por erro',
  OP_01010328_LABEL_MIN: 'Levantamento R.',
  OP_01010334_LABEL: 'Levantamento incompleto',
  OP_01010334_LABEL_MIN: 'Lev. Incompleto',
  OP_01010401_LABEL: 'Devolução',
  OP_01010401_LABEL_MIN: 'Devolução',
  OP_01011101_LABEL: 'Carregamento',
  OP_01011101_LABEL_MIN: 'Carregamento',
  OP_01011122_LABEL: 'Carregamento Cancelado',
  OP_01011122_LABEL_MIN: 'Carregamento C.',
  OP_01011123_LABEL: 'Carregamento Recuperado',
  OP_01011123_LABEL_MIN: 'Carreg. Recuperado',
  OP_01011128_LABEL: 'Carregamento Rejeitado por erro',
  OP_01011128_LABEL_MIN: 'Carre. R. por erro',
  OP_01011201_LABEL: 'Envio para cofre',
  OP_01011201_LABEL_MIN: 'Envio para cofre',
  OP_01011222_LABEL: 'Envio para cofre cancelado',
  OP_01011222_LABEL_MIN: 'Envio para cofre C.',
  OP_01011223_LABEL: ' Envio para cofre recuperado',
  OP_01011223_LABEL_MIN: 'Envio para cofre R.',
  OP_01011228_LABEL: 'Envio para cofre rejeitado por erro',
  OP_01011228_LABEL_MIN: 'Envio para cofre R.',
  OP_01010901_LABEL: 'Depósito Manual',
  OP_01010901_LABEL_MIN: 'Depósito Manual',
  OP_01011001_LABEL: 'Depósito Revertido',
  OP_01011001_LABEL_MIN: 'Depósito R.',
  OP_02120503_LABEL: 'Abertura de Período',
  OP_02120503_LABEL_MIN: 'Abertura de Período',
  OP_02120506_LABEL: 'Fecho de Período',
  OP_02120506_LABEL_MIN: 'Fecho de Período',
  OP_01010223_LABEL: 'Mov. para recuperar',
  OP_01010223_LABEL_MIN: 'Mov. para recuperar',
  OP_01011301_LABEL: 'Troco',
  OP_01011301_LABEL_MIN: 'Troco',
  OP_01011322_LABEL: 'Troco Cancelado',
  OP_01011322_LABEL_MIN: 'Troco Cancelado',
  OP_01011323_LABEL: 'Troco Recuperado',
  OP_01011323_LABEL_MIN: 'Troco Recuperado',
  OP_01011325_LABEL: 'Troco Incompleto',
  OP_01011325_LABEL_MIN: 'Troco Incompleto',
  OP_01011334_LABEL: 'Troco Incompleto',
  OP_01011334_LABEL_MIN: 'Troco Incompleto',
  OP_1_LABEL: 'Pagamento',
  OP_1_LABEL_MIN: 'Pagamento',
  OP_2_LABEL: 'Autorização e compra',
  OP_2_LABEL_MIN: 'Autorização e compra',
  OP_3_LABEL: 'Autorização',
  OP_3_LABEL_MIN: 'Autorização',
  OP_4_LABEL: 'Reembolso',
  OP_4_LABEL_MIN: 'Reembolso',
  OP_5_LABEL: 'Carregamento de Hopper',
  OP_5_LABEL_MIN: 'Carregamento de Hopper',
  OP_6_LABEL: 'Auditoria de Hopper',
  OP_6_LABEL_MIN: 'Auditoria de Hopper',
  OP_7_LABEL: 'Seguro aberto',
  OP_7_LABEL_MIN: 'Seguro aberto',
  OP_8_LABEL: 'Depósito',
  OP_8_LABEL_MIN: 'Depósito',
  OP_9_LABEL: 'Abertura de período',
  OP_9_LABEL_MIN: 'Abertura de período',
  OP_10_LABEL: 'Fecho de período',
  OP_10_LABEL_MIN: 'Fecho de período',

  //Transactions status
  TRANSACTIONS_STATUS_WAITING: 'Em espera',
  TRANSACTIONS_STATUS_WILL_NOT_BE_SENT: 'Concluído',
  TRANSACTIONS_STATUS_SET_AS_NOT_TO_SEND: 'Concluído',
  TRANSACTIONS_STATUS_SENDED_TO_COREBANKING: 'Comunicado',
  TRANSACTIONS_STATUS_CARD_CHECKED: 'Cartão Aceite',
  TRANSACTIONS_STATUS_DEPOSIT_PARCIAL_RECEIVED: 'Parcial recebido',
  TRANSACTIONS_STATUS_WAITING_DECLARATION: 'A aguardar declaração',

  //APMs
  APM_TRANSACTIONS: 'Transações APM',
  APM_PERIODS: 'Períodos APM',
  APM_CLOSE_PERIODS: 'Fecho de Períodos',
  APM_POS: 'APM POS',

  // HelpDesk
  HELPDESK_EQUIPMENTS: 'Equipamentos Helpdesk',
  HELPDESK_ENTITIES: 'Entidades Helpdesk',
  HELPDESK_TICKETS: 'Tickets Helpdesk',
  HELPDESK_MAINTENANCE_COMPANY: 'Empresa manutenção',
  HELPDESK_MAINTENANCE_AGENCY: 'Empresa agente',
  HELPDESK_CHOOSE_ENTITY: 'Escolher entidade',

  //entity
  HELPDESK_ENTITY_REMOVE: 'Tem a certeza que quer apagar esta entidade?',
  ENTITY_TYPE_0: 'Empresa',
  ENTITY_TYPE_1: 'Agente',
  ENTITY_TYPE_2: 'Fornecedor',
  ENTITY_TYPE_3: 'Prestador serv. manutenção',
  ENTITY_TYPE_NULL: 'Todas',

  ENTITY_EXTERNAL_DATA: 'Dados externos',

  //edit Entity
  EDIT_ENTITY_FORM_LABEL_GID: 'Grupo (GID)',
  EDIT_ENTITY_FORM_LABEL_LOGO: 'Logotipo',
  EDIT_ENTITY_FORM_LABEL_USESHIFTS: 'Shifts',
  EDIT_ENTITY_FORM_LABEL_PRINTTYPE: 'Tipo de Impressão',
  EDIT_ENTITY_FORM_LABEL_REPORTTYPE: 'Tipo de Relatório',
  EDIT_ENTITY_FORM_LABEL_SIBSMERCHANTCODE: 'Sibs Mech. Code',
  EDIT_ENTITY_FORM_LABEL_BILLINGENTITY: 'Entidade de cobrança',
  EDIT_ENTITY_FORM_LABEL_OWNERID: 'Owner ID',
  EDIT_ENTITY_FORM_LABEL_ACTIVITY: 'Ramo de atividade',
  EDIT_ENTITY_FORM_LABEL_BILLVOLUME: 'Volume faturação',
  EDIT_ENTITY_LABEL_READ: 'Visualizar Entidade',
  EDIT_ENTITY_LABEL_EDIT: 'Edição de Entidade',
  EDIT_ENTITY_FORM_LABEL_ACTIVITY_NOT_DEFINED: 'Não Definida',
  EDIT_ENTITY_CONTROLLER_ALERT_DELETE_CONFIRM: 'Tem a certeza que quer apagar a entidade',
  EDIT_ENTITY_CONTROLLER_ALERT_DELETE_SUCCESS: 'Entidade eliminada com successo',

  //ticket
  HELPDESK_TICKET_STATUS_0: 'Aberto',
  HELPDESK_TICKET_STATUS_1: 'Completo',
  HELPDESK_TICKET_STATUS_2: 'Reabrir',
  HELPDESK_TICKET_STATUS_3: 'Discussão',
  HELPDESK_TICKET_STATUS_4: 'Esclarecimento',
  HELPDESK_TICKET_STATUS_5: 'Criar IR',
  HELPDESK_TICKET_STATUS_6: 'IR fechado',

  HELPDESK_TICKETS_URGENCY_0: 'Imediata',
  HELPDESK_TICKETS_URGENCY_1: 'Alta',
  HELPDESK_TICKETS_URGENCY_2: 'Média',
  HELPDESK_TICKETS_URGENCY_3: 'Baixa',
  HELPDESK_TICKETS_URGENCY_4: 'Nenhuma',

  HELPDESK_TICKET_CLASSIFICATION_0: 'Erro',
  HELPDESK_TICKET_CLASSIFICATION_1: 'Troco',
  HELPDESK_TICKET_CLASSIFICATION_2: 'Erro externo',
  HELPDESK_TICKET_CLASSIFICATION_3: 'Problema',

  //edit ticket
  HELPDESK_TICKET_UPDATE_COMMUNICATION_CHANNEL: 'Canal de comunicação',
  HELPDESK_TICKET_UPDATE_ISSUE_TYPES: 'Tipo de problema',
  HELPDESK_TICKET_UPDATE_CHANGE_REQUEST: 'Solicitação de alteração',
  HELPDESK_TICKET_UPDATE_INFORMATION_REQUEST: 'Solicitação de informações',
  HELPDESK_TICKET_UPDATE_CLASSIFICATION_PROBLEM: 'Problema reportado',
  HELPDESK_TICKET_UPDATE_CLASSIFICATION_FAULT: 'Falha detetada',
  HELPDESK_TICKET_UPDATE_CLASSIFICATION_CAUSE: 'Causa da falha',
  HELPDESK_TICKET_UPDATE_CLASSIFICATION_ACTION: 'Acção de resolução',
  HELPDESK_TICKET_UPDATE_OPEN_DT: 'Data de abertura',
  HELPDESK_TICKET_UPDATE_LAST_UPDATE: 'Última actualização',
  HELPDESK_TICKET_UPDATE_LAST_STATUS: 'Último estado',
  HELPDESK_TICKET_UPDATE_INT_TYPE: 'Tipo interv.',
  HELPDESK_TICKET_UPDATE_INT_TYPE_REMOTE: 'Remoto',
  HELPDESK_TICKET_UPDATE_INT_TYPE_LOCAL: 'Local',
  HELPDESK_EDIT_TICKET_MODAL_TITLE: 'Editar ticket / Detalhes',
  HELPDESK_EDIT_TICKET_ALERT_UPDATED_SUCCESS: 'Ticket editado com sucesso',
  HELPDESK_EDIT_TICKET_ALERT_UPDATED_ERROR: 'Erro ao editar ticket'
};
